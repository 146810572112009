import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Formik, Field as FormikField } from 'formik';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import Field from '@components/1-atoms/Field';
import DropDownField from '@components/1-atoms/DropDownField';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useToolMutations from '@hooks/tools/useToolMutations';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import useDialog from '@hooks/useDialog';
import { TOOL_MILLING_TYPES } from '@constants/tools';
import { Fields } from '@containers/Dialogs/CreateToolDialog/CreateToolDialog.styled';

const MODAL_ID = ModalDataTypes.CREATE_TOOL;

export const CreateToolDialog = () => {
  const intl = useIntl();
  const { hideDialog, getDialogData, showDialog } = useDialog();
  const { createToolMutation } = useToolMutations();
  const history = useHistory();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const formInitialValues = useMemo(
    () => ({
      name: dialogData.toolName || 'Untitled tool',
      type: {
        label: dialogData.toolType
          ? TOOL_MILLING_TYPES[dialogData.toolType].title
          : '',
        value: dialogData.toolType
          ? TOOL_MILLING_TYPES[dialogData.toolType].value
          : '',
      },
    }),
    [dialogData],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(
            intl.formatMessage({
              id: 'tools.create_tool_dialog.tool_name.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          )
          .min(
            2,
            intl.formatMessage({
              id: 'tools.create_tool_dialog.tool_name.validation.min',
              defaultMessage: 'Name must be at least 2 characters long',
            }),
          )
          .max(
            256,
            intl.formatMessage({
              id: 'tools.create_tool_dialog.tool_name.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          ),
        type: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'tools.create_tool_dialog.type.validation.required',
              defaultMessage: 'Please select an option',
            }),
          ),
      }),
    [intl],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values) => {
      (async () => {
        const { name, type } = values;
        let tool;
        try {
          tool = await createToolMutation.mutateAsync({
            name: name,
            type: type?.value,
          });
        } catch (_) {
          // Handle error
        }
        hideDialog(MODAL_ID);
        const navigationPath = generatePath(ROUTES.TOOL, { toolId: tool?.id });
        history.push(navigationPath);
      })();
    },
    [history, hideDialog, createToolMutation],
  );

  const handleDropDownClick = useCallback(
    (name) => {
      handleClose();
      showDialog(ModalDataTypes.SELECT_TOOL_TYPE, { toolName: name });
    },
    [showDialog, handleClose],
  );

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <DialogPortal
          renderAsForm
          dataTestId="create-tool"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'general.continue',
            defaultMessage: 'Continue',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'tools.create_tool_dialog.header.title',
              defaultMessage: 'Add new tool component',
            })}
          />
          <Fields>
            <FormikField
              autoFocus
              component={Field}
              dataTestId="create-tool__name__field"
              disabled={isSubmitting}
              name="name"
              label={intl.formatMessage({
                id: 'tools.create_tool_dialog.tool_name_input.label',
                defaultMessage: 'Name',
              })}
              placeholder={intl.formatMessage({
                id: 'tools.create_tool_dialog.tool_name.input.placeholder',
                defaultMessage: 'Untitled tool',
              })}
            />

            <DropDownField
              label={intl.formatMessage({
                id: 'tools.create_tool_dialog.tool_type_input.label',
                defaultMessage: 'Type',
              })}
              dataTestId="create-tool__tool-type__dropdown-field"
              placeholder={intl.formatMessage({
                id: 'tools.create_tool_dialog.tool_type.input.placeholder',
                defaultMessage: 'Select tool type',
              })}
              disabled={isSubmitting}
              value={values['type']?.label}
              onClick={() => handleDropDownClick(values['name'])}
            />
          </Fields>
        </DialogPortal>
      )}
    </Formik>
  );
};

export default CreateToolDialog;
