import styled, { css } from 'styled-components';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { flexColumnCenter, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumnCenter}
  flex-shrink: 0;
  cursor: pointer;

  ${({ disabled, transparentWhenDisabled, theme: { spacing } }) => css`
    width: ${spacing.level9};
    height: ${spacing.level9};

    ${when(
      disabled,
      `
        pointer-events: none;
      `,
    )}

    ${when(
      disabled && transparentWhenDisabled,
      `
        opacity: 0.38;
      `,
    )}
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  ${flexColumnCenter}
  padding: 11px;
  position: relative;
  z-index: 0;
`;

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  max-width: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;

  ${({ disabled, theme: { colors } }) => css`
    fill: ${colors.onPrimary};

    ${when(
      disabled,
      `
        fill: ${colors.surface};
      `,
    )}
  `}
`;

export const StateLayer = styled.span`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ disabled, theme: { colors, borderRadius } }) => css`
    border-radius: ${borderRadius.full};
    background-color: ${colors.onSurface};
    opacity: 0.08;

    ${when(
      disabled,
      `
        display: none;
      `,
    )}
  `}
`;

export const OuterBox = styled.div`
  width: 18px;
  height: 18px;
  ${flexColumnCenter}
  border-radius: 2px;
  position: relative;

  ${({
    checked,
    dirty,
    diffRemoved,
    diffAdded,
    diffModified,
    disabled,
    theme: { colors },
  }) => css`
    border: 2px solid ${colors.onSurfaceVariant};

    ${when(
      checked,
      `
        border-color: ${colors?.primary};
        background-color: ${colors?.primary};
      `,
    )}

    ${when(
      dirty,
      `
        border-color: ${colors?.caution};

        ${when(
          checked,
          `
            background-color: ${colors?.caution};

            ${Icon} {
              fill: ${colors.surface};
            }
          `,
        )}
      `,
    )}

    ${when(
      disabled &&
        `
        border-color: ${colors.onSurfaceVariant};
        background: ${colors.onSurfaceVariant};

        ${Icon} path {
          fill: ${colors.surface};
        }
      `,
    )}

    ${when(
      diffRemoved,
      `
        border-color: #9c3e47;

        ${when(
          checked,
          `
            background-color: #9c3e47;

            ${Icon} {
              fill: ${colors.surface};
            }
          `,
        )}
      `,
    )}

    ${when(
      diffAdded,
      `
        border-color: #007bff;

        ${when(
          checked,
          `
            background-color: #007bff;

            ${Icon} {
              fill: ${colors.surface};
            }
          `,
        )}
      `,
    )}

    ${when(
      diffModified,
      `
        border-color: #E93EA4;

        ${when(
          checked,
          `
            background-color: #E93EA4;

            ${Icon} {
              fill: ${colors.surface};
            }
          `,
        )}
      `,
    )}
  `}
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  margin: 0;

  &:hover {
    & ~ ${StateLayer} {
      visibility: visible;
    }
  }

  &:focus:focus-visible {
    & ~ ${StateLayer} {
      visibility: visible;
      opacity: 0.12;
    }

    & ~ ${OuterBox} {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  ${({ disabled }) => css`
    ${when(
      disabled,
      `
        pointer-events: none;
      `,
    )}
  `}
`;
