import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import PageList from '@components/3-organisms/PageList';
import { LIST_ITEM_VARIANT_THUMBNAIL } from '@components/1-atoms/ListItem';
import { toolsConstants, toolTypeLabel } from '@constants/tools';
import { DROP_DOWN_MENU_POSITION_BOTTOM_LEFT } from '@components/2-molecules/DropDownMenu';
import { materialTypeLabel } from '@constants/materials';
import useToolList from '@hooks/tools/useToolList';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';

const ToolsPageContent = ({
  headerLeadingIconButtonIconName,
  loading,
  onHeaderLeadingIconButtonClick,
  toolsList = [],
  withEndingButton,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { showDialog } = useDialog();
  const { getToolDropDownMenuActions } = useToolList();
  const [filterByCategoryName, setFilterByCategoryName] = useState(
    toolsConstants.ALL,
  );

  const showAddToolDialog = useCallback(() => {
    showDialog(ModalDataTypes.CREATE_TOOL);
  }, [showDialog]);

  const toolsListItems = useMemo(() => {
    const filteredList = toolsList?.filter((tool) => {
      if (filterByCategoryName === toolsConstants.ALL) {
        return true;
      }

      return tool.type === filterByCategoryName;
    });

    return filteredList?.map((tool) => ({
      id: tool.id,
      label: tool.name,
      createdAt: tool.createdAt,
      thumbnailUrl: tool.thumbnailUrl || '/img/icons/tool.svg',
      description: `Ø${tool?.diameter}mm - ${materialTypeLabel(tool.type)}`,
      endingIconButtonIconName: withEndingButton ? 'more_vert_0' : '',
      endingIconButtonDropDownProps: withEndingButton
        ? {
            dropDownMenuItems: getToolDropDownMenuActions(tool),
          }
        : {},
      onClick: () =>
        history.push(
          generatePath(ROUTES.TOOL, {
            toolId: tool?.id,
          }),
        ),
    }));
  }, [
    withEndingButton,
    getToolDropDownMenuActions,
    filterByCategoryName,
    history,
    toolsList,
  ]);

  const actionChips = useMemo(() => {
    const chips = [];
    const uniqueTypes = new Set(toolsList?.map((tool) => tool.type));
    const allCategories = [toolsConstants.ALL, ...uniqueTypes];

    chips.push({
      id: 'tools-page-filter-tool-chip',
      dataTestId: 'tools__filter-operators-chip',
      label: toolTypeLabel(filterByCategoryName),
      leadingIconName: 'filter_list_0',
      endingIconName: 'arrow_drop_down_1',
      dropDownMenuItems: allCategories?.map((category, i) => ({
        id: category,
        label: toolTypeLabel(category),
        withDivider: i === 1,
        selected: filterByCategoryName === category,
        onClick: () => setFilterByCategoryName(category),
      })),
      dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
    });

    chips.push({
      id: 'tools-page-add-tool-chip',
      variant: CHIP_VARIANT_PRIMARY,
      dataTestId: 'tools-page__create-add-tool-chip',
      label: intl.formatMessage({
        id: 'tools.tools_list.add_tool.button',
        defaultMessage: 'Add Tool',
      }),
      leadingIconName: 'add_0',
      onClick: showAddToolDialog,
    });

    return chips;
  }, [
    toolsList,
    filterByCategoryName,
    setFilterByCategoryName,
    showAddToolDialog,
    intl,
  ]);

  return (
    <PageList
      dataTestId={'tools-list'}
      listItems={toolsListItems}
      listItemVariant={LIST_ITEM_VARIANT_THUMBNAIL}
      loading={loading}
      createItemMessageIconName="tool_0"
      createItemMessageTitle={intl.formatMessage({
        id: 'tools.tools_list.add_tool.title',
        defaultMessage: 'No Tool Components yet',
      })}
      headerTitle={intl.formatMessage({
        id: 'navigation.link.tools',
        defaultMessage: 'Tool Components',
      })}
      createItemMessageButtonLabel={intl.formatMessage({
        id: 'tools.tools_list.add_tool.button',
        defaultMessage: 'Add Tool',
      })}
      createItemMessageButtonIconName="add_0"
      createItemMessageOnButtonClick={showAddToolDialog}
      headerLeadingIconButtonIconName={headerLeadingIconButtonIconName}
      onHeaderLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
      actionChips={actionChips}
      sortOptions={[
        {
          label: intl.formatMessage({
            id: 'general.date',
            defaultMessage: 'Date',
          }),
          value: 'createdAt',
        },
        {
          label: intl.formatMessage({
            id: 'general.name',
            defaultMessage: 'Name',
          }),
          value: 'label',
        },
      ]}
    />
  );
};

ToolsPageContent.propTypes = {
  withEndingButton: PropTypes.bool,
  headerLeadingIconButtonIconName: PropTypes.string,
  loading: PropTypes.bool,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  toolsList: PropTypes.array,
};

export default ToolsPageContent;
