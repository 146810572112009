import SettingBar from '@components/2-molecules/SettingBar';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMachineModifications,
  getUnsavedPrinterModifications,
} from '@selectors/printerSelectors';
import { isEqual, pick } from 'lodash';
import { changePrinterRobotType, updatePrinter } from '@actions/printerActions';
import { defaultPrinterSettings } from '@constants/defaultPrinterSettings';
import { hardwareSelectionSettings } from '@constants/printers/hardwareSelectionSettings';
import { useIntl } from 'react-intl';
import Settings from '@components/Printers/Editor/Settings';
import usePrinterList from '@hooks/printers/usePrinterList';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import PropTypes from 'prop-types';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import { postProcessorSettingsDefinitions } from '@constants/printers/postProcessorSettingsDefinitions';
import { Formik } from 'formik';
import { uuidRegex } from '@utils/validation';
import * as Yup from 'yup';
import usePrinter from '@hooks/printers/usePrinter';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import { PRINTER_MONITOR_FEATURE } from '@constants/featureFlagConstants';

const validationSchema = Yup.object().shape({
  serialCode: Yup.string()
    .nullable()
    .test(
      'isSerialCodeValid',
      { serialCode: 'Serial code must be an UUID' },
      (serialCode) => {
        if (serialCode) {
          return uuidRegex.test(serialCode);
        }
        return true;
      },
    ),
});

const Editor = ({ handleSidebarCollapse, printer }) => {
  const dispatch = useDispatch();
  const { getPrinterDropDownMenuActions } = usePrinterList();
  const { showDialog } = useDialog();
  const { invalidatePrinterNozzleQuery } = usePrinter();
  const isPrinterMonitorEnabled = useFeatureFlagValue(PRINTER_MONITOR_FEATURE);
  const intl = useIntl();

  const printerId = printer?.id;

  const { printersQuery, printerCamerasQuery } = usePrinterQueries({
    printerId: isPrinterMonitorEnabled ? printerId : undefined,
  });
  const { updatePrinterCamerasMutation } = usePrinterMutations();

  const printerModifications = useSelector(
    getUnsavedPrinterModifications(printerId),
  );

  const machineSettings = useSelector(
    getMachineModifications(printerId),
    isEqual,
  );

  const isSubmitEnabled =
    !printerCamerasQuery.isLoading &&
    (!printerModifications?.settings ||
      Object.keys(printerModifications?.settings).length === 0);

  const successUpdatePrinterCallback = useCallback(() => {
    printersQuery.refetch();
    showDialog(ModalDataTypes.PROMPT, {
      dataTestId: 'update-printer-dialog',
      title: intl.formatMessage({
        id: 'dialogs.title.attention',
        defaultMessage: 'Attention',
      }),
      subtitle: intl.formatMessage({
        id: 'dialogs.subtitle.updateprinter',
        defaultMessage:
          'The printer update will not recompute automatically all your workflows',
      }),
      secondaryButtonLabel: '',
      onPrimaryButtonClick: () => {
        if (
          printerModifications?.settings?.postProcessor ===
          postProcessorSettingsDefinitions.CUSTOM.value
        ) {
          showDialog(ModalDataTypes.PROMPT, {
            dataTestId: 'post-processor-custom-warning-dialog',
            title: intl.formatMessage({
              id: 'dialogs.title.attention',
              defaultMessage: 'Attention',
            }),
            subtitle: intl.formatMessage({
              id: 'printers.postprocessor.update.warning',
              defaultMessage:
                'Disclaimer: Note that any instructions you insert here will be injected into the robot program. Please be aware that you can insert instructions that are dangerous and can damage your machine.',
            }),
            secondaryButtonLabel: '',
          });
        }
      },
    });
    invalidatePrinterNozzleQuery(printer?.id);
  }, [
    printer,
    invalidatePrinterNozzleQuery,
    intl,
    printerModifications,
    showDialog,
    printersQuery,
  ]);

  const updatePrinterCameras = useCallback(async () => {
    const cameraIds = printerCamerasQuery.data.map((camera) => camera.id);
    const cameraValuesToUpdate = pick(
      printerModifications?.settings || {},
      cameraIds,
    );
    const cameraIdsToUpdate = Object.keys(cameraValuesToUpdate);

    if (!cameraIdsToUpdate.length) {
      return;
    }

    const printerCamerasToUpdate = cameraIdsToUpdate.reduce((acc, cameraId) => {
      return {
        ...acc,
        [cameraId]: {
          name: cameraValuesToUpdate?.[cameraId],
        },
      };
    }, {});

    await updatePrinterCamerasMutation.mutateAsync({
      printerId,
      printerCameras: printerCamerasToUpdate,
    });
  }, [
    printerCamerasQuery.data,
    printerModifications?.settings,
    printerId,
    updatePrinterCamerasMutation,
  ]);

  const handleSave = useCallback(async () => {
    if (isPrinterMonitorEnabled) {
      try {
        await updatePrinterCameras();
      } catch (_) {
        // error handling
      }
    }

    const updatedPrinter = generatePrinterObject();
    dispatch(
      updatePrinter(printer?.id, updatedPrinter, successUpdatePrinterCallback),
    );
    dispatch(changePrinterRobotType(updatedPrinter.robotType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    printer,
    printerModifications,
    machineSettings,
    updatePrinterCameras,
    isPrinterMonitorEnabled,
  ]);

  const generatePrinterObject = () => {
    const printerDefinition = {
      name: printer?.name,
      printerSettings: getSpecificUpdatedSettings(),
    };
    const actualSettings = getGenericUpdatedSettings();
    for (const setting of actualSettings) {
      printerDefinition[setting.settingName] = setting.value;
    }
    printerDefinition.machine = machineSettings;
    delete printerDefinition.machine.id;
    delete printerDefinition.robotType;
    return printerDefinition;
  };

  /**
   * Iterates through the list of printer settings that are specific to the printer
   * hardware (i.e. those saved in the printer.printerSettings list), and checks if
   * any values have been modified for each setting.
   *
   * Returns a list of the settings but with any modified values overriding the previous
   * values.
   * @returns list of hardware-specific printer settings with updated values
   */
  const getSpecificUpdatedSettings = () => {
    const modifiedSettings = printerModifications?.settings;
    if (!modifiedSettings) return printer?.printerSettings;
    return printer?.printerSettings.map((setting) => {
      const isSettingModificationPresent =
        modifiedSettings[setting.type] !== undefined &&
        modifiedSettings[setting.type] !== setting.value;

      return {
        ...setting,
        ...(isSettingModificationPresent
          ? { value: modifiedSettings[setting.type] }
          : {}),
      };
    });
  };

  /**
   * Iterates through the list of printer settings that are general to all printer
   * hardware (i.e. those saved individually as printer props), and checks if
   * any values have been modified for each setting.
   *
   * Returns a list of the settings but with any modified values overriding the previous
   * values.
   * @returns list of hardware-specific printer settings with updated values
   */
  const getGenericUpdatedSettings = () => {
    const modifiedSettings = printerModifications?.settings;
    const genericPrinterSettings = defaultPrinterSettings.concat(
      hardwareSelectionSettings,
    );
    if (!modifiedSettings) {
      return genericPrinterSettings.map((setting) => ({
        settingName: setting.settingName,
        value: printer[setting.settingName],
      }));
    }

    return genericPrinterSettings.map((setting) => {
      const settingName = setting?.settingName || '';
      const printerValue = printer?.[settingName] || '';
      const modifiedValue = modifiedSettings?.[settingName];

      const isSettingModificationPresent =
        modifiedValue !== undefined && modifiedValue !== printerValue;

      return {
        settingName,
        value: isSettingModificationPresent ? modifiedValue : printerValue,
      };
    });
  };

  const validateForm = async () => {
    const updatedPrinter = generatePrinterObject();
    try {
      await validationSchema.validate(updatedPrinter, {
        abortEarly: false, // Collect all validation errors
      });
    } catch (validationError) {
      return validationError.inner.reduce((acc, error) => {
        return error.message;
      }, {});
    }
    return {};
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      validate={validateForm}
      onSubmit={handleSave}
    >
      {({ handleSubmit, errors }) => (
        <SettingBar
          headerTitle={printer?.name}
          footerPrimaryButtonLabel={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          onHeaderLeadingIconButtonClick={handleSidebarCollapse}
          onFooterPrimaryButtonClick={handleSubmit}
          headerEndingIconButtonDropDownProps={{
            dropDownMenuItems: getPrinterDropDownMenuActions(printer),
          }}
          footerPrimaryButtonDisabled={isSubmitEnabled}
          withFooterDivider
        >
          <Settings
            printer={printer}
            printerCameras={printerCamerasQuery.data}
            errors={errors}
          />
        </SettingBar>
      )}
    </Formik>
  );
};

Editor.propTypes = {
  handleSidebarCollapse: PropTypes.func.isRequired,
  printer: PropTypes.object.isRequired,
};

export default Editor;
