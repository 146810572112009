import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import PageHeader from '@components/2-molecules/PageHeader';
import PrinterPrintingStatus from '@components/PrinterMonitor/PrinterPrintingStatus';
import PrinterRecordingDetails from '@components/PrinterMonitor/PrinterRecordingDetails';

const PrinterRecordingHeader = ({
  printerName,
  printerStatus = {},
  recording = {},
}) => {
  const isRecordingHistory = !isEmpty(recording);

  const title = useMemo(() => {
    if (isRecordingHistory) {
      return recording?.name;
    }

    return printerName;
  }, [printerName, recording?.name, isRecordingHistory]);

  const subtitle = useMemo(() => {
    if (isRecordingHistory) {
      return <PrinterRecordingDetails recording={recording} />;
    }

    return (
      <PrinterPrintingStatus
        printerStatus={printerStatus?.status}
        recordingStartedAt={printerStatus?.recordingStartedAt}
        ticking
      />
    );
  }, [
    printerStatus?.status,
    printerStatus?.recordingStartedAt,
    recording,
    isRecordingHistory,
  ]);

  return (
    <PageHeader
      title={title}
      renderSubtitleAs="div"
      subtitle={subtitle}
      withMarginTop
    />
  );
};

PrinterRecordingHeader.propTypes = {
  printerName: PropTypes.string,
  printerStatus: PropTypes.object,
  recording: PropTypes.object,
};

export default PrinterRecordingHeader;
