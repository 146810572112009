import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import useDialog from '@hooks/useDialog';
import Dialog from '@components/Workspaces/Dialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Field from '@components/1-atoms/Field';

const MODAL_ID = ModalDataTypes.EDIT_SINGLE_TEXT_FILED;

const EditSingleTextFieldDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const {
    dataTestId = 'edit-single-text-field-dialog',
    title = intl.formatMessage({
      id: 'general.edit',
      defaultMessage: 'Edit',
    }),
    secondaryButtonLabel = intl.formatMessage({
      id: 'general.cancel',
      defaultMessage: 'Cancel',
    }),
    primaryButtonLabel = intl.formatMessage({
      id: 'general.apply',
      defaultMessage: 'Apply',
    }),
    fieldName = 'name',
    fieldLabel,
    fieldPlaceholder,
    defaultValue,
    onSubmit,
    validationSchema = {},
  } = dialogData;

  const defaultValues = useMemo(
    () => ({
      [fieldName]: defaultValue,
    }),
    [fieldName, defaultValue],
  );

  const form = useForm({ defaultValues, mode: 'all' });
  const { isSubmitting } = form.formState;

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const handleSubmit = useCallback(
    async (values) => {
      await onSubmit?.(values?.[fieldName]);

      handleClose();
    },
    [onSubmit, fieldName, handleClose],
  );

  return (
    <Dialog
      dataTestId={dataTestId}
      dialogId={MODAL_ID}
      title={title}
      secondaryButtonLabel={secondaryButtonLabel}
      confirmButtonText={primaryButtonLabel}
      onSubmit={form.handleSubmit(handleSubmit)}
      onCancel={handleClose}
      loading={isSubmitting}
    >
      <Controller
        name={fieldName}
        control={form.control}
        rules={{
          required: intl.formatMessage({
            id: 'validation.message.can_not_be_empty',
            defaultMessage: 'Cannot be empty',
          }),
          ...validationSchema,
        }}
        render={({ field, fieldState }) => {
          const error = fieldState.isTouched && fieldState.error;

          return (
            <Field
              dataTestId={`${dataTestId}_field`}
              disabled={isSubmitting}
              name={fieldName}
              label={fieldLabel}
              placeholder={fieldPlaceholder}
              autoFocus
              error={!!error}
              supportingText={error?.message}
              {...field}
            />
          );
        }}
      />
    </Dialog>
  );
};

export default EditSingleTextFieldDialog;
