import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { Formik, Field as FormikField } from 'formik';
import Field from '@components/1-atoms/Field';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { Fields } from '@containers/Dialogs/CreateUserDialog/CreateUserDialog.styled';
import useLicenseMutations from '@app/hooks/license/useLicenseMutations';
import useSnackbar from '@app/hooks/useSnackbar';
import { useQueryClient } from '@tanstack/react-query';
import { licenseQueryKeys } from '@app/hooks/license/useLicenseQueries';
import DatePickerField from '@app/components/1-atoms/DatePickerField';
import moment from 'moment';
import DropDownField from '@app/components/1-atoms/DropDownField';
import usePrinter from '@app/hooks/printers/usePrinter';

const MODAL_ID = ModalDataTypes.REQUEST_DESKTOP_LICENSE;

const RequestLicenseDialog = () => {
  const intl = useIntl();
  const { hideDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { getAllPrinterDefinitions } = usePrinter();
  const { programTypeDefinitions } = getAllPrinterDefinitions();

  const { requestLicenseMutation } = useLicenseMutations();

  const createValidationSchema = (intl) => {
    return Yup.object().shape({
      targetEmailAddress: Yup.string()
        .required(
          intl.formatMessage({
            id: 'createuser.username.validation.required',
            defaultMessage: 'Email address cannot be empty',
          }),
        )
        .min(
          5,
          intl.formatMessage({
            id: 'createuser.username.validation.min',
            defaultMessage: 'Email address must be at least 5 characters long',
          }),
        )
        .max(
          70,
          intl.formatMessage({
            id: 'createuser.username.validation.max',
            defaultMessage:
              'Email address must be no longer than 70 characters',
          }),
        )
        .email(
          intl.formatMessage({
            id: 'createuser.username.validation.email',
            defaultMessage: 'Must be an email format',
          }),
        ),
    });
  };

  const validationSchema = useMemo(() => createValidationSchema(intl), [intl]);

  const formInitialValues = useMemo(
    () => ({
      targetEmailAddress: '',
      expiryDate: moment().add(1, 'years').format('yyyy-MM-DD'),
      customProgramTypes: {
        label: '',
        value: '',
        data: [],
      },
    }),
    [],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    async ({ targetEmailAddress, expiryDate, customProgramTypes }) => {
      try {
        await requestLicenseMutation.mutateAsync({
          targetEmailAddress,
          expiryDate,
          customProgramTypes: customProgramTypes.data,
        });
        queryClient.invalidateQueries({
          queryKey: licenseQueryKeys.allLicenses,
          refetchType: 'all',
        });
        showSnackbar({
          text: intl.formatMessage({
            id: 'license.issue.success_snackbar',
            defaultMessage: 'License successfully created and pending approval',
          }),
        });
      } catch (_) {
        //error
      }
      hideDialog(MODAL_ID);
    },
    [hideDialog, requestLicenseMutation, intl, showSnackbar, queryClient],
  );

  const onProgramTypeChange = useCallback(
    (programType, currentValue, setFieldValue) => {
      let newValue = currentValue ?? [];
      if (newValue.includes(programType.displayName)) {
        newValue = newValue.filter((v) => v !== programType.displayName);
      } else {
        newValue.push(programType.displayName);
      }
      setFieldValue('customProgramTypes', {
        label: currentValue.join(', '),
        value: '',
        data: newValue,
      });
    },
    [],
  );

  const getProgramTypeDropdownItems = useCallback(
    (values, setFieldValue) => {
      const data = values.customProgramTypes?.data;
      return programTypeDefinitions
        .filter((d) => !d.visibleToAll)
        .map((d) => {
          return {
            label: d.displayName,
            selected: data.includes(d.displayName),
            formFieldValue: {
              label: d.displayName,
              value: d.displayName,
            },
            endingButton: {
              type: 'checkbox',
              checked: data.includes(d.displayName),
              onChange: () => onProgramTypeChange(d, data, setFieldValue),
            },
          };
        });
    },
    [programTypeDefinitions, onProgramTypeChange],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <DialogPortal
          renderAsForm
          dataTestId="request-license"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'license.issue_button',
            defaultMessage: 'Issue license key',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'license.issue.header',
              defaultMessage: 'Issue a new license key',
            })}
            subtitle={intl.formatMessage({
              id: 'license.issue.subheader',
              defaultMessage:
                'Enter the email address of the person receiving the license key. Ensure the email is correct before submitting.',
            })}
          />

          <Fields>
            <FormikField
              component={Field}
              dataTestId="request-license__target_email_address"
              disabled={isSubmitting}
              name="targetEmailAddress"
              label={intl.formatMessage({
                id: 'createuser.username.label',
                defaultMessage: 'Email address',
              })}
            />
            <FormikField
              component={DatePickerField}
              dataTestId="request-license__expiry_date"
              disabled={isSubmitting}
              onChange={setFieldValue}
              name="expiryDate"
              label={intl.formatMessage({
                id: 'license.issue.expiry.label',
                defaultMessage: 'License expiry date',
              })}
            />
            <FormikField
              component={DropDownField}
              dataTestId="request-license__custom_program_types"
              disabled={isSubmitting}
              name="customProgramTypes"
              supportingText={intl.formatMessage({
                id: 'license.issue.custom_program_types.supporting_text',
                defaultMessage:
                  'If the user requires access to program type(s) that are not available in the public library, select them here. They will be granted access when they activate the license.',
              })}
              label={intl.formatMessage({
                id: 'license.issue.custom_program_types',
                defaultMessage: 'Custom program types',
              })}
              dropDownMenuItems={getProgramTypeDropdownItems(
                values,
                setFieldValue,
              )}
            />
          </Fields>
        </DialogPortal>
      )}
    </Formik>
  );
};

RequestLicenseDialog.propTypes = {};

export default RequestLicenseDialog;
