import React from 'react';
import RcSlider from 'rc-slider';
import PropTypes from 'prop-types';
import { Wrapper, SliderWrapper, MinTitle, MaxTitle } from './Slider.styled';

import 'rc-slider/assets/index.css';

const Slider = ({
  // Range variant props
  allowCross = true,
  className,
  dataTestId = 'slider',
  defaultValue,
  max,
  maxTitle,
  min,
  minTitle,
  onAfterChange,
  onBlur,
  onChange,
  onFocus,
  pushable = 0,
  range,
  step,
  value,
}) => {
  return (
    <Wrapper data-testid={dataTestId} className={className}>
      {minTitle && (
        <MinTitle dataTestId={`${dataTestId}__min-title`}>{minTitle}</MinTitle>
      )}

      <SliderWrapper dataTestId={`${dataTestId}__slider`}>
        {range ? (
          <RcSlider
            min={min}
            max={max}
            value={value}
            step={step}
            defaultValue={defaultValue}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onAfterChange={onAfterChange}
            allowCross={allowCross}
            pushable={pushable}
            range
          />
        ) : (
          <RcSlider
            min={min}
            max={max}
            value={value}
            step={step}
            defaultValue={defaultValue}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onAfterChange={onAfterChange}
          />
        )}
      </SliderWrapper>

      {maxTitle && (
        <MaxTitle dataTestId={`${dataTestId}__max-title`}>{maxTitle}</MaxTitle>
      )}
    </Wrapper>
  );
};

Slider.propTypes = {
  // Range variant props
  allowCross: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  max: PropTypes.number,
  maxTitle: PropTypes.string,
  min: PropTypes.number,
  minTitle: PropTypes.string,
  onAfterChange: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  pushable: PropTypes.number,
  range: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

export default Slider;
