import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Tick = styled.text`
  font-size: 8px;
`;
