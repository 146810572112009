import {
  Frame,
  ScrollableList,
  Wrapper,
} from '@pages/Licenses/Licenses.styled';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SETTING_TABS } from '@app/constants/settingTabs';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@app/components/2-molecules/PageHeader';
import SettingsTabs from '@app/components/SettingsTabs';
import useLicenseQueries from '@app/hooks/license/useLicenseQueries';
import SettingLink from '@app/components/2-molecules/SettingLink';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useDialog from '@app/hooks/useDialog';
import ListItem from '@app/components/1-atoms/ListItem';
import EmptyStateBox from '@app/components/2-molecules/EmptyStateBox';

const Licenses = () => {
  const { showDialog } = useDialog();
  const intl = useIntl();
  const { getAllLicenses } = useLicenseQueries();
  const data = getAllLicenses?.data?.sort((a, b) => {
    const aDate = a.sentAt ? new Date(a.sentAt) : new Date();
    const bDate = b.sentAt ? new Date(b.sentAt) : new Date();
    return bDate.getTime() - aDate.getTime();
  });
  const onRequestClick = useCallback(
    () => showDialog(ModalDataTypes.REQUEST_DESKTOP_LICENSE),
    [showDialog],
  );

  return (
    <Wrapper>
      <PageHeader
        dataTestId={`users-list__header`}
        size={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'navigation.link.settings',
          defaultMessage: 'Settings',
        })}
      />
      <SettingsTabs activeTab={SETTING_TABS.LICENSES.title} />
      <Frame>
        <SettingLink
          button={{
            children: (
              <FormattedMessage
                id="licenses.issue.button"
                defaultMessage={'Issue a new license key'}
              />
            ),
            onClick: onRequestClick,
          }}
          label={intl.formatMessage({
            id: 'licenses.issue.header',
            defaultMessage: 'Desktop App License',
          })}
        />
      </Frame>
      <PageHeader
        dataTestId={`settings-page__licenses_list`}
        variant={'small'}
        title={intl.formatMessage({
          id: 'licenses.license_keys.header',
          defaultMessage: 'License keys',
        })}
      />
      <ScrollableList>
        {data?.map((l) => (
          <ListItem
            autoHeight
            key={l.id}
            leadingIconName="key"
            label={l.licenseKeySnippet}
            description={
              <>
                <FormattedMessage
                  id="licenses.license.sent_on"
                  defaultMessage={'Sent on: {sentOn}'}
                  values={{
                    sentOn: l.sentAt
                      ? new Date(l.sentAt).toLocaleDateString()
                      : 'Not sent yet',
                  }}
                />
                <br />
                <FormattedMessage
                  id="licenses.license.send_to"
                  defaultMessage={'Send to: {sendTo}'}
                  values={{ sendTo: l.sentTo }}
                />
                <br />
                <FormattedMessage
                  id="licenses.license.status"
                  defaultMessage={'Status: {status}'}
                  values={{ status: l.status }}
                />
              </>
            }
          />
        ))}
        {data?.length === 0 && (
          <EmptyStateBox
            dataTestId={'licenses__empty-state'}
            iconName="key"
            title={intl.formatMessage({
              id: 'licenses.no_licenses.title',
              defaultMessage: 'No Licenses Issued Yet',
            })}
            primaryButtonIconName="add_0"
            primaryButtonLabel={intl.formatMessage({
              id: 'licenses.no_licenses.issue.button',
              defaultMessage: 'Issue a new license',
            })}
            onPrimaryButtonClick={onRequestClick}
          />
        )}
      </ScrollableList>
    </Wrapper>
  );
};

Licenses.propTypes = {};

export default Licenses;
