import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const toolQueryKeys = {
  tools: ['showLoader', 'tools'],
};

export default function useToolQueries() {
  const toolsQuery = useQuery({
    queryKey: toolQueryKeys.tools,
    queryFn: () => client.get(endpoints.tools).then((res) => res.data),
    initialData: [],
    enabled: false,
  });

  return {
    toolsQuery,
  };
}
