import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { toolQueryKeys } from '@hooks/tools/useToolQueries';

export const toolMutationKeys = {
  deleteTool: ['showLoader', 'deleteTool'],
  updateTool: ['updateTool'],
  createTool: ['showLoader', 'createTool'],
  cloneTool: ['showLoader', 'cloneTool'],
};

export default function useToolMutations() {
  const queryClient = useQueryClient();

  const createToolMutation = useMutation({
    mutationKey: toolMutationKeys.createTool,
    mutationFn: (tool = {}) =>
      client.post(`${endpoints.tools}`, tool).then((res) => res.data),
    onSuccess: (addedTool) => {
      const updater = (data) => {
        return data ? [...data, addedTool] : [addedTool];
      };
      queryClient.setQueryData(toolQueryKeys.tools, updater);
    },
  });

  const updateToolMutation = useMutation({
    mutationKey: toolMutationKeys.updateTool,
    mutationFn: ({ tool } = {}) =>
      client
        .put(endpoints.tool.replace(':toolId', tool.id), tool)
        .then((res) => res.data),
    onSuccess: (_, { tool }) => {
      const updater = (data) => {
        return data
          ? data.map((alreadyPresentTool) =>
              alreadyPresentTool.id === tool.id
                ? {
                    ...alreadyPresentTool,
                    ...tool,
                  }
                : alreadyPresentTool,
            )
          : undefined;
      };
      queryClient.setQueryData(toolQueryKeys.tools, updater);
    },
  });

  const cloneToolMutation = useMutation({
    mutationKey: toolMutationKeys.cloneTool,
    mutationFn: ({ tool, id } = {}) =>
      client
        .put(endpoints.cloneTool.replace(':toolId', id), tool)
        .then((res) => res.data),
    onSuccess: (addedTool) => {
      const updater = (data) => {
        return data ? [...data, addedTool] : [addedTool];
      };
      queryClient.setQueryData(toolQueryKeys.tools, updater);
    },
  });

  const deleteToolMutation = useMutation({
    mutationKey: toolMutationKeys.deleteTool,
    mutationFn: ({ id } = {}) =>
      client
        .delete(endpoints.tool.replace(':toolId', id))
        .then((res) => res.data),
    onSuccess: (_, { id }) => {
      const updater = (data) => {
        return data ? data.filter((tool) => tool.id !== id) : [];
      };
      queryClient.setQueryData(toolQueryKeys.tools, updater);
    },
  });

  return {
    createToolMutation,
    cloneToolMutation,
    updateToolMutation,
    deleteToolMutation,
  };
}
