export const printerConstants = {
  scaleRatio: 0.02,
  zMovement: 'zMovement',
  components: 'components',
  plinth: 'plinth',
  plinthType: 'plinthType',
  postProcessor: 'postProcessor',
  programType: 'programType',
  basePlinthType: 'basePlinthType',
  printingBedType: 'printingBedType',
  extruderType: 'extruderType',
  extrusionType: 'extrusionType',
  robotType: 'robotType',
  extruderSettingsType: 'extruderSettingsType',
  printingBedSettingsType: 'printingBedSettingsType',
  plinthSettingsType: 'plinthSettingsType',
  enclosureType: 'enclosureType',
  THERMOPLASTIC_PELLET: 'THERMOPLASTIC_PELLET',
  THERMOPLASTIC_FILAMENT: 'THERMOPLASTIC_FILAMENT',
  METAL_WIRE: 'METAL_WIRE',
  THERMOSET: 'THERMOSET',
  enclosure: 'enclosure',
  NoEnclosure: 'NoEnclosure',
  flange: 'flange',
  TCP: 'TCP',
  ROBOT: 'ROBOT',
  BASE: 'BASE',
  flangeMounting: 'flangeMounting',
  defaultHomePosition: 'defaultHomePosition',
  homePositionCorrections: 'homePositionCorrections',
  axis1Home: 'axis1Home',
  axis2Home: 'axis2Home',
  axis3Home: 'axis3Home',
  homeA: 'homeA',
  homeB: 'homeB',
  homeC: 'homeC',
  homeD: 'homeD',
  homeE: 'homeE',
  homeF: 'homeF',
  SixAxis: 'SixAxis',
  Link: 'Link',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  axis: 'axis',
  _: '_',
  _Material: '_Material',
  Axis1: 'Axis1',
  Axis2: 'Axis2',
  Axis3: 'Axis3',
  toolMountingAngle: 'toolMountingAngle',
  toolZ: 'toolZ',
  toolY: 'toolY',
  toolX: 'toolX',
  toolA: 'toolA',
  toolB: 'toolB',
  toolC: 'toolC',
  defaultBaseId: 'defaultBaseId',
  defaultToolId: 'defaultToolId',
  tcpCorrectionX: 'tcpCorrectionX',
  tcpCorrectionY: 'tcpCorrectionY',
  tcpCorrectionZ: 'tcpCorrectionZ',
  position: 'position',
  printerSettings: 'printerSettings',
  printingBed: 'printingBed',
  baseRef1X: 'baseRef1X',
  baseRef1Y: 'baseRef1Y',
  baseRef1Z: 'baseRef1Z',
  SECONDARY_TOOL_X: 'SECONDARY_TOOL_X',
  SECONDARY_TOOL_Y: 'SECONDARY_TOOL_Y',
  SECONDARY_TOOL_Z: 'SECONDARY_TOOL_Z',
  SECONDARY_TOOL_A: 'SECONDARY_TOOL_A',
  SECONDARY_TOOL_B: 'SECONDARY_TOOL_B',
  SECONDARY_TOOL_C: 'SECONDARY_TOOL_C',
  TERTIARY_TOOL_X: 'TERTIARY_TOOL_X',
  TERTIARY_TOOL_Y: 'TERTIARY_TOOL_Y',
  TERTIARY_TOOL_Z: 'TERTIARY_TOOL_Z',
  TERTIARY_TOOL_A: 'TERTIARY_TOOL_A',
  TERTIARY_TOOL_B: 'TERTIARY_TOOL_B',
  TERTIARY_TOOL_C: 'TERTIARY_TOOL_C',
  BED: 'BED',
  EXTRUDER: 'EXTRUDER',
  hotend: 'hotend',
  xAxis: 'xAxis',
  yAxis: 'yAxis',
  zAxis: 'zAxis',
  root: 'root',
  independent: 'independent',
  workspaceX1: 'workspaceX1',
  workspaceX2: 'workspaceX2',
  workspaceY1: 'workspaceY1',
  workspaceY2: 'workspaceY2',
  workspaceZ1: 'workspaceZ1',
  workspaceZ2: 'workspaceZ2',
  workspaceOriginX: 'workspaceOriginX',
  workspaceOriginY: 'workspaceOriginY',
  workspaceOriginZ: 'workspaceOriginZ',
  ParameterizedGeometry: 'ParameterizedGeometry',
  baseRef2X: 'baseRef2X',
  baseRef2Y: 'baseRef2Y',
  baseRef2Z: 'baseRef2Z',
  baseRef3X: 'baseRef3X',
  baseRef3Y: 'baseRef3Y',
  baseRef3Z: 'baseRef3Z',
  normal: 'normal',
  color: 'color',
  coupledLinkA: 'LinkAGroup',
  coupledLinkB: 'LinkBGroup',
  coupledLinkC: 'LinkCGroup',
  part: 'part',
  pivot: 'pivot',
  printingObject: 'printingObject',
  redHex: '#FF0000',
  LINEAR_RAIL_HOME_POSITION: 'LINEAR_RAIL_HOME_POSITION',
  ROTARY_HOME_ANGLE: 'ROTARY_HOME_ANGLE',
  ROTARY_ZERO_ANGLE: 'ROTARY_ZERO_ANGLE',
  TWO_AXIS_POSITIONER_JOINT_1_HOME_ANGLE:
    'TWO_AXIS_POSITIONER_JOINT_1_HOME_ANGLE',
  TWO_AXIS_POSITIONER_JOINT_2_HOME_ANGLE:
    'TWO_AXIS_POSITIONER_JOINT_2_HOME_ANGLE',
  TWO_AXIS_POSITIONER_OFFSET: 'TWO_AXIS_POSITIONER_OFFSET',
  machine: 'machine',
  axisMin1: 'axisMin1',
  axisMin2: 'axisMin2',
  axisMin3: 'axisMin3',
  axisMin4: 'axisMin4',
  axisMin5: 'axisMin5',
  axisMin6: 'axisMin6',
  axisMax1: 'axisMax1',
  axisMax2: 'axisMax2',
  axisMax3: 'axisMax3',
  axisMax4: 'axisMax4',
  axisMax5: 'axisMax5',
  axisMax6: 'axisMax6',
  axisA1: 'axisA1',
  axisA2: 'axisA2',
  axisA3: 'axisA3',
  axisA4: 'axisA4',
  axisA5: 'axisA5',
  axisA6: 'axisA6',
  axisD1: 'axisD1',
  axisD2: 'axisD2',
  axisD3: 'axisD3',
  axisD4: 'axisD4',
  axisD5: 'axisD5',
  axisD6: 'axisD6',
  Gantry: 'Gantry',
  Gantry5Axis: 'Gantry5Axis',
  jointAngle1: 'jointAngle1',
  jointAngle2: 'jointAngle2',
  jointAngle3: 'jointAngle3',
  jointAngle4: 'jointAngle4',
  jointAngle5: 'jointAngle5',
  componentMovementX: 'x',
  componentMovementY: 'y',
  componentMovementZ: 'z',
  CUSTOM: {
    label: 'Custom',
    value: 'CUSTOM',
    actions: ['edit'],
  },
};

export const printerSettingTypes = {
  camera: 'CAMERA',
};
