import ReconnectingWebSocket from 'reconnecting-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { getConfig } from '@actions/AuthenticatedFetch';
import { getCurrentUser } from '@selectors/loginSelectors';
import usePrinter from '@hooks/printers/usePrinter';
import {
  CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
  CONNECT_TO_TOOLPATH_SOCKET,
  EVENTS_WEBSOCKET_CREATED,
  PRINTER_HEARTBEAT,
  PRINT_STATUS,
  ALL_SENSORS_DATA,
} from '@constants/actionTypes';
import { tokenAuthentication } from '@actions/loginActions';
import { PrinterStatuses } from '@constants/printerStatuses';
import { WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT } from '@constants/websocket';
import { useCallback, useEffect, useMemo } from 'react';

const WS_MESSAGE_TYPE = {
  ALL_SENSORS_DATA: 'ALL_SENSORS_DATA',
  PRINTER_HEARTBEAT: 'PRINTER_HEARTBEAT',
  PRINT_STATUS: 'PRINT_STATUS',
};

const PRINTER_HEARTBEAT_TIMEOUT_MS = 60000;

let wsInstance = null;
let isWsInitialized = false;
const timeoutOfPrinters = {};

export default function usePrinterSocket() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser());

  const { updatePrinterMonitorStatusData } = usePrinter();

  const Config = getConfig();
  const baseUrl = Config.baseUrlws || 'ws://localhost/';

  const urlProvider = useCallback(async () => {
    const token = currentUser?.token;
    return baseUrl + 'ws/events' + '?token=' + encodeURIComponent(token);
  }, [baseUrl, currentUser?.token]);

  const wsOptions = useMemo(
    () => ({
      connectionTimeout: 2000,
      minReconnectionDelay: 500,
      maxReconnectionDelay: 3000,
    }),
    [],
  );

  if (!wsInstance) {
    wsInstance = new ReconnectingWebSocket(urlProvider, [], wsOptions);
  }

  const handlePrintersReceivedMessage = useCallback(
    (message) => {
      const event = JSON.parse(message.data);

      switch (event.type) {
        case WS_MESSAGE_TYPE.ALL_SENSORS_DATA:
          dispatch({
            type: ALL_SENSORS_DATA,
            payload: {
              printerId: event.data.printerId,
              sensorsData: event.data.payload,
              time: event.data.time,
            },
          });
          break;

        case WS_MESSAGE_TYPE.PRINTER_HEARTBEAT: {
          const printerId = event?.data?.printerId;
          if (timeoutOfPrinters[printerId]) {
            clearTimeout(timeoutOfPrinters[printerId]);
          }
          timeoutOfPrinters[printerId] = setTimeout(() => {
            timeoutOfPrinters[printerId] = null;
            dispatch({
              type: PRINTER_HEARTBEAT,
              payload: {
                printerId,
                status: PrinterStatuses.OFFLINE,
                time: 0,
              },
            });
          }, PRINTER_HEARTBEAT_TIMEOUT_MS);
          dispatch({
            type: PRINTER_HEARTBEAT,
            payload: event.data,
          });

          updatePrinterMonitorStatusData(printerId, event.data);
          break;
        }

        case WS_MESSAGE_TYPE.PRINT_STATUS:
          dispatch({
            type: PRINT_STATUS,
            payload: {
              printerId: event.data.printerId,
              printId: event.data.printId,
              instructionsComplete: event.data.instructionsComplete,
              instructionsRemaining: event.data.instructionsRemaining,
              time: event.data.time,
            },
          });
          break;
        default:
          break;
      }
    },
    [dispatch, updatePrinterMonitorStatusData],
  );

  useEffect(() => {
    if (!isWsInitialized) {
      isWsInitialized = true;

      wsInstance.addEventListener('open', () => {
        if (DEVELOPMENT_ENV) {
          // eslint-disable-next-line no-console
          console.log('WebSocket open');
        }

        dispatch({
          type: CONNECT_TO_TOOLPATH_SOCKET,
        });
      });

      dispatch({
        type: EVENTS_WEBSOCKET_CREATED,
        payload: wsInstance,
      });

      wsInstance.addEventListener('close', (closeEvent) => {
        const retryCount = closeEvent?.target?._retryCount || 0;
        const shouldDispatchCloseAction = retryCount <= 1;
        const shouldValidateToken =
          retryCount === WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT;

        if (DEVELOPMENT_ENV) {
          // eslint-disable-next-line no-console
          console.log('WebSocket closed');
        }

        if (shouldDispatchCloseAction) {
          dispatch({
            type: CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
          });
        }

        if (shouldValidateToken) {
          dispatch(tokenAuthentication());
        }
      });

      wsInstance.addEventListener('message', handlePrintersReceivedMessage);
    }

    return () => {
      // Do not close the WebSocket or remove listeners here, since other components may be using it
    };
  }, [dispatch, handlePrintersReceivedMessage]);

  return wsInstance;
}
