import getIntlProvider from '@utils/getIntlProvider';
import { ROUTES } from '@constants/router';

const intl = getIntlProvider();

export const SETTING_TABS = {
  ACCOUNT: {
    id: 'settings-tab-1',
    title: intl.formatMessage({
      id: 'settings.tab.account.title',
      defaultMessage: 'Account',
    }),
    link: ROUTES.SETTINGS_ACCOUNT,
  },
  PREFERENCES: {
    id: 'settings-tab-2',
    title: intl.formatMessage({
      id: 'settings.tab.preferences.title',
      defaultMessage: 'Preferences',
    }),
    link: ROUTES.SETTINGS_PREFERENCES,
  },
  USERS: {
    id: 'settings-tab-3',
    title: intl.formatMessage({
      id: 'settings.tab.users.title',
      defaultMessage: 'Users',
    }),
    link: ROUTES.SETTINGS_USERS,
  },
  LICENSES: {
    id: 'settings-tab-4',
    title: intl.formatMessage({
      id: 'settings.tab.licenses.title',
      defaultMessage: 'Licenses',
    }),
    link: ROUTES.SETTINGS_LICENSES,
  },
};
