import React, { useEffect } from 'react';
import { Wrapper } from '@pages/Tools/Tools.styled';
import useToolQueries from '@hooks/tools/useToolQueries';
import ToolsPageContent from '@components/ToolsPageContent';

export const Tools = () => {
  const { toolsQuery } = useToolQueries();
  const { refetch: refetchToolsQuery } = toolsQuery;

  const isFetching = !toolsQuery.isFetched || toolsQuery.isLoading;

  useEffect(() => {
    refetchToolsQuery();
  }, [refetchToolsQuery]);

  return (
    <Wrapper>
      <ToolsPageContent
        toolsList={toolsQuery?.data}
        loading={isFetching}
        withEndingButton
      />
    </Wrapper>
  );
};

Tools.propTypes = {};

export default Tools;
