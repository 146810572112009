import { Redirect, useHistory, useParams } from 'react-router-dom';
import useMaterial from '@hooks/materials/useMaterial';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
  PAGE_HEADER_VARIANT_SMALL,
} from '@components/2-molecules/PageHeader';
import React, { useCallback, useMemo } from 'react';
import { ROUTES } from '@constants/router';
import { useIntl } from 'react-intl';
import {
  Frame,
  HeatZonesDiagram,
  PageWrapper,
  Wrapper,
  ContentWrapper,
} from '@pages/Material/Material.styled';
import * as Yup from 'yup';
import { Field as FormikField, Formik } from 'formik';
import useMaterialList from '@hooks/materials/useMaterialList';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import SettingTextField from '@components/2-molecules/SettingTextField';
import SettingInfo from '@components/2-molecules/SettingInfo';
import SettingTextArea from '@components/2-molecules/SettingTextArea';
import { materialConstants } from '@constants/materials';
import useMaterialMutations from '@hooks/materials/useMaterialMutations';
import { mapTypeForDropdown } from '@utils/render';
import { filterFields } from '@utils/filter';
import { createNumberValidator } from '@utils/validationHelper';

const deserializerMaterial = (material) => {
  if (!material) {
    return {};
  }

  const transformedObjects = Object.keys(material).map((key) => {
    const value = material[key] == null ? '' : material[key];

    if (key === 'type') {
      return {
        [key]: mapTypeForDropdown(value),
      };
    }

    if (key !== materialConstants.HEAT_ZONE_LIST_PROPERTY) {
      return {
        [key]: `${value}`,
      };
    }

    const heatZoneDetails = {};
    const heatZones = value;

    for (let index = 0; index < heatZones.length; index++) {
      const key = `heatZone${index + 1}`;
      const heatZone = index < heatZones.length ? heatZones[index] : '';

      heatZoneDetails[key] = `${heatZone ?? ''}`;
    }

    return heatZoneDetails;
  });

  // Merge mapped properties
  return Object.assign({}, ...transformedObjects);
};

// Transform properties for update
const serializeMaterial = (material) => {
  function toValue(val) {
    return val == '' ? null : val;
  }

  if (!material) {
    return {};
  }

  const result = Object.entries(material).reduce((acc, [key, value]) => {
    if (key === 'type') {
      acc[key] = value.value;
      return acc;
    }

    if (!/^heatZone\d$/.test(key)) {
      acc[key] = toValue(value);
      return acc;
    }

    if (!(materialConstants.HEAT_ZONE_LIST_PROPERTY in acc)) {
      acc[materialConstants.HEAT_ZONE_LIST_PROPERTY] = [null, null, null];
    }

    const index = parseInt(key.slice(-1), 10) - 1;

    acc[materialConstants.HEAT_ZONE_LIST_PROPERTY][index] = toValue(value);

    return acc;
  }, {});

  if (materialConstants.MATERIAL_FILAMENT === material.type.value) {
    result[materialConstants.HEAT_ZONE_LIST_PROPERTY].pop();
  }

  return result;
};

export const Material = () => {
  const { materialId } = useParams();
  const intl = useIntl();
  const { getSelectedMaterial } = useMaterial();
  const history = useHistory();
  const { getMaterialTypeDropDownMenuItems } = useMaterialList();
  const { updateMaterialMutation } = useMaterialMutations();

  const selectedMaterial = getSelectedMaterial(materialId);
  const readOnly = selectedMaterial?.isInGlobalLibrary;
  const material = useMemo(
    () => deserializerMaterial(selectedMaterial),
    [selectedMaterial],
  );

  const handleGoBack = useCallback(() => {
    history.push(ROUTES.MATERIALS);
  }, [history]);

  const isFieldTypeOf = useCallback((field, type) => field?.type === type, []);

  const materialInformationFields = useMemo(
    () => [
      {
        type: 'dropdown',
        label: intl.formatMessage({
          id: 'material.material_information.field_label.meterial_type',
          defaultMessage: 'Material Type',
        }),
        name: 'type',
        validator: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'materials.create_material_dialog.type.validation.required',
              defaultMessage: 'Please select an option',
            }),
          ),
        hasLabel: true,
        editable: false,
      },
      {
        label: intl.formatMessage({
          id: 'material.material_information.field_label.material_name',
          defaultMessage: 'Material Name',
        }),
        validator: Yup.string()
          .required(
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          )
          .min(
            2,
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.min',
              defaultMessage: 'Name must be at least 2 characters long',
            }),
          )
          .max(
            128,
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.max',
              defaultMessage: 'Name must be no longer than 128 characters',
            }),
          )
          .matches(
            /^[\w .]+$/, // should only contain letters, digits, dots, underscores, and spaces
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.invalid',
              defaultMessage:
                'Should only contain letters, digits, dots, underscores, and spaces',
            }),
          ),
        name: 'materialName',
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.material_information.field_label.brand',
          defaultMessage: 'Brand',
        }),
        name: 'brand',
        validator: Yup.string().max(
          128,
          intl.formatMessage(
            {
              id: 'material.validation.characters.smaller.than.value',
              defaultMessage: `Smaller than 128 characters`,
            },
            { value: 128 },
          ),
        ),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.material_information.field_label.cost_per_kg',
          defaultMessage: 'Cost / kg',
        }),
        name: 'costPerKg',
        fieldType: 'number',
        validator: Yup.number()
          .min(
            0,
            intl.formatMessage({
              id: 'material.validation.greater.than.zero',
              defaultMessage: 'Greater than 0',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'material.validation.cannot.be.empty',
              defaultMessage: 'Cannot be empty',
            }),
          ),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.material_information.field_label.drying_temperature',
          defaultMessage: 'Drying Temperature (°C) (Recommended)',
        }),
        name: 'dryingTemperature',
        fieldType: 'number',
        validator: createNumberValidator(0, 500, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.material_information.field_label.drying_time',
          defaultMessage: 'Drying Time (hrs) (Recommended)',
        }),
        name: 'dryingTime',
        editable: true,
        fieldType: 'number',
        validator: createNumberValidator(0, 24, false, true),
      },
      {
        type: 'textarea',
        label: intl.formatMessage({
          id: 'material.material_information.field_label.material_description',
          defaultMessage: 'Note',
        }),
        placeholder: intl.formatMessage({
          id: 'material.description.field_label.generic_unfilled_pla',
          defaultMessage:
            'Generic unfilled PLA. Exists in materials database by default.',
        }),
        name: 'materialDescription',
        editable: true,
        validator: Yup.string().max(
          512,
          intl.formatMessage(
            {
              id: 'material.validation.characters.smaller.than.value',
              defaultMessage: `Smaller than 512 characters`,
            },
            { value: 512 },
          ),
        ),
      },
    ],
    [intl],
  );

  const technicalDataFields = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'material.technical_data.field_label.density',
          defaultMessage: 'Density(kg/m3)',
        }),
        name: 'materialDensity',
        fieldType: 'number',
        validator: createNumberValidator(0, 3000, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.technical_data.field_label.specific_heat_capacity',
          defaultMessage: 'Specific heat capacity (J/kg.K)',
        }),
        name: 'specificHeatCapacity',
        fieldType: 'number',
        validator: createNumberValidator(0, 5000, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.technical_data.field_label.thermal_conductivity',
          defaultMessage: 'Thermal conductivity, Z (W/m.K)',
        }),
        name: 'thermalConductivityZ',
        fieldType: 'number',
        validator: createNumberValidator(0, 3, false, true),
        editable: true,
      },
    ],
    [intl],
  );

  const printSettingsFields = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.deposition_factor',
          defaultMessage: 'Deposition Factor',
        }),
        name: 'extrusionFactor',
        fieldType: 'number',
        validator: createNumberValidator(0, 100, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.filament_diameter',
          defaultMessage: 'Filament Diameter (mm)',
        }),
        name: 'filamentDiameter',
        fieldType: 'number',
        validator: createNumberValidator(0, 5, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.feed_zone_t',
          defaultMessage: 'Feed Zone T (℃)',
        }),
        name: 'feedZoneT',
        fieldType: 'number',
        validator: createNumberValidator(0, 500, true, false),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.heat_zone_1',
          defaultMessage: 'Heat Zone 1 (℃)',
        }),
        fieldType: 'number',
        validator: createNumberValidator(0, 800, false, true),
        name: 'heatZone1',
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.heat_zone_2',
          defaultMessage: 'Heat Zone 2 (℃)',
        }),
        name: 'heatZone2',
        fieldType: 'number',
        validator: createNumberValidator(0, 800, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.heat_zone_3',
          defaultMessage: 'Heat Zone 3 (℃)',
        }),
        name: 'heatZone3',
        fieldType: 'number',
        validator: createNumberValidator(0, 800, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.heat_zone_t_optional',
          defaultMessage: 'Nozzle Zone T (℃) (Optional)',
        }),
        fieldType: 'number',
        validator: createNumberValidator(0, 500, true, false),
        name: 'nozzleZoneT',
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.bed_temp',
          defaultMessage: 'Bed temperature (℃)',
        }),
        fieldType: 'number',
        validator: createNumberValidator(0, 300, true, false),
        name: 'bedT',
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.energy_density',
          defaultMessage: 'Energy density (J/mm³)',
        }),
        fieldType: 'number',
        validator: createNumberValidator(0, 1000, true, true),
        name: 'energyDensity',
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.cell_temp',
          defaultMessage: 'Cell temperature (℃)',
        }),
        fieldType: 'number',
        validator: createNumberValidator(0, 150, true, false),
        name: 'cellTemperature',
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.min_prev_layer_temp',
          defaultMessage: 'Minimum prev layer temp(℃)',
        }),
        name: 'minPrevLayerTemperature',
        fieldType: 'number',
        validator: createNumberValidator(0, 300, false, true),
        editable: true,
      },
      {
        label: intl.formatMessage({
          id: 'material.print_settings.field_label.max_prev_layer_temp',
          defaultMessage: 'Maximum prev layer temp(℃)',
        }),
        name: 'maxPrevLayerTemperature',
        fieldType: 'number',
        validator: createNumberValidator(0, 300, false, true),
        editable: true,
      },
    ],
    [intl],
  );

  const showHeatZonesDiagram = useMemo(
    () =>
      selectedMaterial?.type === materialConstants.MATERIAL_FILAMENT ||
      selectedMaterial?.type === materialConstants.MATERIAL_PELLET,
    [selectedMaterial],
  );

  const allFields = useMemo(
    () =>
      filterFields(
        [
          ...materialInformationFields,
          ...printSettingsFields,
          ...technicalDataFields,
        ],
        material,
      ),
    [
      materialInformationFields,
      printSettingsFields,
      technicalDataFields,
      material,
    ],
  );

  const columns = useMemo(() => {
    const cols = [
      {
        title: intl.formatMessage({
          id: 'material.column.title.material_information',
          defaultMessage: 'Material Information',
        }),
        fields: filterFields(materialInformationFields, material),
      },
      {
        title: intl.formatMessage({
          id: 'material.column.title.technical_data',
          defaultMessage: 'Technical Data',
        }),
        fields: filterFields(technicalDataFields, material),
      },
      {
        title: intl.formatMessage({
          id: 'material.column.title.print_settings',
          defaultMessage: 'Print Settings',
        }),
        fields: filterFields(printSettingsFields, material),
        heatZonesDiagram: true,
      },
    ];

    return cols;
  }, [
    intl,
    materialInformationFields,
    printSettingsFields,
    technicalDataFields,
    material,
  ]);

  const onFormSubmit = useCallback(
    async (values) => {
      const materialUpdate = serializeMaterial(values);

      materialUpdate.id = selectedMaterial.id;
      try {
        await updateMaterialMutation.mutateAsync({ material: materialUpdate });
      } catch (_) {
        //error
      }
    },
    [updateMaterialMutation, selectedMaterial],
  );

  const handleSetFieldValue = useCallback(
    (setFieldValue, name) => (e) => {
      setFieldValue(name, e.target.value);
    },
    [],
  );

  const formValidationSchema = useMemo(() => {
    const schemaFields = allFields.reduce((acc, field) => {
      if (field.validator) {
        acc[field.name] = field.validator;
      }
      return acc;
    }, {});

    return Yup.object().shape(schemaFields);
  }, [allFields]);

  const initialFormValues = useMemo(
    () =>
      allFields.reduce(
        (acc, field) => ({
          ...acc,
          [field?.name]: material[field?.name],
        }),
        {},
      ),
    [allFields, material],
  );

  const renderReadOnlyField = useCallback((columnField, values) => {
    return (
      <SettingInfo
        key={`material__material${columnField.name}__info-field`}
        dataTestId={`material__material${columnField.name}__info-field`}
        infoIconName={''}
        label={columnField.label}
        infoText={
          columnField.hasLabel
            ? values[columnField.name].label
            : values[columnField.name] && String(values[columnField.name])
        }
      />
    );
  }, []);

  if (!selectedMaterial) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <PageWrapper>
      <PageHeader
        dataTestId={`material__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={selectedMaterial?.materialName}
        leadingIconButtonIconName={'arrow_back_0'}
        onLeadingIconButtonClick={handleGoBack}
      />
      <Wrapper>
        <ContentWrapper>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={onFormSubmit}
          >
            {({ values, errors, setFieldValue, handleSubmit }) => {
              return (
                <>
                  {columns?.map((column) => (
                    <>
                      {column?.fields?.length > 0 && (
                        <>
                          <PageHeader
                            dataTestId={`material__page__${column.title}-header`}
                            variant={PAGE_HEADER_VARIANT_SMALL}
                            title={column.title}
                            key={`material__page__${column.title}-header`}
                          />
                          <Frame
                            key={`material__page__${column.title}-frame-header`}
                          >
                            {column.heatZonesDiagram &&
                              showHeatZonesDiagram && (
                                <HeatZonesDiagram
                                  key={`material__material${selectedMaterial?.type}__diagram`}
                                  $materialType={selectedMaterial?.type?.toLowerCase()}
                                />
                              )}
                            {column?.fields?.map((columnField) => (
                              <>
                                {isFieldTypeOf(columnField, 'dropdown') &&
                                  (readOnly ? (
                                    renderReadOnlyField(columnField, values)
                                  ) : (
                                    <FormikField
                                      key={`material__material-${columnField.name}__dropdown-field`}
                                      dataTestId={`material__material-${columnField.name}__dropdown-field`}
                                      component={SettingDropDown}
                                      label={columnField.label}
                                      name={columnField.name}
                                      dropDownField={{
                                        disabled: !columnField.editable,
                                        dropDownMenuItems:
                                          getMaterialTypeDropDownMenuItems(
                                            values,
                                          ),
                                        fullWidthDropDownMenu: false,
                                      }}
                                    />
                                  ))}

                                {isFieldTypeOf(columnField, 'textarea') &&
                                  (readOnly ? (
                                    renderReadOnlyField(columnField, values)
                                  ) : (
                                    <SettingTextArea
                                      key={`material__material${columnField.name}__textarea-field`}
                                      dataTestId={`material__material${columnField.name}__textarea-field`}
                                      component={SettingTextArea}
                                      label={columnField.label}
                                      fieldProps={{
                                        disabled:
                                          readOnly || !columnField.editable,
                                        placeholder: columnField.placeholder,
                                        value: values[columnField.name],
                                        onBlur: handleSubmit,
                                        error: !!errors[columnField.name],
                                        supportingText:
                                          errors[columnField.name],
                                        onChange: handleSetFieldValue(
                                          setFieldValue,
                                          columnField.name,
                                        ),
                                      }}
                                    />
                                  ))}

                                {isFieldTypeOf(columnField) &&
                                  (readOnly ? (
                                    renderReadOnlyField(columnField, values)
                                  ) : (
                                    <SettingTextField
                                      key={`material__material${columnField.name}__field`}
                                      dataTestId={`material__material${columnField.name}__field`}
                                      label={columnField.label}
                                      field1={{
                                        ...(columnField.fieldType && {
                                          type: columnField.fieldType,
                                        }),
                                        disabled: !columnField.editable,
                                        value: values[columnField.name],
                                        onBlur: handleSubmit,
                                        error: !!errors[columnField.name],
                                        supportingText:
                                          errors[columnField.name],
                                        onChange: handleSetFieldValue(
                                          setFieldValue,
                                          columnField.name,
                                        ),
                                      }}
                                    />
                                  ))}
                              </>
                            ))}
                          </Frame>
                        </>
                      )}
                    </>
                  ))}
                </>
              );
            }}
          </Formik>
        </ContentWrapper>
      </Wrapper>
    </PageWrapper>
  );
};

Material.propTypes = {};

export default Material;
