import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import Text, {
  TEXT_VARIANT_LABEL_LARGE,
  TEXT_VARIANT_BODY_MEDIUM,
} from '@components/1-atoms/Text';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import { Wrapper as ButtonWrapper } from '@components/1-atoms/Button/Button.styled';
import { flexColumn, flexCenterVertical, when } from '@stylesheets/helpers';

export const Header = styled.div`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level2};
  `}
`;

export const HeaderContent = styled.div`
  ${flexCenterVertical}
  flex-wrap: wrap;
  flex: 1;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level2};
  `}
`;

export const HeaderLabel = styled(Text).attrs({
  variant: TEXT_VARIANT_LABEL_LARGE,
  color: 'onSurface',
})`
  margin-right: auto;
`;

export const HeaderText = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurface',
})``;

export const MediaContainer = styled.div`
  ${flexCenterVertical}
  flex: 1;
  justify-content: center;
  min-height: 156px;
  position: relative;

  ${({ height, padding, theme: { colors } }) => css`
    background: ${colors.surfaceDim};

    ${when(
      height,
      `
        height: ${height}px;
      `,
    )}

    ${when(
      padding,
      `
        padding: ${padding};
      `,
    )}
  `}
`;

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const PlaceHolderIcon = styled(SpriteSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;

  ${({ spinning, theme: { spacing, colors } }) => css`
    width: ${spacing.level8};
    height: ${spacing.level8};
    fill: ${colors.onSurfaceVariant};

    ${when(
      spinning,
      css`
        animation: ${spin} 1s linear infinite;
      `,
    )}
  `}
`;

export const Media = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;

  ${({ as }) => css`
    ${when(
      as === 'video',
      `
        width: auto;
        height: 100%;
      `,
    )}
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  overflow: auto;

  ${({ theme: { spacing } }) => css`
    padding-left: ${spacing.level5};
  `}
`;

export const Footer = styled.div`
  ${flexCenterVertical}
  justify-content: flex-end;
  gap: 8px;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level5};
  `}

  ${ButtonWrapper} {
    flex: unset;
  }
`;

export const Wrapper = styled.div`
  ${flexColumn}
  max-width: 100%;
  max-height: 100%;
  overflow: clip;
  transition: background 0.2s ease;

  ${({ theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.large};
    border: 1px solid ${rgba(colors.onSurface, 0.08)};
    background: ${colors.surfaceContainer};
  `}

  ${({ onClick, theme: { colors } }) => `
    ${when(
      onClick,
      `
      cursor: pointer;

        &:hover {
          background: ${rgba(colors.onSurface, 0.06)};

          ${MediaContainer} {
            background: ${rgba(colors.surfaceDim, 0.82)};
            transition: background 0.1s ease;
          }
        }
      `,
    )}
  `}
`;
