import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const licenseQueryKeys = {
  allLicenses: ['allLicenses'],
};

export default function useLicenseQueries() {
  const getAllLicenses = useQuery({
    queryKey: licenseQueryKeys.allLicenses,
    queryFn: () =>
      client.get(endpoints.getDesktopLicenses).then((res) => res.data),
  });

  return {
    getAllLicenses,
  };
}
