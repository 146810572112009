import {
  FETCH_ALL_PRINTERS,
  FETCH_ALL_PRINTERS_SUCCEEDED,
  FETCH_ALL_PRINTERS_FAILED,
  SELECT_PRINTER,
  SELECT_PRINTER_WIDGET,
  PRINTER_ROBOT_TYPE_MODIFIED,
  CREATE_PRINTER_SUCCEEDED,
  UPDATE_PRINTER,
  UPDATE_PRINTER_SUCCEEDED,
  UPDATE_PRINTER_FAILED,
  DELETE_PRINTER,
  DELETE_PRINTER_SUCCEEDED,
  DELETE_PRINTER_FAILED,
  PRINTER_SETTING_MODIFIED,
  PRINTER_HEARTBEAT,
  PRINT_STATUS,
  FETCH_ALL_PRINTS,
  FETCH_ALL_PRINTS_SUCCEEDED,
  FETCH_ALL_PRINTS_FAILED,
  SELECT_PRINT,
  SHOW_DELETE_PRINT_DIALOG,
  DISMISS_DELETE_PRINT_DIALOG,
  DELETE_PRINT,
  DELETE_PRINT_SUCCEEDED,
  DELETE_PRINT_FAILED,
  TOKEN_EXPIRE_LOG_OUT_SUCCESS,
} from '@constants/actionTypes';
import actionTypes from '@actions';

const initialState = {
  ui: {
    fetchesInProgress: 0,
    selectedPrinterId: null,
    selectedPrinterRobotType: null,
    unsavedModifications: {},
    machineModifications: {},
    prints: {
      selectedPrint: null,
      isDeletingPrint: false,
      printIdToDelete: null,
    },
    showPostProcessorConfig: false,
    nozzleConfig: {
      showNozzleConfig: false,
      nozzle: {},
    },
    generalPrinterSetting: {
      showGeneralPrinterSetting: false,
      setting: '',
      displayName: '',
    },
  },
  data: {
    printers: [],
    printerStatuses: [],
    printProgresses: [],
    prints: {},
    postProcessorConfigs: [],
  },
};

const printersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PRINTERS:
    case UPDATE_PRINTER:
    case DELETE_PRINTER:
    case DELETE_PRINT:
    case FETCH_ALL_PRINTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case FETCH_ALL_PRINTERS_SUCCEEDED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          printers: action.payload,
        },
      };
    }
    case DELETE_PRINTER_FAILED:
    case FETCH_ALL_PRINTERS_FAILED:
    case UPDATE_PRINTER_FAILED:
    case FETCH_ALL_PRINTS_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    case SELECT_PRINTER_WIDGET:
    case SELECT_PRINTER: {
      //eslint-disable-next-line
      let machineModifications =
        action.payload?.machine ||
        state.data.printers.find((printer) => printer.id === action.payload.id)
          ?.machine;
      if (!machineModifications) machineModifications = {};
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedPrinterId: action.payload.id,
          machineModifications: {
            ...state.ui.machineModifications,
            [action.payload.id]: machineModifications,
          },
        },
      };
    }
    case SELECT_PRINT:
      return {
        ...state,
        ui: {
          ...state.ui,
          prints: {
            ...state.ui.prints,
            selectedPrint: action.payload.id,
          },
        },
      };
    case SHOW_DELETE_PRINT_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          prints: {
            ...state.ui.prints,
            isDeletingPrint: true,
            printIdToDelete: action.printIdToDelete,
          },
        },
      };
    case DISMISS_DELETE_PRINT_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          prints: {
            ...state.ui.prints,
            isDeletingPrint: false,
            printIdToDelete: null,
          },
        },
      };
    case PRINTER_ROBOT_TYPE_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedPrinterRobotType: action.payload.robotType,
        },
      };
    case CREATE_PRINTER_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
        },
        data: {
          ...state.data,
          printers: [...state.data.printers, action.payload],
        },
      };
    case FETCH_ALL_PRINTS_SUCCEEDED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          prints: {
            [action.printerId]: action.payload.prints,
          },
        },
      };
    }
    case actionTypes.CHANGE_MACHINE_MODEL:
      return {
        ...state,
        ui: {
          ...state.ui,
          machineModifications: {
            [action.payload.printerId]: action.payload.machineDefaults,
          },
        },
      };
    case actionTypes.MACHINE_SETTING_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          machineModifications: {
            [action.payload.printerId]: {
              ...state.ui.machineModifications[action.payload.printerId],
              [action.payload.setting.settingName]: action.payload.value,
            },
          },
        },
      };
    case PRINTER_SETTING_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            [action.payload.printerId]: {
              ...state.ui.unsavedModifications[action.payload.printerId],
              settings: {
                ...state.ui.unsavedModifications[action.payload.printerId]
                  ?.settings,
                [action.payload.setting.settingName ||
                action.payload.setting.type]: action.payload.value,
              },
            },
          },
        },
      };
    case actionTypes.PRINTER_SETTINGS_DELETED: {
      const updatedSettings = {
        ...state.ui.unsavedModifications[action.payload.printerId]?.settings,
      };
      const settingKey =
        action.payload.setting.settingName || action.payload.setting.type;

      if (updatedSettings[settingKey] !== undefined) {
        delete updatedSettings[settingKey];
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.printerId]: {
              ...state.ui.unsavedModifications[action.payload.printerId],
              settings: updatedSettings,
            },
          },
        },
      };
    }

    case actionTypes.PRINTER_SETTINGS_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            [action.payload.printerId]: {
              ...state.ui.unsavedModifications[action.payload.printerId],
              settings: {
                ...state.ui.unsavedModifications[action.payload.printerId]
                  ?.settings,
                ...action.payload.settings.reduce((acc, setting) => {
                  acc[setting.settingName] = setting.value;
                  return acc;
                }, {}),
              },
            },
          },
        },
      };
    case UPDATE_PRINTER_SUCCEEDED: {
      // eslint-disable-next-line no-unused-vars
      const { [action.payload.id]: toExclude, ...unsavedModifications } =
        state.ui.unsavedModifications;
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          unsavedModifications,
        },
        data: {
          ...state.data,
          printers: [
            ...state.data.printers.filter(
              (printer) => printer.id !== action.payload.id,
            ),
            action.payload,
          ],
        },
      };
    }
    case actionTypes.FETCH_PRINTER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          printers: [
            ...state.data.printers.filter(
              (printer) => printer.id !== action.payload.id,
            ),
            action.payload,
          ],
        },
      };
    }
    case DELETE_PRINTER_SUCCEEDED: {
      const copyPrints = { ...state.data.prints };
      delete copyPrints[action.payload.printerId];
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          selectedPrinterId: action.payload.dismissPrinter
            ? null
            : state.ui.selectedPrinterId,
          selectedPrinterRobotType: action.payload.dismissPrinter
            ? null
            : state.ui.selectedPrinterRobotType,
        },
        data: {
          ...state.data,
          printers: state.data.printers.filter(
            (printer) => printer.id !== action.payload.printerId,
          ),
          prints: copyPrints,
        },
      };
    }
    case DELETE_PRINT_SUCCEEDED: {
      const copyPrints = { ...state.data.prints };
      copyPrints[action.payload.printerId] = copyPrints[
        action.payload.printerId
      ].filter((pr) => pr.printId !== action.payload.printId);
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          prints: {
            ...state.ui.prints,
            isDeletingPrint: false,
            printIdToDelete: null,
          },
        },
        data: {
          ...state.data,
          prints: copyPrints,
        },
      };
    }
    case DELETE_PRINT_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          prints: {
            ...state.ui.prints,
            isDeletingPrint: false,
            printIdToDelete: null,
          },
        },
      };
    case PRINTER_HEARTBEAT:
      return {
        ...state,
        data: {
          ...state.data,
          printerStatuses: [
            ...state.data.printerStatuses.filter(
              (status) => status.printerId !== action.payload.printerId,
            ),
            {
              printerId: action.payload.printerId,
              status: action.payload.status,
              time: action.payload.time,
              recordingStartedAt: action.payload.recordingStartedAt,
            },
          ],
        },
      };
    case PRINT_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          printProgresses: [
            ...state.data.printProgresses.filter(
              (progress) => progress.printerId !== action.payload.printerId,
            ),
            {
              printerId: action.payload.printerId,
              printId: action.payload.printId,
              instructionsComplete: action.payload.instructionsComplete,
              instructionsRemaining: action.payload.instructionsRemaining,
              time: action.payload.time,
            },
          ],
        },
      };
    case TOKEN_EXPIRE_LOG_OUT_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: initialState.ui.fetchesInProgress,
        },
      };
    }

    case actionTypes.SHOW_POST_PROCESSOR_CONFIG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showPostProcessorConfig: true,
        },
      };
    }

    case actionTypes.HIDE_POST_PROCESSOR_CONFIG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showPostProcessorConfig: false,
        },
      };
    }

    case actionTypes.SHOW_NOZZLE_CONFIG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          nozzleConfig: {
            showNozzleConfig: true,
            nozzle: action.payload.nozzle,
          },
        },
      };
    }

    case actionTypes.HIDE_NOZZLE_CONFIG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          nozzleConfig: {
            showNozzleConfig: false,
            nozzle: {},
          },
        },
      };
    }

    case actionTypes.FETCH_POST_PROCESSOR_CONFIG_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          postProcessorConfigs: action.payload.postProcessorConfigs,
        },
      };
    }

    case actionTypes.FETCH_POST_PROCESSOR_CONFIG_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          postProcessorConfigs: [],
        },
      };
    }

    case actionTypes.SHOW_GENERAL_PRINTER_SETTING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          generalPrinterSetting: {
            showGeneralPrinterSetting: true,
            setting: action.payload.setting,
            displayName: action.payload.displayName,
          },
        },
      };
    }

    case actionTypes.HIDE_GENERAL_PRINTER_SETTING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          generalPrinterSetting: {
            showGeneralPrinterSetting: false,
            setting: '',
            displayName: '',
          },
        },
      };
    }

    default:
      return state;
  }
};

export default printersReducer;
