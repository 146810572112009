import { useQueryClient, useMutation } from '@tanstack/react-query';
import { omit } from 'lodash';
import { printerQueryKeys } from '@hooks/printers/usePrinterQueries';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const printerMutationKeys = {
  createPrinter: ['createPrinter'],
  deletePrinter: ['deletePrinter'],
  stopPrint: ['showLoader', 'stopPrint'],
  editRecording: ['editRecording'],
  deleteRecording: ['deleteRecording'],
  editPrinterControl: ['editPrinterControl'],
  enableCamerasVideoStreams: ['enableCamerasVideoStreams'],
  updatePrinterCameras: ['updatePrinterCameras'],
};

export default function usePrinterMutations() {
  const queryClient = useQueryClient();

  const createPrinterMutation = useMutation({
    mutationKey: printerMutationKeys.createPrinter,
    mutationFn: (printer = {}) =>
      client
        .post(
          endpoints.printers,
          omit(printer, [
            'bedKinematics',
            'baseOffsetX',
            'baseOffsetY',
            'baseOffsetZ',
          ]),
        )
        .then((res) => res.data),
    onSuccess: (addedPrinter) => {
      const updater = (data) => {
        return data ? [...data, addedPrinter] : [addedPrinter];
      };

      queryClient.setQueryData(printerQueryKeys.printers, updater);
    },
  });

  const deletePrinterMutation = useMutation({
    mutationKey: printerMutationKeys.deletePrinter,
    mutationFn: (printerId) =>
      client
        .delete(endpoints.printer.replace(':printerId', printerId))
        .then((res) => res.data),
    onSuccess: (_, mutationPrinterId) => {
      const updater = (data) => {
        return data
          ? data?.filter((printer) => printer.id !== mutationPrinterId)
          : undefined;
      };

      queryClient.setQueryData(printerQueryKeys.printers, updater);
    },
  });

  const stopPrintMutation = useMutation({
    mutationKey: printerMutationKeys.createPrinter,
    mutationFn: ({ printerId, printId }) =>
      client
        .delete(
          endpoints.printerPrint
            .replace(':printerId', printerId)
            .replace(':printId', printId),
        )
        .then((res) => res.data),
  });

  const editRecordingMutation = useMutation({
    mutationKey: printerMutationKeys.editRecording,
    mutationFn: ({ recordingId, name }) =>
      client
        .post(endpoints.recording.replace(':recordingId', recordingId), {
          name,
        })
        .then((res) => res.data),
    onSuccess: (_, { recordingId, name }) => {
      const updater = (data) => {
        return data
          ? data?.map((recording) =>
              recording.id === recordingId ? { ...recording, name } : recording,
            )
          : undefined;
      };

      queryClient.setQueryData(printerQueryKeys.printersRecording, updater);
    },
  });

  const deleteRecordingMutation = useMutation({
    mutationKey: printerMutationKeys.deleteRecording,
    mutationFn: ({ recordingId }) =>
      client
        .delete(endpoints.recording.replace(':recordingId', recordingId))
        .then((res) => res.data),
    onSuccess: (_, { recordingId }) => {
      const updater = (data) => {
        return data
          ? data?.filter((recording) => recording.id !== recordingId)
          : undefined;
      };

      queryClient.setQueryData(printerQueryKeys.printersRecording, updater);
    },
  });

  const editPrinterControlMutation = useMutation({
    mutationKey: printerMutationKeys.editPrinterControl,
    mutationFn: ({ printerId, controlId, value }) =>
      client
        .post(
          endpoints.printerControl
            .replace(':printerId', printerId)
            .replace(':controlId', controlId),
          {
            value,
          },
        )
        .then((res) => res.data),
  });

  const enableCamerasVideoStreams = useMutation({
    mutationKey: printerMutationKeys.enableCamerasVideoStreams,
    mutationFn: ({ printerId }) =>
      client.post(
        endpoints.enableCamerasStream.replace(':printerId', printerId),
      ),
  });

  const updatePrinterCamerasMutation = useMutation({
    mutationKey: printerMutationKeys.updatePrinterCameras,
    mutationFn: ({ printerId = '', printerCameras = {} }) =>
      client.patch(
        endpoints.printerCameras.replace(':printerId', printerId),
        printerCameras,
      ),
  });

  return {
    createPrinterMutation,
    deletePrinterMutation,
    stopPrintMutation,
    editRecordingMutation,
    deleteRecordingMutation,
    editPrinterControlMutation,
    enableCamerasVideoStreams,
    updatePrinterCamerasMutation,
  };
}
