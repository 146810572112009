import { useCallback, useContext, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, isUndefined, pick } from 'lodash';
import usePrevious from '@hooks/usePrevious';
import useOperator from '@hooks/operators/useOperator';
import { getClippingTool } from '@selectors/toolBarSelector';
import {
  setClipToolRange,
  selectClipToolOption,
  setClippedRangeAppliedToObject,
  resetClippedRangeAppliedToObject,
} from '@actions/toolBarActions';
import VisualizationUtils from '@app/lib/VisualizationUtils';
import { VisualizationContext } from '@contexts/VisualizationContext';

export default function ClippingPlanes() {
  const dispatch = useDispatch();
  const { objects } = useContext(VisualizationContext);
  const clippingTool = useSelector(getClippingTool);
  const { getSelectedOperatorOutputGeometryId } = useOperator();
  const designGeometryId = getSelectedOperatorOutputGeometryId();

  const selectedObject = useMemo(
    () =>
      Object.values(objects).find(
        ({ designId }) => designId === designGeometryId,
      ),
    [objects, designGeometryId],
  );
  const previousSelectedObject = usePrevious(selectedObject);

  const previousClippingTool = usePrevious(clippingTool);

  const isLayersRangeSelected = clippingTool?.clipOption === 'layer';
  const isSequenceRangeSelected = clippingTool?.clipOption === 'sequence';

  const hasRenderedObjectChanged = useCallback(() => {
    const renderedObject = previousSelectedObject;
    const previousRenderedObject = selectedObject;
    const hasChanged = previousRenderedObject?.uuid !== renderedObject?.uuid;

    return hasChanged;
  }, [previousSelectedObject, selectedObject]);

  const hasRenderedObjectOriginChanged = useCallback(() => {
    const renderedObject = previousSelectedObject;
    const previousRenderedObject = selectedObject;
    const hasChanged =
      previousRenderedObject?.userData?.designId !==
      renderedObject?.userData?.designId;

    return hasChanged;
  }, [previousSelectedObject, selectedObject]);

  const checkIfObjectSupportClipping = useCallback(
    (object) => object?.userData?.clipEnabled,
    [],
  );

  const setClippingRange = useCallback(() => {
    const object = selectedObject;

    if (!object) return;

    let ranges = [0, 0];

    if (isLayersRangeSelected) {
      ranges = VisualizationUtils.getPolylineLayerRange(object);
    }

    if (isSequenceRangeSelected) {
      ranges = VisualizationUtils.getPolylineSequenceRange(object);
    }

    const [clipMinRange, clipMaxRange] = ranges;

    const nextRange = {
      clipStep: 1,
      clipMinRange,
      clipMaxRange,
    };

    dispatch(setClipToolRange(nextRange));
  }, [
    dispatch,
    isLayersRangeSelected,
    isSequenceRangeSelected,
    selectedObject,
  ]);

  const applyClippingRangeToObject = useCallback(
    (object) => {
      object.traverse((childObject) => {
        if (checkIfObjectSupportClipping(childObject)) {
          const layerNumber = childObject.userData?.[clippingTool?.clipOption];

          const shouldShowMaterial =
            (layerNumber >= clippingTool?.clipMinRangeValue &&
              layerNumber <= clippingTool?.clipMaxRangeValue) ||
            layerNumber === null;

          if (shouldShowMaterial) {
            childObject.visible = true;
          } else {
            childObject.visible = false;
          }
        }
      });
    },
    [clippingTool, checkIfObjectSupportClipping],
  );

  const observeClipToolChanges = useCallback(() => {
    const skip = !clippingTool?.clipIsActive;

    if (skip) return;

    const clipTurnedOn =
      !previousClippingTool?.clipIsActive && clippingTool?.clipIsActive;
    const clipOptionChanged =
      !isUndefined(previousClippingTool) &&
      !isEqual(previousClippingTool?.clipOption, clippingTool?.clipOption);
    const objectChanged = hasRenderedObjectOriginChanged();
    const shouldSetClippingRange =
      clipTurnedOn || clipOptionChanged || objectChanged;

    if (!shouldSetClippingRange) return;

    setClippingRange();
  }, [
    previousClippingTool,
    clippingTool,
    hasRenderedObjectOriginChanged,
    setClippingRange,
  ]);

  const observeClipToolRangeChanges = useCallback(() => {
    let skip = isUndefined(previousClippingTool) || !clippingTool?.clipIsActive;

    if (skip) return;

    const rangeValuesKeys = [
      'clipOption',
      'clipMinRangeValue',
      'clipMaxRangeValue',
    ];
    const previousRangeValues = pick(previousClippingTool, rangeValuesKeys);
    const currentRangeValues = pick(clippingTool, rangeValuesKeys);
    const objectTransformationApplied =
      !hasRenderedObjectOriginChanged() && hasRenderedObjectChanged();
    skip =
      !objectTransformationApplied &&
      isEqual(previousRangeValues, currentRangeValues);

    if (skip) return;

    selectedObject.traverse((o) => applyClippingRangeToObject(o));
    dispatch(
      setClippedRangeAppliedToObject({
        clipMinRange: currentRangeValues.clipMinRangeValue,
        clipMaxRange: currentRangeValues.clipMaxRangeValue,
      }),
    );
  }, [
    previousClippingTool,
    clippingTool,
    selectedObject,
    hasRenderedObjectOriginChanged,
    hasRenderedObjectChanged,
    applyClippingRangeToObject,
    dispatch,
  ]);

  const observeDisableClippingRange = useCallback(() => {
    const clipDisabled =
      previousClippingTool?.clipIsActive && !clippingTool?.clipIsActive;
    const object = selectedObject;
    const skip = !object || !clipDisabled;

    if (skip) return;

    object.traverse((childObject) => {
      if (checkIfObjectSupportClipping(childObject)) {
        childObject.visible = true;
      }
    });
    dispatch(resetClippedRangeAppliedToObject());
  }, [
    previousClippingTool,
    clippingTool?.clipIsActive,
    checkIfObjectSupportClipping,
    selectedObject,
    dispatch,
  ]);

  useEffect(() => {
    if (hasRenderedObjectOriginChanged()) {
      dispatch(selectClipToolOption('layer'));
    }
  }, [dispatch, hasRenderedObjectOriginChanged]);

  useEffect(() => {
    observeClipToolChanges();
  }, [observeClipToolChanges]);

  useEffect(() => {
    observeClipToolRangeChanges();
  }, [observeClipToolRangeChanges]);

  useEffect(() => {
    observeDisableClippingRange();
  }, [observeDisableClippingRange]);

  return null;
}
