import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PageList from '@components/3-organisms/PageList';

const MonitorPageContent = ({
  handleTabClick,
  headerTitle,
  loading,
  tabs,
  listItems = [],
}) => {
  const intl = useIntl();

  return (
    <PageList
      dataTestId="monitor-page-content"
      loading={loading}
      headerTitle={headerTitle}
      tabs={tabs}
      onTabClick={handleTabClick}
      listItems={listItems}
      createItemMessageIconName="precision_manufacturing_0"
      createItemMessageTitle={intl.formatMessage({
        id: 'monitorpage.list_items.empty_list_title',
        defaultMessage: 'No Online Printers',
      })}
      createItemMessagDescription={intl.formatMessage({
        id: 'monitorpage.list_items.empty_list_description',
        defaultMessage: 'Online printers will be displayed here',
      })}
    />
  );
};

MonitorPageContent.propTypes = {
  handleTabClick: PropTypes.func,
  headerTitle: PropTypes.string,
  loading: PropTypes.bool,
  tabs: PropTypes.array,
  listItems: PropTypes.array,
};

export default MonitorPageContent;
