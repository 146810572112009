import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toolQueryKeys } from '@hooks/tools/useToolQueries';

export default function useTool() {
  const queryClient = useQueryClient();

  const getSelectedTool = useCallback(
    (toolId) => {
      const tools = queryClient.getQueryData(toolQueryKeys.tools);

      if (!tools) {
        return null;
      }

      return tools.find((tool) => tool.id === toolId);
    },
    [queryClient],
  );

  return {
    getSelectedTool,
  };
}
