import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import PrinterRecordingControl from '@components/PrinterMonitor/PrinterRecordingControl';
import { PrinterControlTypes } from '@constants/printerStatuses';
import { Wrapper, Controls } from './PrinterRecordingControls.styled';

const PrinterRecordingControls = ({ controls = [], editable }) => {
  const intl = useIntl();

  if (isEmpty(controls)) {
    return null;
  }

  return (
    <Wrapper>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={intl.formatMessage({
          id: 'printermonitor.recording_controls.title',
          defaultMessage: 'Control',
        })}
      />

      <Controls>
        {controls.map((control) => (
          <PrinterRecordingControl
            key={control.id}
            editable={editable}
            control={control}
          />
        ))}
      </Controls>
    </Wrapper>
  );
};

PrinterRecordingControls.propTypes = {
  editable: PropTypes.bool,
  controls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      printerId: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.oneOf(Object.values(PrinterControlTypes)),
      iconKey: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ]),
      timestamp: PropTypes.number,
    }),
  ).isRequired,
};

export default PrinterRecordingControls;
