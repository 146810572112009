import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  ${flexColumn};
  overflow: auto;

  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing?.level6};
  `}
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { colors, borderRadius, spacing } }) => css`
    border-radius: ${borderRadius?.large};
    background-color: ${colors?.surfaceContainer};
    padding: ${spacing?.level4};
  `}
  ${({ theme: { dimensions } }) => css`
    width: min(${dimensions.pageContentWidth}, 100%);
  `}
`;

export const ScrollableList = styled(Frame)`
  overflow: auto;
`;
