import React, { useCallback, useEffect, useState } from 'react';
import EditorLayout, {
  LayoutSettingBarLayer,
} from '@components/1-atoms/Layouts/EditorLayout';
import Editor from '@components/Tools/Editor';
import Toolbar from '@components/2-molecules/Toolbar';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { DIALOG_VARIANT_SIDE_VIEW } from '@components/2-molecules/Dialog';
import { useParams } from 'react-router-dom';
import useTool from '@hooks/tools/useTool';
import ToolDynamicNavigationBar from '@containers/ToolDynamicaNavigationBar';

const Tool = () => {
  const { showDialog } = useDialog();
  const { toolId } = useParams();
  const { getSelectedTool } = useTool();
  const selectedTool = getSelectedTool(toolId);

  const [settingBarCollapsed, setSettingBarCollapsed] = useState(true);

  const handleToolbarLogoClick = useCallback(() => {
    showDialog(ModalDataTypes.NAVIGATION);
  }, [showDialog]);

  const handleSidebarCollapse = useCallback(() => {
    setSettingBarCollapsed(!settingBarCollapsed);
  }, [settingBarCollapsed, setSettingBarCollapsed]);

  useEffect(() => {
    setSettingBarCollapsed(false);
  }, []);

  return (
    <>
      <EditorLayout
        backgroundLayer={<></>}
        settingBarCollapsed={settingBarCollapsed}
        topBar={<></>}
      >
        <Toolbar
          logoButton={{
            onClick: handleToolbarLogoClick,
          }}
        />

        {selectedTool ? (
          <>
            <LayoutSettingBarLayer visible={settingBarCollapsed}>
              <Toolbar
                headerIconButtons={[
                  {
                    id: 0,
                    iconName: 'thumbnail_bar_0',
                    onClick: handleSidebarCollapse,
                  },
                ]}
              />
            </LayoutSettingBarLayer>

            <LayoutSettingBarLayer visible={!settingBarCollapsed} fadeOut>
              {selectedTool && (
                <Editor
                  key={selectedTool.id}
                  handleSidebarCollapse={handleSidebarCollapse}
                  selectedTool={selectedTool}
                />
              )}
            </LayoutSettingBarLayer>
          </>
        ) : (
          <></>
        )}
      </EditorLayout>

      <DialogPortal
        dialogId={ModalDataTypes.NAVIGATION}
        variant={DIALOG_VARIANT_SIDE_VIEW}
        onCloseIconButtonClick={undefined}
        primaryButtonLabel={undefined}
        disableContentPadding
        closeOnClickOutside
        closeOnEscape
      >
        <ToolDynamicNavigationBar />
      </DialogPortal>
    </>
  );
};

Tool.propTypes = {};

export default Tool;
