import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import useToolMutations from '@hooks/tools/useToolMutations';
import { toolsConstants, toolTypeLabel } from '@constants/tools';

export default function useToolList() {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const { deleteToolMutation } = useToolMutations();

  const getToolDropDownMenuActions = useCallback(
    (tool = {}) => {
      const actions = [
        {
          leadingIconName: 'content_copy_0',
          label: intl.formatMessage({
            id: 'general.duplicate',
            defaultMessage: 'Duplicate',
          }),
          onClick: () => showDialog(ModalDataTypes.CLONE_TOOL, { tool }),
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-tool-dialog',
              title: intl.formatMessage({
                id: 'tools.delete_tool_dialog.header.title',
                defaultMessage: 'Delete Tool Component',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'tools.delete_tool_dialog.subtitle',
                  defaultMessage:
                    'The Tool named <b>{toolName}</b> will be permanently deleted and all its configurations will be lost. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  toolName: tool.name,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: () => {
                deleteToolMutation.mutateAsync({
                  id: tool.id,
                });
              },
            });
          },
        },
      ];

      return actions;
    },
    [deleteToolMutation, showDialog, intl],
  );

  const getToolTypeDropDownMenuItems = (values, type) => {
    let categoryList = [];

    if (type === 'type') {
      categoryList = [
        toolsConstants.BALL_END_MILL,
        toolsConstants.FLAT_END_MILL,
        toolsConstants.ENGRAVING_BIT,
        toolsConstants.DRILL_BIT,
      ];
    } else if (type === 'spindleRotation') {
      categoryList = [toolsConstants.CLOCKWISE, toolsConstants.ANTICLOCKWISE];
    } else if (type === 'material') {
      categoryList = [
        toolsConstants.HIGH_SPEED_STEEL,
        toolsConstants.CARBIDE,
        toolsConstants.CERAMIC,
        toolsConstants.NOT_SPECIFIED,
      ];
    }

    return categoryList
      .map((category) => ({
        label: toolTypeLabel(category),
        selected: values?.type?.value === category,
        formFieldValue: {
          label: toolTypeLabel(category),
          value: category,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return {
    getToolDropDownMenuActions,
    getToolTypeDropDownMenuItems,
  };
}
