import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import usePrevious from '@hooks/usePrevious';
import useSteps from '@hooks/useSteps';
import NavigationBar from '@components/NavigationBar';
import { getNavigationBarHistory } from '@selectors/applicationSelectors';
import { setNavigationBarHistory } from '@actions/applicationActions';
import {
  Wrapper,
  Header,
  RouterLink,
  Logo,
  Content,
} from './ToolDynamicNavigationBar.styled';
import ToolsPageContent from '@components/ToolsPageContent';
import useToolQueries from '@hooks/tools/useToolQueries';

const NAVIGATION_BAR_STEP = 'navigationBar';
const TOOLS_STEP = 'tools';

const NAVIGATION_STEPS = [NAVIGATION_BAR_STEP, TOOLS_STEP];

const dataTestId = 'tool-dynamic-navigation-bar';

const ToolDynamicNavigationBar = () => {
  const dispatch = useDispatch();
  const navigationHistory = useSelector(getNavigationBarHistory);
  const wrapperRef = useRef(null);

  const { toolsQuery } = useToolQueries();

  const isLoading = !toolsQuery.isFetched || toolsQuery.isLoading;

  const refetchToolsQuery = toolsQuery.refetch;

  const initialStepNumber = useMemo(() => {
    if (navigationHistory?.isToolsStep) {
      return NAVIGATION_STEPS.indexOf(TOOLS_STEP);
    }

    return NAVIGATION_STEPS.indexOf(NAVIGATION_BAR_STEP);
  }, [navigationHistory]);

  const { currentStepName, isStepActive, getStepIndex, goNext, goPrev } =
    useSteps({
      steps: NAVIGATION_STEPS,
      initialStepNumber: initialStepNumber,
    });
  const previousCurrentStepName = usePrevious(currentStepName);
  const currentStepIndex = getStepIndex(currentStepName);
  const navigationBarStepIndex = getStepIndex(NAVIGATION_BAR_STEP);
  const isNavigationBarStep = isStepActive(NAVIGATION_BAR_STEP);
  const isToolsStep = isStepActive(TOOLS_STEP);

  const updateNavigationHistory = useCallback(
    (nextHistory) =>
      dispatch(
        setNavigationBarHistory({
          ...navigationHistory,
          ...nextHistory,
        }),
      ),
    [dispatch, navigationHistory],
  );

  useGSAP(
    () => {
      const isMovingBackwards =
        getStepIndex(currentStepName) < getStepIndex(previousCurrentStepName);

      const startFrom = isMovingBackwards ? '-100%' : '100%';

      gsap.from('.animated-step', 0.35, { x: startFrom });
    },
    {
      scope: wrapperRef,
      dependencies: [currentStepName, getStepIndex, currentStepName],
    },
  );

  useEffect(() => {
    if (currentStepIndex > navigationBarStepIndex) {
      if (!navigationHistory?.isToolsStep) {
        updateNavigationHistory({ isToolsStep: true });
      }

      return;
    }

    if (navigationHistory?.isToolsStep) {
      updateNavigationHistory({ isToolsStep: false });
    }
  }, [
    currentStepIndex,
    navigationBarStepIndex,
    navigationHistory,
    updateNavigationHistory,
  ]);

  useEffect(() => {
    if (!toolsQuery.isFetched) {
      refetchToolsQuery();
    }
  }, [refetchToolsQuery, toolsQuery.isFetched]);

  return (
    <Wrapper ref={wrapperRef} data-testid={dataTestId}>
      <Header data-testid={`${dataTestId}__header`}>
        <RouterLink data-testid={`${dataTestId}__logo-link`} to="/">
          <Logo />
        </RouterLink>
      </Header>

      {isNavigationBarStep && (
        <div className="animated-step">
          <NavigationBar onToolsItemClick={goNext} withLogo={false} />
        </div>
      )}

      {!isNavigationBarStep && (
        <Content className="animated-step">
          {isToolsStep && (
            <ToolsPageContent
              headerLeadingIconButtonIconName="arrow_back_0"
              onHeaderLeadingIconButtonClick={goPrev}
              loading={isLoading}
              toolsList={toolsQuery?.data}
            />
          )}
        </Content>
      )}
    </Wrapper>
  );
};

ToolDynamicNavigationBar.propTypes = {};

export default ToolDynamicNavigationBar;
