export const PostProcessorSettingsName = {
  startAction: 'startAction',
  startPositionAction: 'startPositionAction',
  endAction: 'endAction',
  lineTypeAction: 'lineTypeAction',
  layerAction: 'layerAction',
  extrusionAction: 'extrusionAction',
  controlAction: 'controlAction',
  calculationAction: 'calculationAction',
  gCodeAction: 'gCodeAction',
  depositionMultiplier: 'depositionMultiplier',
  maximumDepositionValue: 'maximumDepositionValue',
  extrusionRateChangeInstruction: 'extrusionRateChangeInstruction',
  toggleOnInstruction: 'toggleOnInstruction',
  toggleOffInstruction: 'toggleOffInstruction',
  startInstruction: 'startInstruction',
  endInstruction: 'endInstruction',
  lineTypeInstruction: 'lineTypeInstruction',
  layerInstruction: 'layerInstruction',
  isToolChangingEnabled: 'isToolChangingEnabled',
  isCustomWaitHandlerEnabled: 'isCustomWaitHandlerEnabled',
  customWaitInstructions: 'customWaitInstructions',
  toolChangeInstruction: 'toolChangeInstruction',
  isRetractionEnabled: 'isRetractionEnabled',
  useRetractionCustom: 'useRetractionCustom',
  customRetractionInstructions: 'customRetractionInstructions',
  customProtractionInstructions: 'customProtractionInstructions',
  isProcessParametersEnabled: 'isProcessParametersEnabled',
  processParametersIDsInstructions: 'processParametersIDsInstructions',
  processParametersIDsAction: 'processParametersIDsAction',
  defaultProcessParametersID: 'defaultProcessParametersID',
  kukaInitialTool: 'kukaInitialTool',
  kukaInitialBase: 'kukaInitialBase',
  abbIsAnalogSignalEnabled: 'abbIsAnalogSignalEnabled',
  abbAnalogSignalName: 'abbAnalogSignalName',
  fanucUseKLFileGeneration: 'fanucUseKLFileGeneration',
  isToolExternalAxis: 'isToolExternalAxis',
  toolExternalAxisId: 'toolExternalAxisId',
  ceadEnablePurge: 'ceadEnablePurge',
  ceadEnableTemperatures: 'ceadEnableTemperatures',
  useJointPositions: 'useJointPositions',
  useRollerTool: 'useRollerTool',
  gCodeLinearMoveToken: 'gCodeLinearMoveToken',
  gCodeTravelMoveToken: 'gCodeTravelMoveToken',
  gCodeExtrusionToken: 'gCodeExtrusionToken',
  gCodeRollerToken: 'gCodeRollerToken',
  gCodeLinearAxisToken: 'gCodeLinearAxisToken',
  gCodeRotaryAxis1Token: 'gCodeRotaryAxis1Token',
  gCodeRotaryAxis2Token: 'gCodeRotaryAxis2Token',
  startPosition: 'startPosition',
  setInitialTool: 'setInitialTool',
  setInitialBase: 'setInitialBase',
};
