import * as Yup from 'yup';
import getIntlProvider from '@app/utils/getIntlProvider';

export const createStringValidator = (requiredMessageId, options = {}) => {
  const intl = getIntlProvider();
  let validator = Yup.string().required(
    intl.formatMessage({
      id: requiredMessageId,
      defaultMessage: requiredMessageId,
    }),
  );

  if (options.min) {
    validator = validator.min(
      options.min.value,
      intl.formatMessage({
        id: options.min.messageId,
        defaultMessage: options.min.messageId,
      }),
    );
  }

  if (options.max) {
    validator = validator.max(
      options.max.value,
      intl.formatMessage({
        id: options.max.messageId,
        defaultMessage: options.max.messageId,
      }),
    );
  }

  return validator;
};

export const createNumberValidator = (min, max, integer, required) => {
  const intl = getIntlProvider();
  let baseValidator = Yup.number();

  if (integer) {
    baseValidator = baseValidator.integer(
      intl.formatMessage({
        id: 'general.must.be.an.integer',
        defaultMessage: 'Must be an integer',
      }),
    );
  }

  baseValidator = baseValidator
    .min(
      min,
      intl.formatMessage(
        {
          id: 'general.greater.than.value',
          defaultMessage: `Greater than ${min}`,
        },
        { value: min },
      ),
    )
    .max(
      max,
      intl.formatMessage(
        {
          id: 'general.smaller.than.value',
          defaultMessage: `Smaller than ${max}`,
        },
        { value: max },
      ),
    );

  if (required) {
    return baseValidator.required(
      intl.formatMessage({
        id: 'general.cannot.be.empty',
        defaultMessage: 'Cannot be empty',
      }),
    );
  }

  return baseValidator;
};

export const createNameRoleAndUsernameValidationSchema = (intl) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(
        intl.formatMessage({
          id: 'createuser.name.validation.required',
          defaultMessage: 'Name cannot be empty',
        }),
      )
      .min(
        5,
        intl.formatMessage({
          id: 'createuser.name.validation.min',
          defaultMessage: 'Name must be at least 5 characters long',
        }),
      )
      .max(
        70,
        intl.formatMessage({
          id: 'createuser.name.validation.max',
          defaultMessage: 'Name must be no longer than 70 characters',
        }),
      ),
    username: Yup.string()
      .required(
        intl.formatMessage({
          id: 'createuser.username.validation.required',
          defaultMessage: 'Email address cannot be empty',
        }),
      )
      .min(
        5,
        intl.formatMessage({
          id: 'createuser.username.validation.min',
          defaultMessage: 'Email address must be at least 5 characters long',
        }),
      )
      .max(
        70,
        intl.formatMessage({
          id: 'createuser.username.validation.max',
          defaultMessage: 'Email address must be no longer than 70 characters',
        }),
      )
      .email(
        intl.formatMessage({
          id: 'createuser.username.validation.email',
          defaultMessage: 'Must be an email format',
        }),
      ),
    role: Yup.object()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'createuser.role.validation.required',
          defaultMessage: 'Please select an option',
        }),
      ),
  });
};

export const passwordValidationRules = (intl, newPassword) => {
  return [
    {
      id: 'minLength',
      description: intl.formatMessage({
        id: 'loginpage.resetpassword_newpassword.newpassword.rules.minlength',
        defaultMessage: 'Must contain between 8 and 25 characters',
      }),
      matchCondition: newPassword?.length >= 8 && newPassword?.length <= 25,
    },
    {
      id: 'lowercase',
      description: intl.formatMessage({
        id: 'loginpage.resetpassword_newpassword.newpassword.rules.lowercase',
        defaultMessage: 'At least one lowercase letter',
      }),
      matchCondition: /[a-z]/.test(newPassword),
    },
    {
      id: 'uppercase',
      description: intl.formatMessage({
        id: 'loginpage.resetpassword_newpassword.newpassword.rules.uppercase',
        defaultMessage: 'At least one uppercase letter',
      }),
      matchCondition: /[A-Z]/.test(newPassword),
    },
    {
      id: 'number',
      description: intl.formatMessage({
        id: 'loginpage.resetpassword_newpassword.newpassword.rules.number',
        defaultMessage: 'At least one digit',
      }),
      matchCondition: /\d/.test(newPassword),
    },
    {
      id: 'specialCharacter',
      description: intl.formatMessage({
        id: 'loginpage.resetpassword_newpassword.newpassword.rules.specialcharacter',
        defaultMessage: 'At least one special character',
      }),
      matchCondition: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPassword),
    },
    {
      id: 'notWhitespace',
      description: intl.formatMessage({
        id: 'loginpage.resetpassword_newpassword.newpassword.rules.notwhitespace',
        defaultMessage: 'Must not contain any whitespaces',
      }),
      matchCondition: !!newPassword?.length && !/\s/.test(newPassword),
    },
  ];
};
