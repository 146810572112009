import { isEmpty } from 'lodash';
import { UserRoles } from '@constants/userRoles';

export const getIsLoggedIn =
  () =>
  ({ login }) => {
    const userIsSet = !isEmpty(login.user);
    const mfaPassed = login.user?.mfaEnforced ? login.user?.mfaEnabled : true;

    return userIsSet && mfaPassed;
  };
export const getLoginTokenExpired =
  () =>
  ({ login }) =>
    login.loginTokenExpired;

export const getCurrentUser =
  () =>
  ({ login }) =>
    login.user;

export const getHasSuperAdminRights =
  () =>
  ({ login }) =>
    login?.user?.role === UserRoles.SUPER_ADMIN;

export const getHasAdminRights =
  () =>
  ({ login }) =>
    login?.user?.role === UserRoles.SUPER_ADMIN ||
    login?.user?.role === UserRoles.ADMIN;

export const getUserPreferences = ({ login }) => login?.userPreferences || {};
export const getUserSessionPreferences = ({ login }) =>
  login?.userSessionPreferences || {};

export const getUserPreference =
  (preferanceName = '') =>
  ({ login }) =>
    login?.userPreferences?.[preferanceName] || false;

export const getUserSessionPreference =
  (preferanceName = '') =>
  ({ login }) =>
    login?.userSessionPreferences?.[preferanceName] || false;

export const getIsTokenAuthentificated = ({ login }) =>
  login?.tokenAuthentificated;

export const getLoginFailed = ({ login }) => login?.loginFailed;

export const getUserAccountPreferences = ({ login }) =>
  login?.user?.userPreferences || {};

export const getUserAccountPreference =
  (preferanceName) =>
  ({ login }) =>
    login?.user?.userPreferences?.[preferanceName];
