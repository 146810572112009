export default {
  baseUrl: 'http://localhost:8080/',
  baseUrlws: 'ws://localhost:8080/',
  minimumBackwardsCompatibleVersion: '3.0.18',
  statsigClientKey: 'client-frTDu288IZWqaGpYOqpzwUm8BXPFxY3IfjUZeal48nO',

  /* Feature Flags */
  features: {
    talkToAi: {
      enabled: true,
      users: '*',
      organizations: [
        'AiBuild',
        'Ai Build - Light',
        'Ai Build - Standard',
        'Ai Build Demo',
        'Ai Build QA',
      ],
      roles: '*',
    },
    workflowAnnotations: {
      enabled: true,
      users: '*',
      organizations: ['AiBuild'],
      roles: '*',
    },
    knowledgeCentre: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    quickStart: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    analyticsDefectSensitivity: {
      enabled: true,
      users: '*',
      organizations: ['AiBuild'],
      roles: '*',
    },
    clippingTool: {
      enabled: true,
      users: '*',
      organizations: ['AiBuild'],
      roles: '*',
    },
    computationWaitingTime: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    fullScreenOperator: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    operatorCategories: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
  },
  /* End of Feature Flags */
};
