import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Log } from './PrinterRecordingLogs.styled';

const PrinterRecordingLogs = ({ data = [] }) => {
  const formatWaitTime = useCallback((waitTime) => {
    if (!waitTime) return 'N/A';

    return moment.utc(waitTime).format('mm:ss');
  }, []);

  const sortedByLayerDesc = useMemo(() => {
    return data.sort((a, b) => b.layer - a.layer);
  }, [data]);

  return (
    <Wrapper>
      {sortedByLayerDesc.map((log, index) => {
        const { waitTime, layer } = log;

        return (
          <Log key={index}>
            <FormattedMessage id="general.layer" defaultMessage="Layer" />{' '}
            {layer} -{' '}
            <FormattedMessage
              id="printermonitor.recording_logs.wait_time"
              defaultMessage="Wait time"
            />{' '}
            {formatWaitTime(waitTime)}
          </Log>
        );
      })}
    </Wrapper>
  );
};

PrinterRecordingLogs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      printerId: PropTypes.string,
      cameraId: PropTypes.string,
      waitTime: PropTypes.number,
      layer: PropTypes.number,
    }),
  ),
};

export default PrinterRecordingLogs;
