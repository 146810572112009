import React from 'react';
import PropTypes from 'prop-types';
import { dropDownMenuHOCProps } from '@hoc/withDropDownMenu';
import {
  preventDefault,
  stopPropagation,
  whenEnterButtonPressed,
} from '@utils/interactionEvents';
import Skeleton, {
  SKELETON_VARIANT_ONE_LINE,
} from '@components/1-atoms/Skeleton';
import {
  SkeletonWrapper,
  Wrapper,
  ThumbnailContainer,
  ThumbnailPlaceholderIcon,
  Icon,
  Content,
  Label,
  DescriptionContainer,
  DescriptionDot,
  Description,
  EndingIconButton,
} from './ListItem.styled';

export const LIST_ITEM_VARIANT_DEFAULT = 'default';
export const LIST_ITEM_VARIANT_THUMBNAIL = 'thumbnail';

export const LIST_ITEM_DESCRIPTION_DOT_VARIANT_SUCCESS = 'success';
export const LIST_ITEM_DESCRIPTION_DOT_VARIANT_ERROR = 'error';
export const LIST_ITEM_DESCRIPTION_DOT_VARIANT_CAUTION = 'caution';

const ListItem = ({
  autoHeight,
  className,
  dataTestId = 'list-item',
  description,
  descriptionDotVariant,
  disabled,
  dragged,
  endingIconButtonDropDownProps = {},
  endingIconButtonIconName,
  endingIconButtonRef,
  label,
  leadingIconName,
  onClick,
  onEndingIconButtonClick,
  skeleton,
  thumbnailSkeleton,
  thumbnailUrl,
  variant = LIST_ITEM_VARIANT_DEFAULT,
}) => {
  const thumbnailVariant = variant === LIST_ITEM_VARIANT_THUMBNAIL;
  const withLeadingIcon = !thumbnailVariant && !!leadingIconName;
  const withEndingIconButton = !!endingIconButtonIconName;

  const showLeadingIcon = withLeadingIcon && !thumbnailVariant;
  const showThumbnailSekeleton = thumbnailVariant && thumbnailSkeleton;
  const showThumbnailPlaceholder =
    thumbnailVariant && !thumbnailUrl && !thumbnailSkeleton;
  const showThumbnail = thumbnailVariant && thumbnailUrl && !thumbnailSkeleton;

  if (skeleton) {
    return (
      <SkeletonWrapper>
        <Skeleton variant={SKELETON_VARIANT_ONE_LINE} height={24} />
      </SkeletonWrapper>
    );
  }

  return (
    <Wrapper
      autoHeight={autoHeight}
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      dragged={dragged}
      onClick={disabled ? undefined : onClick}
      onKeyDown={whenEnterButtonPressed(onClick)}
      tabIndex={disabled || !onClick ? -1 : 0}
      withAction={!!onClick}
      withDescription={!!description}
      withEndingIconButton={withEndingIconButton}
      withLeadingIcon={withLeadingIcon}
      withThumbnail={!!thumbnailVariant}
    >
      {showLeadingIcon && <Icon disabled={disabled} name={leadingIconName} />}

      {showThumbnailSekeleton && (
        <ThumbnailContainer
          data-testid={`${dataTestId}__thumbnail-skeleton`}
          flex={false}
        >
          <Skeleton
            variant={SKELETON_VARIANT_ONE_LINE}
            withLine={false}
            borderRadius="small"
          />
        </ThumbnailContainer>
      )}

      {showThumbnailPlaceholder && (
        <ThumbnailContainer
          data-testid={`${dataTestId}__thumbnail-placeholder`}
        >
          <ThumbnailPlaceholderIcon name="draft_0" />
        </ThumbnailContainer>
      )}

      {showThumbnail && (
        <ThumbnailContainer
          data-testid={`${dataTestId}__thumbnail`}
          thumbnailUrl={thumbnailUrl}
        />
      )}

      <Content>
        <Label dataTestId={`${dataTestId}__label`} disabled={disabled}>
          {label}
        </Label>

        {description && (
          <DescriptionContainer>
            {descriptionDotVariant && (
              <DescriptionDot dotVariant={descriptionDotVariant} />
            )}

            <Description
              dataTestId={`${dataTestId}__description`}
              disabled={disabled}
              tag="div"
            >
              {description}
            </Description>
          </DescriptionContainer>
        )}
      </Content>

      {endingIconButtonIconName && (
        <EndingIconButton
          ref={endingIconButtonRef}
          dataTestId={`${dataTestId}__ending-icon-button`}
          iconName={endingIconButtonIconName}
          onClick={
            disabled
              ? undefined
              : preventDefault(stopPropagation(onEndingIconButtonClick))
          }
          {...(endingIconButtonDropDownProps || {})}
        />
      )}
    </Wrapper>
  );
};

ListItem.propTypes = {
  autoHeight: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dragged: PropTypes.bool,
  endingIconButtonDropDownProps: PropTypes.shape(dropDownMenuHOCProps),
  endingIconButtonIconName: PropTypes.string,
  endingIconButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  descriptionDotVariant: PropTypes.oneOf([
    LIST_ITEM_DESCRIPTION_DOT_VARIANT_SUCCESS,
    LIST_ITEM_DESCRIPTION_DOT_VARIANT_ERROR,
    LIST_ITEM_DESCRIPTION_DOT_VARIANT_CAUTION,
  ]),
  leadingIconName: PropTypes.string,
  onClick: PropTypes.func,
  onEndingIconButtonClick: PropTypes.func,
  skeleton: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
  thumbnailSkeleton: PropTypes.bool,
  variant: PropTypes.oneOf([
    LIST_ITEM_VARIANT_DEFAULT,
    LIST_ITEM_VARIANT_THUMBNAIL,
  ]),
};

export default ListItem;
