import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const TypeFields = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing.level6};
  `}
`;

const list = css`
  ${flexColumn};
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  display: grid;
  grid-gap: 12px;
`;

export const ContentWrapper = styled.div`
  ${list}
`;
