import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { useTheme } from 'styled-components';
import { Wrapper, Tick } from './PrinterRecordingChart.styled';

const CustomizedTick = ({ x, y, tickColor, payload, tickFormatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <Tick x={0} y={0} textAnchor="end" fill={tickColor}>
        {tickFormatter ? tickFormatter?.(payload.value) : payload.value}
      </Tick>
    </g>
  );
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  tickColor: PropTypes.string,
  payload: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  tickFormatter: PropTypes.func,
};

const PrinterRecordingChart = ({ data }) => {
  const theme = useTheme();
  const lineColor = theme.colors.primary;
  const tickColor = theme.colors.outline;

  // const calculateBarchartWidth = useCallback((dataLength) => {
  //   if (dataLength > 9) return dataLength * 100;

  //   return '100%';
  // }, []);

  return (
    <Wrapper>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} height={300} data={data}>
          <XAxis
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
            height={8}
            tick={<CustomizedTick tickColor={tickColor} />}
          />
          <YAxis
            dataKey="waitTime"
            width={34}
            allowDecimals={false}
            tickLine={false}
            axisLine={false}
            tickMargin={14}
            tick={<CustomizedTick tickColor={tickColor} />}
            tickFormatter={(value) => `${moment.utc(value).format('s')}s`}
          />
          <Line
            type="monotone"
            dataKey="waitTime"
            stroke={lineColor}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

PrinterRecordingChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      printerId: PropTypes.string,
      cameraId: PropTypes.string,
      waitTime: PropTypes.number,
      layer: PropTypes.number,
    }),
  ),
};

export default PrinterRecordingChart;
