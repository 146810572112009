import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PageList from '@components/3-organisms/PageList';

const RecordingsPageContent = ({
  handleTabClick,
  headerTitle,
  loading,
  tabs,
  listItems = [],
}) => {
  const intl = useIntl();
  const defaultFilterByPrinterName = intl.formatMessage({
    id: 'recordingspage.list_items.filter_by_printer_name.all',
    defaultMessage: 'All machines',
  });
  const [filterByPrinterName, setFilterByPrinterName] = useState(
    defaultFilterByPrinterName,
  );

  const printerFileterDropDownMenuItems = useMemo(() => {
    const resetItem = {
      id: 'recordings-printers-filter-all',
      label: defaultFilterByPrinterName,
      selected: filterByPrinterName === defaultFilterByPrinterName,
      onClick: () => setFilterByPrinterName(defaultFilterByPrinterName),
    };
    const printerItems = listItems?.map((item) => ({
      id: item?.printerId,
      label: item?.printerName,
      selected: filterByPrinterName === item?.printerName,
      onClick: () => setFilterByPrinterName(item?.printerName),
    }));

    return [resetItem, ...printerItems];
  }, [defaultFilterByPrinterName, listItems, filterByPrinterName]);

  const filteredListItems = useMemo(() => {
    return filterByPrinterName === defaultFilterByPrinterName
      ? listItems
      : listItems?.filter((item) => item?.printerName === filterByPrinterName);
  }, [listItems, filterByPrinterName, defaultFilterByPrinterName]);

  return (
    <PageList
      dataTestId="recordings-page-content"
      loading={loading}
      headerTitle={headerTitle}
      tabs={tabs}
      onTabClick={handleTabClick}
      listItems={filteredListItems}
      createItemMessageIconName="live_tv_0"
      createItemMessageTitle={intl.formatMessage({
        id: 'recordingspage.list_items.empty_list_title',
        defaultMessage: 'No Recordings yet',
      })}
      createItemMessagDescription={intl.formatMessage({
        id: 'recordingspage.list_items.empty_list_description',
        defaultMessage: 'Print job recordings will be displayed here',
      })}
      sortOptions={[
        {
          label: intl.formatMessage({
            id: 'general.date',
            defaultMessage: 'Date',
          }),
          value: 'createdAt',
        },
        {
          label: intl.formatMessage({
            id: 'general.name',
            defaultMessage: 'Name',
          }),
          value: 'label',
        },
      ]}
      actionChips={[
        {
          id: 'recordings-printers-filter-chip',
          dataTestId: 'recordings-page-content__filter-printers',
          label: filterByPrinterName,
          endingIconName: 'arrow_drop_down_1',
          dropDownMenuItems: printerFileterDropDownMenuItems,
        },
      ]}
    />
  );
};

RecordingsPageContent.propTypes = {
  handleTabClick: PropTypes.func,
  headerTitle: PropTypes.string,
  loading: PropTypes.bool,
  tabs: PropTypes.array,
  listItems: PropTypes.array,
};

export default RecordingsPageContent;
