import styled from 'styled-components';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';

export const Wrapper = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurfaceVariant',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.76;
`;
