import React, { useCallback, useEffect, Fragment } from 'react';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { PAGE_HEADER_VARIANT_SMALL } from '@components/2-molecules/PageHeader';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import SettingTextField from '@components/2-molecules/SettingTextField';
import PropTypes from 'prop-types';
import useToolList from '@hooks/tools/useToolList';
import { toolTypeLabel, TOOL_TYPES } from '@constants/tools';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import { useLocation } from 'react-router-dom';
import { TOOLTIP_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Tooltip';

const Settings = ({ columns, setColumns, setInitialFormValues, toolId }) => {
  const { values, setFieldValue, errors, initialValues } = useFormikContext();
  const { getToolTypeDropDownMenuItems } = useToolList();
  const { showDialog } = useDialog();
  const location = useLocation();

  const handleSetFieldValue = useCallback(
    (name) => (e) => {
      setFieldValue(name, e.target.value);
    },
    [setFieldValue],
  );

  const isFieldTypeOf = useCallback((field, type) => field?.type === type, []);

  const handleHeaderClick = useCallback(
    (title) => {
      setColumns((prev) =>
        prev.map((column) =>
          column.title === title
            ? { ...column, expand: !column.expand }
            : column,
        ),
      );
    },
    [setColumns],
  );

  useEffect(() => {
    const initialType = initialValues.type;
    const state = location.state;
    if (state && initialType.value !== state.type) {
      const newInitialValues = TOOL_TYPES[state.type].fields.reduce(
        (acc, field) => {
          acc[field.name] = field.value;
          return acc;
        },
        {},
      );
      newInitialValues.type = {
        label: toolTypeLabel(state.type),
        value: state.type,
      };
      newInitialValues.name = values.name;
      newInitialValues.manufacturer = values.manufacturer;
      newInitialValues.productCode = values.productCode;
      newInitialValues.url = values.url;
      newInitialValues.description = values.description;

      setInitialFormValues(newInitialValues);
    }
  }, [location.state, values, initialValues, setInitialFormValues]);

  const handleDropDownClick = useCallback(() => {
    showDialog(ModalDataTypes.SELECT_TOOL_TYPE, { toolId: toolId });
  }, [toolId, showDialog]);

  return (
    <>
      {columns?.map((column) => (
        <Fragment key={column.title}>
          {column?.fields?.length > 0 && (
            <SettingsCategory
              dataTestId={`tool__page__${column.title}-header`}
              key={`tool__page__${column.title}-header`}
              variant={PAGE_HEADER_VARIANT_SMALL}
              title={column.title}
              withDividerTop
              onHeaderClick={() => handleHeaderClick(column.title)}
              expand={column.expand}
            >
              {column?.fields?.map((columnField) => (
                <Fragment key={columnField.name}>
                  {isFieldTypeOf(columnField, 'dropdown') && (
                    <FormikField
                      key={`tool-${columnField.name}__dropdown-field`}
                      dataTestId={`tool-${columnField.name}__dropdown-field`}
                      component={SettingDropDown}
                      label={columnField.label}
                      name={columnField.name}
                      labelTooltip={
                        columnField.tooltip && {
                          tooltipAppearDelay: 200,
                          tooltipWidth: 350,
                          tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
                          tooltipOffsetRight: 44,
                          tooltipBridge: false,
                          tooltipInfoRow: {
                            label: columnField.tooltip,
                          },
                        }
                      }
                      dropDownField={
                        columnField.name === 'type'
                          ? {
                              onClick: () => handleDropDownClick(),
                              value: values['type']?.label,
                            }
                          : {
                              dropDownMenuItems: getToolTypeDropDownMenuItems(
                                values,
                                columnField.name,
                              ),
                              fullWidthDropDownMenu: false,
                            }
                      }
                    />
                  )}

                  {isFieldTypeOf(columnField) && (
                    <SettingTextField
                      key={`tool__${columnField.name}__field`}
                      dataTestId={`tool${columnField.name}__field`}
                      label={columnField.label}
                      labelTooltip={
                        columnField.tooltip && {
                          tooltipAppearDelay: 200,
                          tooltipWidth: 350,
                          tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
                          tooltipOffsetRight: 44,
                          tooltipBridge: false,
                          tooltipInfoRow: {
                            label: columnField.tooltip,
                          },
                        }
                      }
                      field1={{
                        ...(columnField.fieldType && {
                          type: columnField.fieldType,
                        }),
                        value: values[columnField.name],
                        error: !!errors[columnField.name],
                        supportingText: errors[columnField.name],
                        onChange: handleSetFieldValue(columnField.name),
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </SettingsCategory>
          )}
        </Fragment>
      ))}
    </>
  );
};

Settings.propTypes = {
  setColumns: PropTypes.func.isRequired,
  setInitialFormValues: PropTypes.func.isRequired,
  toolId: PropTypes.string,
  columns: PropTypes.array.isRequired,
};

export default Settings;
