import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, pick } from 'lodash';
import IconButton from '@app/components/1-atoms/IconButton';
import InfoRow from '@components/1-atoms/InfoRow';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_OUTLINE,
} from '@components/1-atoms/Button';
import {
  Wrapper,
  Header,
  HeaderContent,
  HeaderLabel,
  HeaderText,
  MediaContainer,
  PlaceHolderIcon,
  Media,
  Content,
  Footer,
} from './Card.styled';
import Skeleton, {
  SKELETON_VARIANT_ONE_LINE,
} from '@app/components/1-atoms/Skeleton';

const Card = ({
  className,
  dataTestId = 'card',
  disabled,
  headerEndingIconButton = {},
  headerLabel,
  headerLeadingIconButton = {},
  headerText,
  id,
  infoRow = {},
  loading,
  mediaAlt = '',
  mediaHeight,
  mediaIsLoading,
  mediaPadding,
  mediaPlaceholderIconName,
  mediaSrc,
  onClick,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonDisabled,
  primaryButtonIconName,
  primaryButtonLabel,
  primaryButtonLoading,
  secondaryButtonDisabled,
  secondaryButtonIconName,
  secondaryButtonLabel,
  skeleton,
  video,
}) => {
  const disableActions = disabled || loading;
  const showHeaderLeadingIconButton = !isEmpty(headerLeadingIconButton);
  const showHeaderEndingIconButton = !isEmpty(headerEndingIconButton);
  const showHeader =
    showHeaderLeadingIconButton ||
    headerLabel ||
    headerText ||
    showHeaderEndingIconButton;
  const showInfoRow = !isEmpty(infoRow);
  const showFooter = secondaryButtonLabel || primaryButtonLabel;
  const isVideoMedia = !isEmpty(video);
  const showMedia = mediaSrc || isVideoMedia;

  if (skeleton) {
    return (
      <Skeleton
        variant={SKELETON_VARIANT_ONE_LINE}
        borderRadius="large"
        dataTestId={`${dataTestId}__skeleton`}
        height={216}
        withLine={false}
      />
    );
  }

  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {showHeader && (
        <Header data-testid={`${dataTestId}__header`}>
          {showHeaderLeadingIconButton && (
            <IconButton
              dataTestId={`${dataTestId}__header__leading-icon-button`}
              {...headerLeadingIconButton}
              disabled={disableActions}
            />
          )}

          {(headerLabel || headerText) && (
            <HeaderContent>
              {headerLabel && (
                <HeaderLabel dataTestId={`${dataTestId}__header__label`}>
                  {headerLabel}
                </HeaderLabel>
              )}

              {headerText && (
                <HeaderText dataTestId={`${dataTestId}__header__text`}>
                  {headerText}
                </HeaderText>
              )}
            </HeaderContent>
          )}

          {showHeaderEndingIconButton && (
            <IconButton
              dataTestId={`${dataTestId}__header__ending-icon-button`}
              {...headerEndingIconButton}
              disabled={disableActions}
            />
          )}
        </Header>
      )}

      {showMedia && (
        <MediaContainer
          data-testid={`${dataTestId}__media-container`}
          height={mediaHeight}
          padding={mediaPadding}
        >
          {(mediaPlaceholderIconName || mediaIsLoading) && (
            <PlaceHolderIcon
              name={
                mediaIsLoading
                  ? 'progress_activity_0'
                  : mediaPlaceholderIconName
              }
              spinning={mediaIsLoading}
            />
          )}

          {isVideoMedia ? (
            <Media
              as="video"
              data-testid={`${dataTestId}__media-container__media`}
              {...pick(video, ['ref', 'src', 'autoPlay', 'loop', 'muted'])}
            />
          ) : (
            <Media
              data-testid={`${dataTestId}__media-container__media`}
              src={mediaSrc}
              alt={mediaAlt}
            />
          )}
        </MediaContainer>
      )}

      {showInfoRow && (
        <Content data-testid={`${dataTestId}__content`}>
          <InfoRow
            dataTestId={`${dataTestId}__content__info-row`}
            {...infoRow}
            disabled={disableActions}
          />
        </Content>
      )}

      {showFooter && (
        <Footer data-testid={`${dataTestId}__footer`}>
          {secondaryButtonLabel && (
            <Button
              dataTestId={`${dataTestId}__footer__secondary-button`}
              variant={BUTTON_VARIANT_OUTLINE}
              disabled={secondaryButtonDisabled || disableActions}
              iconName={secondaryButtonIconName}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonLabel}
            </Button>
          )}

          {primaryButtonLabel && (
            <Button
              dataTestId={`${dataTestId}__footer__primary-button`}
              disabled={primaryButtonDisabled || disabled}
              loading={primaryButtonLoading || loading}
              variant={BUTTON_VARIANT_PRIMARY}
              iconName={primaryButtonIconName}
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </Footer>
      )}
    </Wrapper>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  headerEndingIconButton: PropTypes.shape(IconButton.propTypes),
  headerLabel: PropTypes.string,
  headerLeadingIconButton: PropTypes.shape(IconButton.propTypes),
  headerText: PropTypes.string,
  id: PropTypes.string,
  infoRow: PropTypes.shape(InfoRow.propTypes),
  loading: PropTypes.bool,
  mediaAlt: PropTypes.string,
  mediaHeight: PropTypes.number,
  mediaIsLoading: PropTypes.bool,
  mediaPadding: PropTypes.string,
  mediaPlaceholderIconName: PropTypes.string,
  mediaSrc: PropTypes.string,
  onClick: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonIconName: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  primaryButtonLoading: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonIconName: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  skeleton: PropTypes.bool,
  video: PropTypes.object,
};

export default Card;
