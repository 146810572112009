import React from 'react';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import PromptDialog from '@app/containers/Dialogs/PromptDialog';
import CreateFlowDialog from '@containers/Dialogs/CreateFlowDialog';
import SendCopyDialog from '@containers/Dialogs/SendCopyDialog';
import EditProjectDialog from '@containers/Dialogs/EditProjectDialog';
import EditWorkflowDialog from '@containers/Dialogs/EditWorkflowDialog';
import AddProjectDialog from '@containers/Dialogs/AddProjectDialog';
import EditFileDialog from '@containers/Dialogs/EditFileDialog';
import WorkflowDetailsDialog from '@containers/Dialogs/WorkflowDetailsDialog';
import EditOperatorDialog from '@containers/Dialogs/EditOperatorDialog';
import PublishFileDialog from '@containers/Dialogs/PublishFileDialog';
import CreateMaterialDialog from '@containers/Dialogs/CreateMaterialDialog';
import CloneMaterialDialog from '@containers/Dialogs/CloneMaterialDialog';
import ResetPasswordDialog from '@containers/Dialogs/ResetPasswordDialog';
import AddMFADialog from '@containers/Dialogs/AddMFADialog';
import CreateUserDialog from '@containers/Dialogs/CreateUserDialog';
import EditUserDialog from '@containers/Dialogs/EditUserDialog';
import UserInfoDialog from '@containers/Dialogs/UserInfoDialog';
import CreatePrinterDialog from '@containers/Dialogs/CreatePrinterDialog';
import RequestLicenseDialog from '@containers/Dialogs/RequestLicenseDialog';
import DeletePrinterDialog from '@containers/Dialogs/DeletePrinterDialog';
import ManageVisibilityDialog from '@containers/Dialogs/ManageVisibilityDialog';
import DeleteNozzleDialog from '@containers/Dialogs/DeleteNozzleDialog';
import TalkToAiDialog from '@containers/Dialogs/TalkToAiDialog';
import YoutubeVideoDialog from '@containers/Dialogs/YoutubeVideoDialog';
import EditSingleTextFieldDialog from '@containers/Dialogs/EditSingleTextFieldDialog';
import DownloadStreamToolpathDialog from './DownloadStreamToolpathDialog';
import EditRecordingDialog from './EditRecordingDialog';
import CreateToolDialog from '@containers/Dialogs/CreateToolDialog';
import SelectToolTypeDialog from '@containers/Dialogs/SelectToolTypeDialog';
import CloneToolDialog from '@containers/Dialogs/CloneToolDialog';

export default function Dialogs() {
  const { isDialogShown } = useDialog();

  //materials
  const showCreateMaterialDialog = isDialogShown(
    ModalDataTypes.CREATE_MATERIAL,
  );
  const showCloneMaterialDialog = isDialogShown(ModalDataTypes.CLONE_MATERIAL);

  //tools
  const showCreateToolDialog = isDialogShown(ModalDataTypes.CREATE_TOOL);
  const showToolTypeDialog = isDialogShown(ModalDataTypes.SELECT_TOOL_TYPE);
  const showCloneToolDialog = isDialogShown(ModalDataTypes.CLONE_TOOL);

  if (showCreateMaterialDialog) {
    return <CreateMaterialDialog />;
  }

  if (showCreateToolDialog) {
    return <CreateToolDialog />;
  }

  if (showToolTypeDialog) {
    return <SelectToolTypeDialog />;
  }

  if (showCloneToolDialog) {
    return <CloneToolDialog />;
  }

  if (showCloneMaterialDialog) {
    return <CloneMaterialDialog />;
  }

  //account
  const showResetPasswordDialog = isDialogShown(ModalDataTypes.RESET_PASSWORD);
  const showAddMFADialog = isDialogShown(ModalDataTypes.CONNECT_MFA);

  if (showResetPasswordDialog) {
    return <ResetPasswordDialog />;
  }

  if (showAddMFADialog) {
    return <AddMFADialog />;
  }

  //users
  const showCreateUserDialog = isDialogShown(ModalDataTypes.CREATE_USER);
  const showEditUserDialog = isDialogShown(ModalDataTypes.EDIT_USER);
  const showUserInfoDialog = isDialogShown(ModalDataTypes.USER_INFO);

  if (showCreateUserDialog) {
    return <CreateUserDialog />;
  }

  if (showEditUserDialog) {
    return <EditUserDialog />;
  }

  if (showUserInfoDialog) {
    return <UserInfoDialog />;
  }

  //printers
  const showCreatePrinterDialog = isDialogShown(ModalDataTypes.CREATE_PRINTER);
  const showDeletePrinterDialog = isDialogShown(ModalDataTypes.DELETE_PRINTER);
  const showDeleteNozzleDialog = isDialogShown(ModalDataTypes.DELETE_NOZZLE);
  const showManageVisibilityDialog = isDialogShown(
    ModalDataTypes.MANAGE_VISIBILITY,
  );

  if (showCreatePrinterDialog) {
    return <CreatePrinterDialog />;
  }

  if (showDeletePrinterDialog) {
    return <DeletePrinterDialog />;
  }

  if (showManageVisibilityDialog) {
    return <ManageVisibilityDialog />;
  }

  if (showDeleteNozzleDialog) {
    return <DeleteNozzleDialog />;
  }

  if (isDialogShown(ModalDataTypes.PROMPT)) {
    return <PromptDialog />;
  }

  if (isDialogShown(ModalDataTypes.CREATE_FLOW)) {
    return <CreateFlowDialog />;
  }

  if (isDialogShown(ModalDataTypes.SEND_COPY)) {
    return <SendCopyDialog />;
  }

  if (isDialogShown(ModalDataTypes.ADD_PROJECT)) {
    return <AddProjectDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_PROJECT)) {
    return <EditProjectDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_WORKFLOW)) {
    return <EditWorkflowDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_FILE)) {
    return <EditFileDialog />;
  }

  if (isDialogShown(ModalDataTypes.WORKFLOW_DETAILS)) {
    return <WorkflowDetailsDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_OPERATOR)) {
    return <EditOperatorDialog />;
  }

  if (isDialogShown(ModalDataTypes.PUBLISH_FILE)) {
    return <PublishFileDialog />;
  }

  if (isDialogShown(ModalDataTypes.TALK_TO_AI)) {
    return <TalkToAiDialog />;
  }

  if (isDialogShown(ModalDataTypes.YOUTUBE_VIDEO)) {
    return <YoutubeVideoDialog />;
  }

  if (isDialogShown(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH)) {
    return <DownloadStreamToolpathDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_PRINTER_MONITOR_RECORDING)) {
    return <EditRecordingDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_SINGLE_TEXT_FILED)) {
    return <EditSingleTextFieldDialog />;
  }

  if (isDialogShown(ModalDataTypes.REQUEST_DESKTOP_LICENSE)) {
    return <RequestLicenseDialog />;
  }

  return null;
}
