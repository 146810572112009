import actionTypes from '@actions';

const initialState = {
  data: {},
  ui: {
    clippingTool: {
      clipIsActive: false,
      clipAvailable: false,
      clipMode: 'axis',
      clipOption: 'z',
      clipMinRange: 0,
      clipMaxRange: 100,
      clipMinRangeValue: 0,
      clipMaxRangeValue: 0,
      clipStep: 1,
      clipRangeAppliedToObject: null,
    },
  },
};

const toolBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CLIP_TOOL_AVAILABILITY: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...state.ui.clippingTool,
            clipAvailable: action.payload?.clipAvailable,
          },
        },
      };
    }

    case actionTypes.RESET_CLIP_TOOL: {
      return initialState;
    }

    case actionTypes.TOGGLE_CLIP_TOOL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...initialState.ui.clippingTool,
            clipAvailable: state.ui.clippingTool?.clipAvailable,
            clipIsActive: !state.ui.clippingTool?.clipIsActive,
            clipMode: state.ui.clippingTool?.clipMode,
            clipOption: state.ui.clippingTool?.clipOption,
          },
        },
      };
    }

    case actionTypes.SELECT_CLIP_TOOL_OPTION: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...state.ui.clippingTool,
            clipOption: action.payload?.option,
          },
        },
      };
    }

    case actionTypes.SET_CLIP_TOOL_RANGE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...state.ui.clippingTool,
            clipStep: action.payload?.clipStep,
            clipMinRange: action.payload?.clipMinRange,
            clipMaxRange: action.payload?.clipMaxRange,
            clipMinRangeValue: action.payload?.clipMinRange,
            clipMaxRangeValue: action.payload?.clipMaxRange,
          },
        },
      };
    }

    case actionTypes.SET_CLIP_RANGE_APPLIED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...state.ui.clippingTool,
            clipRangeAppliedToObject: {
              clipStep: action.payload?.clipStep,
              clipMinRange: action.payload?.clipMinRange,
              clipMaxRange: action.payload?.clipMaxRange,
              clipMinRangeValue: action.payload?.clipMinRange,
              clipMaxRangeValue: action.payload?.clipMaxRange,
            },
          },
        },
      };
    }

    case actionTypes.RESET_CLIP_RANGE_APPLIED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...state.ui.clippingTool,
            clipRangeAppliedToObject: null,
          },
        },
      };
    }

    case actionTypes.UPDATE_CLIP_TOOL_MIN_MAX_RANGES: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...state.ui.clippingTool,
            clipMinRangeValue: action.payload?.clipMinRangeValue,
            clipMaxRangeValue: action.payload?.clipMaxRangeValue,
          },
        },
      };
    }

    case actionTypes.SET_CLIP_TOOL_MODE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          clippingTool: {
            ...initialState.ui.clippingTool,
            clipAvailable: state.ui.clippingTool?.clipAvailable,
            clipIsActive: state.ui.clippingTool?.clipIsActive,
            clipMode: action.payload?.clipMode,
            clipOption: action.payload?.clipOption,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default toolBarReducer;
