import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  flex: 1;
  overflow: hidden;

  ${({ theme: { colors, borderRadius, spacing } }) => css`
    background-color: ${colors.surfaceContainer};
    border-radius: ${borderRadius.large};
    padding: 0 ${spacing.level6} ${spacing.level6} ${spacing.level6};
  `}
`;

export const TabsContainer = styled.div`
  ${flexColumn}
  height: 100%;
  overflow: hidden;
`;

export const TabsContent = styled.div`
  ${flexColumn}
  flex: 1;
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.level6};
  `}
`;
