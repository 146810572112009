import { Directories } from '@constants/directories';
import endpoints from '@api/endpoints';

export const ROUTES = {
  ANALYTIC: '/analytics/:dashboardId?',
  ANALYTICS: '/analytics',
  DESKTOP_LICENSE_APPROVAL: '/desktop-license-approval',
  HOME: '/',
  LIBRARY: '/library',
  LOGIN: '/login',
  LOGOUT: '/authentication/logout',
  SSO_RESPONSE: '/authentication/sso',
  MONITOR: '/monitor',
  MONITOR_RECORDINGS: '/monitor/recordings',
  MATERIALS: '/materials',
  MATERIAL: '/materials/:materialId',
  TOOLS: '/tools',
  TOOL: '/tools/:toolId',
  PRINTERS: '/printers',
  PRINTER: '/printers/:printerId',
  PRINTS: '/printers/:printerId?/:directory(prints)?/:printId?',
  PROJECTS: '/workspaces',
  FILES: `/workspaces/:workspaceId/${Directories.files}`,
  WORKFLOWS: `/workspaces/:workspaceId/${Directories.workflows}`,
  WORKFLOW: `/workspaces/:workspaceId/${Directories.workflows}/:itemId`,
  RESET_PASSWORD: '/reset-password',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_PREFERENCES: '/settings/preferences',
  SETTINGS_LICENSES: '/settings/licenses',
  SETTINGS: '/settings',
  SHARED: '/shared/:type/:sentDataId',
  NOT_FOUND: '/not-found',
};

export const LOGGED_IN_NAVIGATION_LINKS = [
  {
    name: 'Projects',
    path: ROUTES.PROJECTS,
    intlId: 'navigation.link.project',
  },
  {
    name: 'Printers',
    path: ROUTES.PRINTERS,
    intlId: 'navigation.link.dropdown.printers',
  },
  {
    name: 'Materials',
    path: ROUTES.MATERIALS,
    intlId: 'navigation.link.dropdown.materials',
  },
  {
    name: 'Analytics',
    path: ROUTES.ANALYTICS,
    intlId: 'navigation.link.analytics',
  },
];

export const LOGGED_OUT_NAVIGATION_LINKS = [
  {
    name: 'Contact us',
    path: 'mailto:support@ai-build.com',
    external: true,
    intlId: 'navigation.link.contactus',
  },
];

export const SETTINGS_LINKS = [
  {
    name: 'Settings',
    path: ROUTES.SETTINGS_ACCOUNT,
    intlId: 'navigation.link.settings',
  },
  {
    name: 'Organization',
    path: ROUTES.ORGANIZATION,
    intlId: 'navigation.link.organization',
  },
];

export const VISUAL_TOOL_PARAM_NAME = 'visualTool';

export const ROUTE_MATCH_URL =
  '/:currentRoute?/:workspaceId?/:directory(files|workflows)?/:itemId?';

export const MANAGE_NOT_FOUND_ENDPOINTS = [
  endpoints.concept,
  endpoints.workspace,
  endpoints.printer,
];
