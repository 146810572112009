import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import useDialog from '@hooks/useDialog';
import useModal from '@hooks/useModal';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import { ModalDataTypes, MODAL_IDS } from '@constants/modalDataTypes';
import { b, br } from '@utils/render';
import PrinterPrintingStatus from '@components/PrinterMonitor/PrinterPrintingStatus';
import PrinterRecordingDetails from '@components/PrinterMonitor/PrinterRecordingDetails';

export default function usePrinterList() {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const { showModal } = useModal();
  const { deleteRecordingMutation } = usePrinterMutations();

  const getPrinterDropDownMenuActions = useCallback(
    (printer = {}) => {
      const actions = [
        {
          leadingIconName: 'query_stats_0',
          label: intl.formatMessage({
            id: 'printeractions.sensor_data.label',
            defaultMessage: 'Sensor data',
          }),
          onClick: () => {
            showModal(MODAL_IDS.PRINTER_SENSOR_DATA, { printer });
          },
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.DELETE_PRINTER, {
              printerId: printer.id,
              printerName: printer.name,
            });
          },
        },
      ];

      return actions;
    },
    [showDialog, showModal, intl],
  );

  const getPrinterMonitorDropDownMenuActions = useCallback(
    (printer = {}) => {
      const actions = [
        {
          leadingIconName: 'videocam_0',
          label: intl.formatMessage({
            id: 'printeractions.monitor.label',
            defaultMessage: 'Monitor',
          }),
          onClick: () => {
            showModal(MODAL_IDS.PRINTER_MONITOR_RECORDING, {
              printerId: printer.id,
            });
          },
        },
      ];

      return actions;
    },
    [showModal, intl],
  );

  const getPrinterRecordingDropDownMenuActions = useCallback(
    (recording = {}) => {
      const actions = [
        {
          leadingIconName: 'live_tv_0',
          label: intl.formatMessage({
            id: 'recordingspage.recording.watch_recording',
            defaultMessage: 'Watch recording',
          }),
          onClick: () => {
            showModal(MODAL_IDS.PRINTER_MONITOR_RECORDING, {
              printerId: recording.printerId,
              recording,
            });
          },
        },
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.rename',
            defaultMessage: 'Rename',
          }),
          onClick: () => {
            showDialog(ModalDataTypes.EDIT_PRINTER_MONITOR_RECORDING, {
              recording,
            });
          },
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-printer-recording-dialog',
              title: intl.formatMessage({
                id: 'delete_printer_recording_dialog.title',
                defaultMessage: 'Delete Recording',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'delete_printer_recording_dialog.text',
                  defaultMessage:
                    '<b>{name} {date} - {duration}</b> will be permanently deleted.<br></br><br></br>Are you sure you want to proceed?',
                },
                {
                  name: recording.name,
                  date: moment(recording.createdAt).format('DD/MM/YYYY'),
                  duration: moment.utc(recording.duration).format('HH:mm:ss'),
                  b,
                  br,
                },
              ),
              primaryButtonLabel: intl.formatMessage({
                id: 'general.delete',
                defaultMessage: 'Delete',
              }),
              onPrimaryButtonClick: async () => {
                try {
                  await deleteRecordingMutation.mutateAsync({
                    recordingId: recording.id,
                  });
                } catch (_) {
                  //error
                }
              },
            });
          },
        },
      ];

      return actions;
    },
    [showDialog, showModal, intl, deleteRecordingMutation],
  );

  const getPrintersMonitorList = useCallback(
    (printers, { withMoreButton = true } = {}) => {
      const list = printers?.map((printer) => {
        let optionalListItemProps = {};
        const printerStatus = printer.printerStatus;

        if (withMoreButton) {
          optionalListItemProps = {
            ...optionalListItemProps,
            endingIconButtonIconName: 'more_vert_0',
            endingIconButtonDropDownProps: {
              dropDownMenuItems: getPrinterMonitorDropDownMenuActions(printer),
            },
          };
        }

        return {
          id: printer?.id,
          label: printer?.name,
          description: (
            <PrinterPrintingStatus
              printerStatus={printerStatus?.status}
              recordingStartedAt={printerStatus?.recordingStartedAt}
              ticking
            />
          ),
          leadingIconName: 'precision_manufacturing_0',
          onClick: () => {
            showModal(MODAL_IDS.PRINTER_MONITOR_RECORDING, {
              printerId: printer.id,
            });
          },
          ...optionalListItemProps,
        };
      });

      return list;
    },
    [getPrinterMonitorDropDownMenuActions, showModal],
  );

  const getPrintersRecordingList = useCallback(
    (recordings, { withMoreButton = true } = {}) => {
      const list = recordings?.map((recording) => {
        let optionalListItemProps = {};

        if (withMoreButton) {
          optionalListItemProps = {
            ...optionalListItemProps,
            endingIconButtonIconName: 'more_vert_0',
            endingIconButtonDropDownProps: {
              dropDownMenuItems:
                getPrinterRecordingDropDownMenuActions(recording),
            },
          };
        }

        return {
          id: recording?.id,
          label: recording?.name,
          description: <PrinterRecordingDetails recording={recording} />,
          createdAt: recording?.startedAt,
          printerName: recording?.printerName,
          printerId: recording?.printerId,
          leadingIconName: 'live_tv_0',
          onClick: () => {
            showModal(MODAL_IDS.PRINTER_MONITOR_RECORDING, {
              printerId: recording.printerId,
              recording,
            });
          },
          ...optionalListItemProps,
        };
      });

      return list;
    },
    [getPrinterRecordingDropDownMenuActions, showModal],
  );

  return {
    getPrinterDropDownMenuActions,
    getPrinterMonitorDropDownMenuActions,
    getPrinterRecordingDropDownMenuActions,
    getPrintersMonitorList,
    getPrintersRecordingList,
  };
}
