import { useCallback, useEffect, useState } from 'react';
import useWSClient from '@hooks/websocket/useWSClient';

export default function useSocketMessage({
  messageType = '',
  messageCondition = () => true,
}) {
  const ws = useWSClient();
  const [messageData, setMessageData] = useState();

  const handleMessage = useCallback(
    (message) => {
      const event = JSON.parse(message.data);

      if (event.type !== messageType) {
        return;
      }

      const data = event?.data;

      if (!messageCondition?.(data)) {
        return;
      }

      setMessageData(data);
    },
    [messageType, messageCondition],
  );

  useEffect(() => {
    ws.removeEventListener('message', handleMessage);
    ws.addEventListener('message', handleMessage);

    return () => {
      ws.removeEventListener('message', handleMessage);
    };
  }, [handleMessage, ws]);

  return {
    messageData,
  };
}
