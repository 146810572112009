import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const printerQueryKeys = {
  validateShare: (type, sentDataId) => ['showLoader', type, sentDataId],
};

export default function useShareQueries({
  type,
  sentDataId,
  queryParams,
} = {}) {
  const validateShare = useQuery({
    queryKey: printerQueryKeys.validateShare(type, sentDataId),
    queryFn: () =>
      client
        .get(
          endpoints.validateShare
            .replace(':type', type)
            .replace(':sentDataId', sentDataId),
          {
            params: queryParams,
            ignoreGlobalErrorHandler: true,
          },
        )
        .then((res) => res.data),
    enabled: !!type && !!sentDataId,
    retry: false,
  });

  return {
    validateShare,
  };
}
