import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useHls from '@hooks/useHls';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import IconButton, {
  ICON_BUTTON_VARIANT_TONAL,
} from '@components/1-atoms/IconButton';
import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';
import Slider from '@components/1-atoms/Slider';
import PrinterRecordingHeader from '@components/PrinterMonitor/PrinterRecordingHeader';
import PrinterRecordingVideoPlayer from '@components/PrinterMonitor/PrinterRecordingVideoPlayer';
import PrinterRecordingData from '@components/PrinterMonitor/PrinterRecordingData';
import PrinterRecordingControls from '@components/PrinterMonitor/PrinterRecordingControls';
import {
  Wrapper,
  RecordingContent,
  VideoControls,
} from '@containers/LivePrinterRecording/LivePrinterRecording.styled';

const PRINTER_SENSOR_WAITING_TIME_ID = 'waiting_time';

const HistoryPrinterRecording = ({ printerId, recording }) => {
  const intl = useIntl();
  const [videoSliderValue, setVideoSliderValue] = useState(0);
  const [isSliderDragging, setIsSliderDragging] = useState(false);
  const iseSliderDtaggingRef = useRef(false);
  const {
    printerQuery,
    printerCamerasQuery,
    printerControlsQuery,
    printerSensorDataQuery,
  } = usePrinterQueries({
    printerId,
    printerRecordingId: recording?.id,
  });

  const printer = printerQuery?.data;
  const cameras = printerCamerasQuery?.data;
  const printerControls = printerControlsQuery?.data;
  const sensorData = printerSensorDataQuery?.data;

  const isLoading =
    printerQuery?.isLoading ||
    printerCamerasQuery?.isLoading ||
    printerControlsQuery?.isLoading ||
    printerSensorDataQuery?.isLoading;
  const mainCamera = cameras?.[0];
  const {
    videoRef,
    isLoading: isVideoLoading,
    isPlaying,
    isTouched,
    streamStatus,
    formattedCurrentTime,
    formattedTotalDuration,
    totalDuration,
    currentTime,
  } = useHls(mainCamera?.hlsUrl);

  const waitingTimeSensorData = useMemo(
    () =>
      sensorData?.find(
        (data) => data?.sensorId === PRINTER_SENSOR_WAITING_TIME_ID,
      ),
    [sensorData],
  );

  const currentTimePrinterControls = useMemo(() => {
    const currentTimeData = printerControls.reduce((acc, control) => {
      const controlId = control?.id;
      const controlSensorData = sensorData?.find(
        (data) => data?.sensorId === controlId,
      );

      if (!controlSensorData) return acc;

      const currentTimeData = controlSensorData?.dataPoints?.filter(
        (dataPoint) => (dataPoint?.timeOffsetMs || 0) / 1000 <= currentTime,
      );
      const lastSensorValue = currentTimeData?.at(-1)?.data.value;

      return [
        ...acc,
        {
          ...control,
          value: lastSensorValue,
        },
      ];
    }, []);

    return currentTimeData;
  }, [printerControls, currentTime, sensorData]);

  const currentTimeSensorData = useMemo(() => {
    if (!waitingTimeSensorData) return null;

    const currentTimeData = waitingTimeSensorData?.dataPoints
      ?.filter(
        (dataPoint) => (dataPoint?.timeOffsetMs || 0) / 1000 <= currentTime,
      )
      .map((dataPoint) => dataPoint.data);

    return currentTimeData;
  }, [currentTime, waitingTimeSensorData]);

  const onPlay = useCallback(() => {
    const videoElement = videoRef.current;

    if (isPlaying) {
      videoElement.pause();
    } else {
      videoElement.play();
    }
  }, [isPlaying, videoRef]);

  const onVideoTimeUpdate = useCallback(
    (time) => {
      const videoElement = videoRef.current;

      setVideoSliderValue(time);
      videoElement.currentTime = time;
    },
    [videoRef],
  );

  useEffect(() => {
    iseSliderDtaggingRef.current = isSliderDragging;
  }, [isSliderDragging]);

  useEffect(() => {
    if (!iseSliderDtaggingRef.current && !isVideoLoading) {
      setVideoSliderValue(currentTime);
    }
  }, [isVideoLoading, currentTime]);

  if (isLoading) {
    return <PageLoaderPortal show />;
  }

  return (
    <Wrapper>
      <PrinterRecordingHeader
        printerName={printer?.name}
        recording={recording}
      />

      <RecordingContent>
        <PrinterRecordingVideoPlayer
          videoRef={videoRef}
          cameraName={mainCamera?.name}
          cameraStatus={streamStatus}
          isVideoTouched={isTouched}
          isVideoLoading={isVideoLoading}
        />

        <PrinterRecordingData data={currentTimeSensorData} />
      </RecordingContent>

      <PrinterRecordingControls controls={currentTimePrinterControls} />

      <VideoControls>
        <IconButton
          variant={ICON_BUTTON_VARIANT_TONAL}
          iconName={isPlaying ? 'pause_1' : 'play_arrow_1'}
          title={intl.formatMessage({
            id: isPlaying ? 'general.pause' : 'general.play',
            defaultMessage: isPlaying ? 'Pause' : 'Play',
          })}
          onClick={onPlay}
        />

        <Slider
          minTitle={formattedCurrentTime}
          maxTitle={formattedTotalDuration}
          min={0}
          max={totalDuration}
          step={1}
          value={videoSliderValue}
          onChange={setVideoSliderValue}
          onFocus={() => setIsSliderDragging(true)}
          onBlur={() => setIsSliderDragging(false)}
          onAfterChange={onVideoTimeUpdate}
        />
      </VideoControls>
    </Wrapper>
  );
};

HistoryPrinterRecording.propTypes = {
  printerId: PropTypes.string.isRequired,
  recording: PropTypes.shape({
    id: PropTypes.string,
    printerId: PropTypes.string,
    startedAt: PropTypes.string,
    endedAt: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default HistoryPrinterRecording;
