import React from 'react';

export const b = (str) => <b>{str}</b>;
export const br = () => <br />;

export const mapTypeForDropdown = (type) => {
  return {
    label: type
      .split('_')
      .map((word) => {
        let formattedWord = word.toLowerCase();
        formattedWord = `${formattedWord[0].toUpperCase()}${formattedWord.slice(
          1,
        )}`;

        return formattedWord;
      })
      .join(' '),
    value: type,
  };
};
