import React, { useCallback } from 'react';
import useModal from '@hooks/useModal';
import ModalPortal from '@components/2-molecules/ModalPortal';
import LivePrinterRecording from '@containers/LivePrinterRecording';
import HistoryPrinterRecording from '@containers/HistoryPrinterRecording';
import { MODAL_IDS } from '@constants/modalDataTypes';
import { isEmpty } from 'lodash';

const MODAL_ID = MODAL_IDS.PRINTER_MONITOR_RECORDING;

const PrinterRecordingModal = () => {
  const { hideModal, getModalData } = useModal();

  const {
    dataTestId = 'live-printer-recording-modal',
    printerId,
    recording,
  } = getModalData(MODAL_ID);

  const isRecordingHistory = !isEmpty(recording);

  const handleClose = useCallback(() => {
    hideModal(MODAL_ID);
  }, [hideModal]);

  return (
    <ModalPortal
      dataTestId={dataTestId}
      modalId={MODAL_ID}
      onClose={handleClose}
    >
      {isRecordingHistory ? (
        <HistoryPrinterRecording printerId={printerId} recording={recording} />
      ) : (
        <LivePrinterRecording printerId={printerId} />
      )}
    </ModalPortal>
  );
};

PrinterRecordingModal.propTypes = {};

export default PrinterRecordingModal;
