import { capitalize } from 'lodash';

export const toolsConstants = {
  ALL: 'ALL',
  BALL_END_MILL: 'BALL_END_MILL',
  FLAT_END_MILL: 'FLAT_END_MILL',
  ENGRAVING_BIT: 'ENGRAVING_BIT',
  DRILL_BIT: 'DRILL_BIT',

  CLOCKWISE: 'CLOCKWISE',
  ANTICLOCKWISE: 'ANTICLOCKWISE',

  HIGH_SPEED_STEEL: 'HIGH_SPEED_STEEL',
  CARBIDE: 'CARBIDE',
  CERAMIC: 'CERAMIC',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
};

export const toolTypeLabel = (tool) => {
  return tool
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');
};

export const TOOL_MILLING_TYPES = {
  BALL_END_MILL: {
    mediaSrc: '/img/slice-modes/horizontal.png',
    title: toolTypeLabel(toolsConstants.BALL_END_MILL),
    value: 'BALL_END_MILL',
  },
  FLAT_END_MILL: {
    mediaSrc: '/img/slice-modes/horizontal.png',
    title: toolTypeLabel(toolsConstants.FLAT_END_MILL),
    value: 'FLAT_END_MILL',
  },
  ENGRAVING_BIT: {
    mediaSrc: '/img/slice-modes/horizontal.png',
    title: toolTypeLabel(toolsConstants.ENGRAVING_BIT),
    value: 'ENGRAVING_BIT',
  },
  DRILL_BIT: {
    mediaSrc: '/img/slice-modes/horizontal.png',
    title: toolTypeLabel(toolsConstants.DRILL_BIT),
    value: 'DRILL_BIT',
  },
};

const commonGeometryFields = [
  {
    name: 'spindleRotation',
    value: {
      label: toolTypeLabel(toolsConstants.CLOCKWISE),
      value: toolsConstants.CLOCKWISE,
    },
  },
  { name: 'numberOfFlutes', value: 2 },
  {
    name: 'material',
    value: {
      label: toolTypeLabel(toolsConstants.NOT_SPECIFIED),
      value: toolsConstants.NOT_SPECIFIED,
    },
  },
  { name: 'diameter', value: 6 },
  { name: 'shaftDiameter', value: 6 },
  { name: 'totalLength', value: 75 },
  { name: 'lengthBelowHolder', value: 50 },
  { name: 'shoulderLength', value: 20 },
  { name: 'fluteLength', value: 20 },
];

const commonFeedSpeedFields = [
  { name: 'spindleSpeed', value: 5000 },
  { name: 'surfaceSpeed', value: 5000 * 6 * Math.PI },
  { name: 'plungeFeedrate', value: 10 },
  { name: 'plungeFeedratePerRevolution', value: 10 / 5000 },
];

const commonEngravingBitFlatEndMillBallEndMillFeedSpeedFields = [
  { name: 'cuttingFeedrate', value: 20 },
  { name: 'feedPerTooth', value: 20 / (5000 * 2) },
  { name: 'leadInFeedrate', value: 20 },
  { name: 'leadOutFeedrate', value: 20 },
  { name: 'transitionFeedrate', value: 20 },
  { name: 'defaultStepdown', value: 6 * 0.3 },
  { name: 'defaultStepover', value: '' },
];

const commonFlatEndMillBallEndMillFeedSpeedFields = [
  { name: 'rampSpindleSpeed', value: 5000 },
  { name: 'rampFeedrate', value: 10 },
];

const toolSpecificFields = {
  BALL_END_MILL: [
    ...commonEngravingBitFlatEndMillBallEndMillFeedSpeedFields,
    ...commonFlatEndMillBallEndMillFeedSpeedFields,
  ],
  FLAT_END_MILL: [
    ...commonEngravingBitFlatEndMillBallEndMillFeedSpeedFields,
    ...commonFlatEndMillBallEndMillFeedSpeedFields,
  ],
  ENGRAVING_BIT: [
    ...commonEngravingBitFlatEndMillBallEndMillFeedSpeedFields,
    { name: 'tipDiameter', value: 0.1 },
    { name: 'taperAngle', value: 45 },
  ],
  DRILL_BIT: [
    { name: 'tipAngle', value: 118 },
    { name: 'retractFeedrate', value: 10 },
    { name: 'retractFeedratePerRevolution', value: 10 / 5000 },
  ],
};

export const TOOL_TYPES = Object.keys(toolSpecificFields).reduce(
  (acc, type) => ({
    ...acc,
    [type]: {
      fields: [
        ...commonGeometryFields,
        ...commonFeedSpeedFields,
        ...toolSpecificFields[type],
      ],
    },
  }),
  {},
);

export default TOOL_TYPES;
