import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import useHls from '@hooks/useHls';
import { useSelector } from 'react-redux';
import { getPrinterStatus } from '@selectors/printerSelectors';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import usePrinterRecordingWaitingTimesSocket from '@hooks/websocket/usePrinterRecordingWaitingTimesSocket';
import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';
import PrinterRecordingHeader from '@components/PrinterMonitor/PrinterRecordingHeader';
import PrinterRecordingVideoPlayer from '@components/PrinterMonitor/PrinterRecordingVideoPlayer';
import PrinterRecordingData from '@components/PrinterMonitor/PrinterRecordingData';
import PrinterRecordingControls from '@components/PrinterMonitor/PrinterRecordingControls';
import { PrinterStatuses } from '@constants/printerStatuses';
import { Wrapper, RecordingContent } from './LivePrinterRecording.styled';

const LivePrinterRecording = ({ printerId }) => {
  const [livePrinterStatus, setLivePrinterStatus] = useState({});
  const [currentMainCamera, setCurrentMainCamera] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [previousCameraStatus, setPreviousCameraStatus] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const { printerQuery, printerCamerasQuery, printerControlsQuery } =
    usePrinterQueries({
      printerId,
    });
  const { enableCamerasVideoStreams } = usePrinterMutations();
  const printerHeartbeat = useSelector(getPrinterStatus(printerId));

  usePrinterSocket();

  const printer = printerQuery?.data;
  const cameras = printerCamerasQuery?.data;
  const printerControls = printerControlsQuery?.data;

  useEffect(() => {
    if (cameras && cameras.length > 0) {
      setCurrentMainCamera(cameras[0]);
    }
  }, [cameras]);

  const isPrinterIdle = livePrinterStatus?.status === PrinterStatuses.IDLE;
  const isLoading =
    printerQuery?.isLoading ||
    printerCamerasQuery?.isLoading ||
    printerControlsQuery?.isLoading;

  const { videoRef, streamStatus, isStandby } = useHls(
    currentMainCamera?.hlsUrl,
  );

  const { waitingLayerData } = usePrinterRecordingWaitingTimesSocket({
    printerId,
    cameraId: currentMainCamera?.id,
  });

  const enableCamera = useCallback(async () => {
    if (isVideoLoading) return;

    setPreviousCameraStatus({
      livePrinterStatus,
      mainCamera: currentMainCamera,
    });

    await enableCamerasVideoStreams.mutateAsync({
      printerId: printerId,
    });

    setIsVideoLoading(true);
    setIsPolling(true);
  }, [
    enableCamerasVideoStreams,
    printerId,
    livePrinterStatus,
    currentMainCamera,
    isVideoLoading,
  ]);

  const pollForCameraStatus = useCallback(
    (attempt = 0) => {
      if (!isPolling) return;
      const maxAttempts = 12; // 1 minute (12 * 5 seconds)
      if (attempt >= maxAttempts) {
        setIsPolling(false);
        setIsVideoLoading(false);
        setLivePrinterStatus(previousCameraStatus.livePrinterStatus);
        setCurrentMainCamera(previousCameraStatus.mainCamera);
        return;
      }

      printerCamerasQuery
        .refetch()
        .then((response) => {
          const updatedMainCamera = response.data?.[0];
          if (
            updatedMainCamera &&
            updatedMainCamera.hlsUrl !== previousCameraStatus.mainCamera?.hlsUrl
          ) {
            setIsPolling(false);
            setIsVideoLoading(false);
            setCurrentMainCamera(updatedMainCamera);
          } else {
            setTimeout(() => {
              pollForCameraStatus(attempt + 1);
            }, 5000);
          }
        })
        .catch(() => {
          setTimeout(() => {
            pollForCameraStatus(attempt + 1);
          }, 5000);
        });
    },
    [isPolling, printerCamerasQuery, previousCameraStatus],
  );

  useEffect(() => {
    if (isPolling) {
      pollForCameraStatus();
    }
  }, [isPolling, pollForCameraStatus]);

  useEffect(() => {
    const skip = !printer?.printerStatus && !printerHeartbeat;

    if (skip) return;

    const printerStatusSource = printerHeartbeat || printer?.printerStatus;
    const nextLivePrinterStatus = pick(printerStatusSource, [
      'status',
      'recordingStartedAt',
    ]);

    setLivePrinterStatus(nextLivePrinterStatus);
  }, [printer?.printerStatus, printerHeartbeat]);

  useEffect(() => {
    return () => {
      setIsPolling(false);
    };
  }, []);

  if (isLoading) {
    return <PageLoaderPortal show />;
  }

  return (
    <Wrapper>
      <PrinterRecordingHeader
        printerName={printer?.name}
        printerStatus={livePrinterStatus}
      />

      <RecordingContent>
        <PrinterRecordingVideoPlayer
          videoRef={videoRef}
          cameraName={currentMainCamera?.name}
          cameraStatus={streamStatus}
          cameraNotEnabled={isPrinterIdle && isStandby}
          enableCamera={enableCamera}
          autoPlay
          isVideoLoading={isVideoLoading}
        />

        <PrinterRecordingData data={waitingLayerData} />
      </RecordingContent>

      <PrinterRecordingControls controls={printerControls} editable />
    </Wrapper>
  );
};

LivePrinterRecording.propTypes = {
  printerId: PropTypes.string.isRequired,
};

export default LivePrinterRecording;
