import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { PostProcessorSettingsName } from '@components/Printers/PostProcessorConfig/PostProcessorConstants';
import {
  postProcessorCalibrationTypes,
  postProcessorStartTypes,
} from '../../../../constants/printers/postProcessorSettingsDefinitions';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';

const PostProcessorKUKA = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();

  const getFormattedOptions = useMemo(
    () => (definitions) =>
      Object.values(definitions).map((option) => ({
        label: option.label,
        formFieldValue: {
          label: option.label,
          value: option.value,
        },
      })),
    [],
  );

  const startPositionOptions = getFormattedOptions(postProcessorStartTypes);
  const calibrationOptions = getFormattedOptions(postProcessorCalibrationTypes);

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.kuka.startPosition.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-start`}
        name={PostProcessorSettingsName.startPosition}
        dropDownMenuItems={startPositionOptions}
        disabled={isSubmitting}
      />
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.kuka.initialTool.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-kuka-initialTool`}
        name={PostProcessorSettingsName.setInitialTool}
        dropDownMenuItems={calibrationOptions}
        disabled={isSubmitting}
      />
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.kuka.initialBase.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-kuka-initialBase`}
        name={PostProcessorSettingsName.setInitialBase}
        dropDownMenuItems={calibrationOptions}
        disabled={isSubmitting}
      />
      <SettingsCategory
        title={intl.formatMessage({
          id: 'printers.postprocessor.isToolExternalAxis.label',
          defaultMessage: 'Use Tool As External Axis',
        })}
        dataTestId={`printer-custom-post-processor__setting-isToolExternalAxis`}
        endingCheckboxChecked={Boolean(
          values[PostProcessorSettingsName.isToolExternalAxis],
        )}
        endingCheckboxDirty={checkIfFieldIsDirty(
          values,
          initialValues,
          PostProcessorSettingsName.isToolExternalAxis,
        )}
        onEndingCheckboxClick={() => {
          setFieldValue(
            PostProcessorSettingsName.isToolExternalAxis,
            !values[PostProcessorSettingsName.isToolExternalAxis],
          );
        }}
        expand={Boolean(values[PostProcessorSettingsName.isToolExternalAxis])}
      >
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: 'printers.postprocessor.toolExternalAxisId.label',
            defaultMessage: 'Tool External Axis ID',
          })}
          dataTestId={`printer-custom-post-processor__setting-toolExternalAxisId`}
          name={PostProcessorSettingsName.toolExternalAxisId}
          field1={{
            placeholder: intl.formatMessage({
              id: 'printers.postprocessor.toolExternalAxisId.placeholder',
              defaultMessage: 'Enter ID',
            }),
            type: 'number',
            disabled: isSubmitting,
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              PostProcessorSettingsName.toolExternalAxisId,
            ),
          }}
        />
      </SettingsCategory>
    </>
  );
};

PostProcessorKUKA.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorKUKA;
