import { useCallback, useMemo, useEffect } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useSelector } from 'react-redux';
import { getConfig } from '@actions/AuthenticatedFetch';
import { getCurrentUser } from '@selectors/loginSelectors';

export default function useWSClient() {
  const currentUser = useSelector(getCurrentUser());
  const Config = getConfig();
  const baseUrl = Config.baseUrlws || 'ws://localhost/';

  const urlProvider = useCallback(async () => {
    const token = currentUser?.token;

    return baseUrl + 'ws/events' + '?token=' + encodeURIComponent(token);
  }, [baseUrl, currentUser?.token]);

  const wsOptions = useMemo(
    () => ({
      connectionTimeout: 2000,
      minReconnectionDelay: 500,
      maxReconnectionDelay: 3000,
    }),
    [],
  );

  const ws = useMemo(
    () => new ReconnectingWebSocket(urlProvider, [], wsOptions),
    [urlProvider, wsOptions],
  );

  useEffect(() => {
    return () => {
      ws.close();
    };
  }, [ws]);

  return ws;
}
