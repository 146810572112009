export default {
  authentication: 'authentication',
  camera: 'cameras/:printerId/:cameraId',
  cameras: 'cameras/:printerId',
  cloneMaterial: 'materials/clone/:materialId',
  componentData: 'component/:componentId/data',
  components: 'components',
  componentTemplates: 'component-templates',
  concept: 'concepts/:conceptId',
  upgradeConcept: 'concepts/:conceptId/upgrade',
  conceptMetadata: 'concepts/:conceptId/metadata',
  conceptNozzleUpdate: 'concepts/nozzle-update/:conceptId',
  concepts: 'concepts',
  conceptsPublic: 'concepts/public',
  conceptsDefaultOperators: 'concepts/default_operators',
  dashboards: 'dashboards',
  deactivateMFA: 'authentication/mfa/deactivate',
  designs: 'designs',
  design: 'designs/:designId',
  canvasSelectionObject: 'designs/canvas-selection/:designId/:fileName',
  designThumbnail: 'designs/:designId/thumbnail',
  duplicateOperator: 'workflows/:workflowId/operator/:operatorId/duplicate',
  duplicateWorkflow: 'workflows/:workflowId/duplicate',
  geometry: 'designs/:geometryId/workflowPrinter',
  enclosureDefinitions: 'printers/enclosure_definitions',
  explodeTemplate: 'concepts/:conceptId/operators/:operatorId/explode',
  extruderDefinitions: 'printers/extruder_definitions',
  generateMFASecret: 'authentication/mfa/generateSecret',
  generativeWorkflow: 'generative/workflow/:workflowId',
  logout: 'authentication/logout',
  machineDefinitions: 'printers/machine_definitions',
  materials: 'materials',
  material: 'materials/:materialId',
  tools: 'tools',
  tool: 'tools/:toolId',
  cloneTool: 'tools/clone/:toolId',
  nozzles: 'nozzles',
  operators: 'concepts/:conceptId/operators',
  operator: 'concepts/operators/:operatorId',
  reorderOperator: 'concepts/operators/:operatorId/order/:order',
  conceptRecommendedOperators: 'concepts/:conceptId/recommendations',
  operatorValue:
    'concepts/operators/:operatorId/operatorValues/:operatorValueId',
  publishDesign: 'designs/:designId/publish/:designName',
  operatorValues: 'concepts/operatorvalues',
  passwordReset: 'users/password/reset',
  passwordResetToken: 'users/:username/password/reset-token',
  passwordResetTokenValidity: 'users/password/reset-token/:token',
  plinthDefinitions: 'printers/plinth_definitions',
  postProcessorConfig: 'printers/:printerId/postprocessor',
  printer: 'printers/:printerId',
  printerWorkflows: 'printers/:printerId/workflows',
  printerComponentVisibility: 'printers/component/visibility',
  printerNozzles: 'nozzles/:printerId',
  printerNozzleWorkflows: 'nozzles/:nozzleId/workflows',
  printers: 'printers',
  printersNozzles: 'nozzles/printers',
  printerStreamOperator: 'printers/:printerId/operators/:operatorId',
  conceptMaterialUpdate: 'concepts/material-update/:conceptId',
  printingBedDefinitions: 'printers/printing_bed_definitions',
  prints: 'prints',
  printerPrint: 'printers/:printerId/:printId',
  programTypeDefinitions: 'printers/program_type_definitions',
  runConcept: 'concepts/:conceptId/run',
  template: 'templates/:templateId',
  templates: 'templates',
  updateOperatorTag: 'concepts/:workflowId/operators/:operatorId/tag',
  users: 'users',
  userPreference: 'users/preference',
  validateMFACode: 'authentication/mfa/validateCode',
  validateToken: 'users',
  workspace: 'workspaces/:workspaceId',
  workspaceDesignThumbnails: 'workspaces/:workspaceId/design_thumbnails',
  workspaces: 'workspaces',
  knowledgeCentre: 'knowledge-centre',
  workflowAnnotation: 'workflow-annotations',
  workflows: 'workflows',
  workflowValues: 'workflows/:workflowId/values',
  computationVersion: 'version/computation',
  version: 'version',
  updateWorkflowPrinterConfiguration:
    'concepts/:workflowId/refresh-printer-snapshot',
  getAuthenticationConfig: 'authentication/config',
  getCurrentUser: 'authentication/user',
  shareProject: 'shared/send/workspace/:workspaceId',
  shareWorkflow: 'shared/send/workflow/:workflowId',
  validateShare: 'shared/:type/:sentDataId/validate',
  importSharedWorkflow: 'shared/workflow/:workflowId/import',
  importSharedProject: 'shared/workspace/:workspaceId/import',
  recordings: 'recordings',
  recording: 'recordings/:recordingId',
  printerCameras: 'cameras/:printerId',
  enableCamerasStream: 'cameras/:printerId/enable-video-stream',
  printerControls: 'printers/:printerId/controls',
  printerControl: 'printers/:printerId/controls/:controlId',
  requestDesktopLicense: 'authentication/license/new',
  approveDesktopLicense: 'authentication/license/issue',
  getDesktopLicenses: 'authentication/license/',
  sensorsData: 'recordings/:printerId/:recordingId/sensors_data',
};
