import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isAnyFetchInProgess } from '@selectors/fetchSelector';
import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';
import useToolQueries from '@hooks/tools/useToolQueries';
import { resetNavigationBarHistory } from '@actions/applicationActions';
import { useParams } from 'react-router-dom';

const ToolLoader = ({ children }) => {
  const { toolId } = useParams();
  const isFetching = useSelector(isAnyFetchInProgess);
  const dispatch = useDispatch();
  const { toolsQuery } = useToolQueries();
  const { refetch: refetchToolsQuery } = toolsQuery;

  useEffect(() => {
    refetchToolsQuery();
  }, [refetchToolsQuery, toolId]);

  const pageDataIsFetched = !isFetching && toolsQuery.isFetched;

  useEffect(() => {
    return () => {
      dispatch(resetNavigationBarHistory());
    };
  }, [dispatch]);

  if (!pageDataIsFetched) {
    return <PageLoaderPortal show />;
  }

  return children;
};

ToolLoader.propTypes = {
  children: PropTypes.node,
};

export default ToolLoader;
