import styled, { css } from 'styled-components';
import { flexColumn, flexCenterVertical } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  width: min(100%, 956px);
  margin-inline: auto;
  ${flexColumn}
`;

export const Header = styled.div`
  ${flexColumn}
`;

export const RecordingContent = styled.div`
  display: grid;
  height: 368px;
  grid-template-columns: 364px 1fr;
  gap: 16px;
  overflow: hidden;
  margin-bottom: 36px;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level6};
  `}
`;

export const VideoControls = styled.div`
  ${flexCenterVertical}
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 26px;

  ${({ theme: { colors } }) => css`
    background: ${colors.surfaceContainer};
  `}
`;
