import { Redirect } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { mapTypeForDropdown } from '@utils/render';
import { filterFields } from '@utils/filter';
import * as Yup from 'yup';
import useToolMutations from '@hooks/tools/useToolMutations';
import useSnackbar from '@hooks/useSnackbar';
import Settings from '@components/Tools/Editor/Settings';
import SettingBar from '@components/2-molecules/SettingBar';
import {
  createNumberValidator,
  createStringValidator,
} from '@utils/validationHelper';

const deserializeTool = (tool) => {
  if (!tool) {
    return {};
  }

  const transformedObjects = Object.keys(tool).map((key) => {
    const value = tool[key] == null ? '' : tool[key];

    if (key === 'type' || key === 'spindleRotation' || key === 'material') {
      return {
        [key]: mapTypeForDropdown(value),
      };
    }

    return {
      [key]: `${value}`,
    };
  });

  // Merge mapped properties
  return Object.assign({}, ...transformedObjects);
};

const serializeTool = (tool) => {
  function toValue(val) {
    return val == '' ? null : val;
  }

  if (!tool) {
    return {};
  }

  const result = Object.entries(tool).reduce((acc, [key, value]) => {
    if (key === 'type' || key === 'spindleRotation' || key === 'material') {
      acc[key] = value.value;
      return acc;
    }

    acc[key] = toValue(value);
    return acc;
  }, {});

  return result;
};

const Editor = ({ handleSidebarCollapse, selectedTool }) => {
  const tool = useMemo(() => deserializeTool(selectedTool), [selectedTool]);
  const intl = useIntl();
  const { showSnackbar } = useSnackbar();
  const { updateToolMutation } = useToolMutations();

  const createField = useMemo(
    () =>
      (
        type,
        labelId,
        labelDefault,
        descriptionId,
        name,
        validator = null,
        fieldType = null,
      ) => ({
        ...(type && { type }),
        label: intl.formatMessage({
          id: labelId,
          defaultMessage: labelDefault,
        }),
        ...(descriptionId && {
          tooltip: intl.formatMessage({
            id: descriptionId,
            defaultMessage: descriptionId,
          }),
        }),
        name,
        ...(validator && { validator }),
        ...(fieldType && { fieldType }),
      }),
    [intl],
  );

  const generalFields = useMemo(
    () => [
      createField(
        'dropdown',
        'tools.tool.general.tab.type.label',
        'Tool type',
        null,
        'type',
      ),
      createField(
        null,
        'tools.tool.general.tab.name.label',
        'Name',
        null,
        'name',
        createStringValidator(
          'tools.create_tool_dialog.tool_name.validation.required',
          {
            min: {
              value: 2,
              messageId: 'tools.create_tool_dialog.tool_name.validation.min',
              messageDefault: 'Name must be at least 2 characters long',
            },
            max: {
              value: 256,
              messageId: 'tools.create_tool_dialog.tool_name.validation.max',
              messageDefault: 'Name must be no longer than 256 characters',
            },
          },
        ),
      ),
      createField(
        null,
        'tools.tool.general.tab.manufacturer.label',
        'Manufacturer',
        null,
        'manufacturer',
        createStringValidator('tools.validation.manufacturer.required'),
      ),
      createField(
        null,
        'tools.tool.general.tab.productCode.label',
        'Product Code',
        null,
        'productCode',
        createStringValidator('tools.validation.product_code.required'),
      ),
      createField(
        null,
        'tools.tool.general.tab.url.label',
        'URL',
        null,
        'url',
        createStringValidator('tools.validation.url.required'),
      ),
      createField(
        null,
        'tools.tool.general.tab.description.label',
        'Description',
        null,
        'description',
        createStringValidator('tools.validation.description.required'),
      ),
    ],
    [createField],
  );

  const geometryFields = useMemo(
    () => [
      createField(
        'dropdown',
        'tools.tool.geometry.tab.spindle_rotation.label',
        'Spindle Rotation',
        'tools.tool.geometry.tab.spindle_rotation.description',
        'spindleRotation',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.number_of_flutes.label',
        'Number of Flutes',
        'tools.tool.geometry.tab.number_of_flutes.description',
        'numberOfFlutes',
        createNumberValidator(1, 12, true, true),
        'number',
      ),
      createField(
        'dropdown',
        'tools.tool.geometry.tab.material.label',
        'Material',
        'tools.tool.geometry.tab.material.description',
        'material',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.diameter.label',
        'Diameter',
        'tools.tool.geometry.tab.diameter.description',
        'diameter',
        createNumberValidator(0.1, 100, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.shaft_diameter.label',
        'Shaft Diameter',
        'tools.tool.geometry.tab.shaft_diameter.description',
        'shaftDiameter',
        createNumberValidator(0.1, 100, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.tip_diameter.label',
        'Tip Diameter',
        'tools.tool.geometry.tab.tip_diameter.description',
        'tipDiameter',
        createNumberValidator(0.1, 100, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.total_length.label',
        'Total Length',
        'tools.tool.geometry.tab.total_length.description',
        'totalLength',
        createNumberValidator(1, 500, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.length_below_holder.label',
        'Length Below Holder',
        'tools.tool.geometry.tab.length_below_holder.description',
        'lengthBelowHolder',
        createNumberValidator(1, 500, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.shoulder_length.label',
        'Shoulder Length',
        'tools.tool.geometry.tab.shoulder_length.label.description',
        'shoulderLength',
        createNumberValidator(1, 500, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.flute_length.label',
        'Flute Length',
        'tools.tool.geometry.tab.flute_length.description',
        'fluteLength',
        createNumberValidator(1, 500, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.taper_angle.label',
        'Taper Angle',
        'tools.tool.geometry.tab.taper_angle.description',
        'taperAngle',
        createNumberValidator(1, 180, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.geometry.tab.tip_angle.label',
        'Tip Angle',
        'tools.tool.geometry.tab.tip_angle.description',
        'tipAngle',
        createNumberValidator(1, 180, false, true),
        'number',
      ),
    ],
    [createField],
  );

  const feedsAndSpeedsFields = useMemo(
    () => [
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.spindle_speed.label',
        'Spindle Speed',
        'tools.tool.feeds_and_speeds.tab.spindle_speed.description',
        'spindleSpeed',
        createNumberValidator(1, 500000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.surface_speed.label',
        'Surface Speed',
        'tools.tool.feeds_and_speeds.tab.surface_speed.description',
        'surfaceSpeed',
        createNumberValidator(0, 157000000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.ramp_spindle_speed.label',
        'Ramp Spindle Speed',
        'tools.tool.feeds_and_speeds.tab.ramp_spindle_speed.description',
        'rampSpindleSpeed',
        createNumberValidator(1, 500000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.cutting_feedrate.label',
        'Cutting Feedrate',
        'tools.tool.feeds_and_speeds.tab.cutting_feedrate.description',
        'cuttingFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.feed_per_tooth.label',
        'Feed Per Tooth',
        'tools.tool.feeds_and_speeds.tab.feed_per_tooth.description',
        'feedPerTooth',
        createNumberValidator(0, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.lead_in_feedrate.label',
        'Lead-in Feedrate',
        'tools.tool.feeds_and_speeds.tab.lead_in_feedrate.description',
        'leadInFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.lead_out_feedrate.label',
        'Lead-out Feedrate',
        'tools.tool.feeds_and_speeds.tab.lead_out_feedrate.description',
        'leadOutFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.transition_feedrate.label',
        'Transition Feedrate',
        'tools.tool.feeds_and_speeds.tab.transition_feedrate.description',
        'transitionFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.ramp_feedrate.label',
        'Ramp Feedrate',
        'tools.tool.feeds_and_speeds.tab.ramp_feedrate.description',
        'rampFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.plunge_feedrate.label',
        'Plunge Feedrate',
        'tools.tool.feeds_and_speeds.tab.plunge_feedrate.description',
        'plungeFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.plunge_feedrate_per_revolution.label',
        'Plunge Feedrate Per Revolution',
        'tools.tool.feeds_and_speeds.tab.plunge_feedrate_per_revolution.description',
        'plungeFeedratePerRevolution',
        createNumberValidator(0, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.default_stepdown.label',
        'Default Stepdown',
        'tools.tool.feeds_and_speeds.tab.default_stepdown.description',
        'defaultStepdown',
        createNumberValidator(0.1, 50, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.default_stepover.label',
        'Default Stepover',
        'tools.tool.feeds_and_speeds.tab.default_stepover.description',
        'defaultStepover',
        null,
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.retract_feedrate.label',
        'Retract Feedrate',
        'tools.tool.feeds_and_speeds.tab.retract_feedrate.description',
        'retractFeedrate',
        createNumberValidator(1, 1000, false, true),
        'number',
      ),
      createField(
        null,
        'tools.tool.feeds_and_speeds.tab.retract_feedrate_per_revolution.label',
        'Retract Feedrate Per Revolution',
        'tools.tool.feeds_and_speeds.tab.retract_feedrate_per_revolution.description',
        'retractFeedratePerRevolution',
        createNumberValidator(0, 1000, false, true),
        'number',
      ),
    ],
    [createField],
  );

  const getAllFields = useCallback(
    (tool) => {
      return filterFields(
        [...generalFields, ...geometryFields, ...feedsAndSpeedsFields],
        tool,
      );
    },
    [generalFields, geometryFields, feedsAndSpeedsFields],
  );

  const createColumns = useMemo(() => {
    return (initialValues) => [
      {
        title: intl.formatMessage({
          id: 'tools.tool.column.title.general_tab',
          defaultMessage: 'General',
        }),
        fields: generalFields,
        expand: true,
      },
      {
        title: intl.formatMessage({
          id: 'tools.tool.column.title.geometry',
          defaultMessage: 'Geometry',
        }),
        fields: filterFields(geometryFields, initialValues),
        expand: true,
      },
      {
        title: intl.formatMessage({
          id: 'tools.tool.column.title.feeds_and_speeds',
          defaultMessage: 'Feeds and Speeds',
        }),
        fields: filterFields(feedsAndSpeedsFields, initialValues),
        expand: true,
      },
    ];
  }, [intl, generalFields, geometryFields, feedsAndSpeedsFields]);

  const [columns, setColumns] = useState(() => createColumns(tool));

  const onFormSubmit = useCallback(
    async (values) => {
      const toolUpdate = serializeTool(values);

      toolUpdate.id = selectedTool.id;
      try {
        await updateToolMutation.mutateAsync({ tool: toolUpdate });
      } catch (_) {
        // error
      }

      showSnackbar({
        text: intl.formatMessage({
          id: 'tools.update.snackbar.label',
          defaultMessage: 'Tool was successfully updated',
        }),
      });
    },
    [showSnackbar, intl, selectedTool, updateToolMutation],
  );

  const [initialFormValues, setInitialFormValues] = useState(() => {
    return getAllFields(tool).reduce(
      (acc, field) => ({
        ...acc,
        [field?.name]: tool[field?.name],
      }),
      {},
    );
  });

  const generateValidationSchema = useCallback(
    (formValues) => {
      const schemaFields = getAllFields(formValues).reduce((acc, field) => {
        if (field.validator) {
          acc[field.name] = field.validator;
        }
        return acc;
      }, {});

      return Yup.object().shape(schemaFields);
    },
    [getAllFields],
  );

  const [formValidationSchema, setFormValidationSchema] = useState(() => {
    return generateValidationSchema(initialFormValues);
  });

  useEffect(() => {
    setColumns(createColumns(initialFormValues));
    setFormValidationSchema(generateValidationSchema(initialFormValues));
  }, [
    setFormValidationSchema,
    generateValidationSchema,
    initialFormValues,
    tool,
    createColumns,
  ]);

  if (!tool) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={formValidationSchema}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit }) => (
        <SettingBar
          renderAsForm
          headerTitle={intl.formatMessage({
            id: 'tools.tool.editor.title',
            defaultMessage: 'Configure Tool Component',
          })}
          footerPrimaryButtonLabel={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          onHeaderLeadingIconButtonClick={handleSidebarCollapse}
          onSubmit={handleSubmit}
          withFooterDivider
          headerEndingIconButtonIconName={''}
        >
          <Settings
            columns={columns}
            setColumns={setColumns}
            setInitialFormValues={setInitialFormValues}
            toolId={tool?.id}
          />
        </SettingBar>
      )}
    </Formik>
  );
};

Editor.propTypes = {
  handleSidebarCollapse: PropTypes.func.isRequired,
  selectedTool: PropTypes.object.isRequired,
};

export default Editor;
