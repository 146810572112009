export const fileExtensions = {
  PAC: '.psc',
  RAPID: '.mod',
  VAL3: '.zip',
  KUKA: '.zip',
  ABB: '.zip',
  YASKAWA: '.zip',
  KUKA_STREAMING: '.src',
  KUKA_ARCTECH: '.zip',
  KUKA_EXLABS: '.zip',
  GCODE: '.gcode',
  AILAB_TWIN_SPLIT: '.zip',
  CEAD_FLEXBOT: '.mpf',
  CEAD_FLEXBOT_WEIR: '.mpf',
  CEAD_FLEXBOT_DFC: '.mpf',
  CEAD_FLEXBOT_MILLING: '.mpf',
  WEBER_DX: '.zip',
  CNC_DESIGN_ADDITIVE: '.mpf',
  CNC_DESIGN_SUBTRACTIVE: '.mpf',
  BELOTTI: '.mpf',
  GENERATIVE_MACHINE: '.gcode',
  SPERRA: '.gcode',
  MELD: '.nc',
  GCODE_ROBOTSTUDIO: '.gcode',
  GCODE_2: '.gcode',
  GCODE_9: '.nc',
  GCODE_12: '.gcode',
  GCODE_13: '.gcode',
  GCODE_14: '.dxr',
  GCODE_15: '.gcode',
  GCODE_16: '.gcode',
  CLIENT_1: '.src',
  CLIENT_3: '.src',
  CLIENT_4: '.zip',
  CLIENT_5: '.zip',
  CLIENT_6: '.src',
  CLIENT_7: '.src',
  CLIENT_8: '.src',
  CLIENT_9: '.zip',
  CLIENT_10: '.zip',
  CLIENT_11: '.gcode',
  CLIENT_12: '.mpf',
  CLIENT_13: '.src',
  CLIENT_14: '.gcode',
  CLIENT_16: '.zip',
  CLIENT_17: '.zip',
  CLIENT_18: '.zip',
  CLIENT_19: '.gcode',
  CLIENT_20: '.gcode',
  CLIENT_21: '.zip',
  CLIENT_23: '.src',
  CLIENT_24: '.zip',
  CLIENT_26: '.src',
  CLIENT_28: '.gcode',
  CLIENT_29: '.mpf',
  CLIENT_30: '.zip',
  CLIENT_31: '.gcode',
  CLIENT_32: '.zip',
  CLIENT_33: '.urp',
  CLIENT_34: '.zip',
  CLIENT_35: '.gcode',
  CLIENT_36: '.zip',
  CLIENT_37: '.nc',
  CLIENT_38: '.zip',
  CLIENT_39: '.gcode',
  CLIENT_40: '.zip',
  KUKA_WAAM_ARCTECH: '.zip',
  CLIENT_42: '.nc',
  CLIENT_43: '.zip',
  CLIENT_44: '.zip',
  CLIENT_45: '.zip',
  CLIENT_46: '.zip',
  CLIENT_47: '.dxr',
  CLIENT_48: '.nc',
  CLIENT_49: '.ppf',
  INGERSOLL_VOLUMETRIC: '.ppf',
  CLIENT_50: '.zip',
  CLIENT_51: '.gcode',
  CLIENT_52: '.zip',
  CLIENT_53: '.zip',
  CLIENT_54: '.gcode',
  CLIENT_56: '.mpf',
  CLIENT_57: '.zip',
  CLIENT_58: '.gcd',
  CLIENT_59: '.zip',
  CLIENT_60: '.zip',
  CLIENT_61: '.zip',
  CLIENT_62: '.gcode',
  CLIENT_63: '.zip',
  CLIENT_64: '.zip',
  CLIENT_66: '.gcode',
  CLIENT_67: '.zip',
  CLIENT_68: '.zip',
  CLIENT_69: '.zip',
  CLIENT_70: '.gcode',
  CLIENT_71: '.zip',
  CLIENT_72: '.dxr',
  CLIENT_73: '.gcode',
  CLIENT_74: '.gcode',
  CLIENT_75: '.gcode',
  CLIENT_76: '.npg',
  CLIENT_77: '.gcode',
  CLIENT_78: '.src',
  CLIENT_79: '.zip',
  CLIENT_80: '.zip',
  CLIENT_83: '.zip',
  CLIENT_84: '.zip',
  CLIENT_85: '.zip',
  CLIENT_86: '.zip',
  CLIENT_87: '.zip',
  CLIENT_88: '.zip',
  CLIENT_89: '.zip',
  CLIENT_90: '.mpf',
  CLIENT_91: '.zip',
  CLIENT_92: '.mpf',
  CLIENT_93: '.zip',
  CLIENT_95: '.zip',
  CLIENT_96: '.mpf',
  CLIENT_97: '.zip',
};
