import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import Hls from 'hls.js';
import moment from 'moment';

export default function useHls(streamUrl) {
  const intl = useIntl();
  const videoRef = useRef(null);
  const hlsRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(false);
  const [isStandby, setIsStandby] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  const isTouchedRef = useRef(isTouched);

  const formattedCurrentTime = moment
    .utc(currentTime * 1000)
    .format('HH:mm:ss');
  const formattedTotalDuration = moment
    .utc(totalDuration * 1000)
    .format('HH:mm:ss');

  const streamStatus = useMemo(() => {
    if (isOnline) {
      return intl.formatMessage({
        id: 'hls.status.online',
        defaultMessage: 'Online',
      });
    }

    if (isStandby) {
      return intl.formatMessage({
        id: 'hls.status.standby',
        defaultMessage: 'Standby',
      });
    }

    if (isError) {
      return intl.formatMessage({
        id: 'hls.status.error',
        defaultMessage: 'Offline',
      });
    }

    return intl.formatMessage({
      id: 'hls.status.offline',
      defaultMessage: 'Offline',
    });
  }, [intl, isOnline, isStandby, isError]);

  const updateIsTouched = useCallback(() => {
    if (isTouchedRef.current) return;

    setIsTouched(true);
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    if (!video || !streamUrl) return;

    video.addEventListener('play', () => {
      setIsPlaying(true);
    });

    video.addEventListener('pause', () => {
      setIsPlaying(false);
    });

    video.addEventListener('ended', () => {
      setIsPlaying(false);
    });

    video.addEventListener('timeupdate', () => {
      setCurrentTime(video.currentTime);
    });

    if (Hls.isSupported()) {
      const hls = new Hls();
      hlsRef.current = hls;

      // Attach the video element
      hls.attachMedia(video);

      hls.on(Hls.Events.FRAG_BUFFERED, () => {
        setIsLoading(false);
      });

      hls.on(Hls.Events.LEVEL_LOADED, (_, event) => {
        setTotalDuration(event.details.totalduration);
      });

      // Listen for media attachment and load the stream
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (DEVELOPMENT_ENV) {
          // eslint-disable-next-line no-console
          console.log('Media attached, starting stream...');
        }

        setIsLoading(true);
        setIsStandby(false);
        hls.loadSource(streamUrl);
      });

      // Handle manifest parsed (ready to play)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (DEVELOPMENT_ENV) {
          // eslint-disable-next-line no-console
          console.log('Manifest loaded, stream is ready.');
        }

        setIsOnline(true);
        setIsError(false);
      });

      // Handle errors
      hls.on(Hls.Events.ERROR, (_, data) => {
        if (data.fatal) {
          if (DEVELOPMENT_ENV) {
            // eslint-disable-next-line no-console
            console.error('A fatal error occurred:', data);
          }

          setIsOnline(false);
          setIsError(true);

          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              if (DEVELOPMENT_ENV) {
                // eslint-disable-next-line no-console
                console.error('Network error - stream not available');
              }

              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              if (DEVELOPMENT_ENV) {
                // eslint-disable-next-line no-console
                console.error('Media error');
              }

              break;
            default:
              if (DEVELOPMENT_ENV) {
                // eslint-disable-next-line no-console
                console.error('Unhandled error');
              }

              hls.destroy();
              break;
          }
        }
      });

      // Cleanup on unmount
      return () => {
        hls.destroy();
      };
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // Native HLS support (e.g., Safari)
      video.src = streamUrl;

      video.addEventListener('loadedmetadata', () => {
        if (DEVELOPMENT_ENV) {
          // eslint-disable-next-line no-console
          console.log('Stream available and playing.');
        }

        setIsOnline(true);
        setIsError(false);
        setIsStandby(false);
      });

      video.addEventListener('error', () => {
        if (DEVELOPMENT_ENV) {
          // eslint-disable-next-line no-console
          console.error('Stream not available or failed to load.');
        }

        setIsOnline(false);
        setIsError(true);
        setIsStandby(false);
      });

      return () => {
        video.removeEventListener('loadedmetadata', () => {});
        video.removeEventListener('error', () => {});
      };
    } else {
      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.error('HLS is not supported in this browser.');
      }

      setIsError(true);
      setIsStandby(false);
    }
  }, [streamUrl]);

  useEffect(() => {
    isTouchedRef.current = isTouched;
  }, [isTouched]);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;

    video.addEventListener('play', () => {
      setIsLoading(false);
      updateIsTouched();
    });

    video.addEventListener('seeking', () => setIsLoading(true));
    video.addEventListener('seeked', () => setIsLoading(false));
    // video.addEventListener('waiting', () => setIsLoading(true));
    video.addEventListener('canplay', () => setIsLoading(false));

    video.addEventListener('timeupdate', () => {
      updateIsTouched();
    });
  }, [videoRef, streamUrl, updateIsTouched]);

  return {
    videoRef,
    isLoading,
    isOnline,
    isStandby,
    isError,
    isPlaying,
    isTouched,
    streamStatus,
    totalDuration,
    currentTime,
    formattedCurrentTime,
    formattedTotalDuration,
  };
}
