import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import Tabs, { TABS_VARIANT_TEXT } from '@components/2-molecules/Tabs';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import PrinterRecordingChart from '@components/PrinterMonitor/PrinterRecordingChart';
import PrinterRecordingLogs from '@components/PrinterMonitor/PrinterRecordingLogs';
import getIntlProvider from '@utils/getIntlProvider';
import {
  Wrapper,
  TabsContainer,
  TabsContent,
} from './PrinterRecordingData.styled';

const intl = getIntlProvider();

const TABS = [
  {
    id: 'chart',
    title: intl.formatMessage({
      id: 'printermonitor.recording_logs.tabs.chart',
      defaultMessage: 'Chart',
    }),
  },
  {
    id: 'logs',
    title: intl.formatMessage({
      id: 'printermonitor.recording_logs.tabs.logs',
      defaultMessage: 'Logs',
    }),
  },
];

const PrinterRecordingData = ({ data = [] }) => {
  const intl = useIntl();
  const [activeTabId, setActiveTabId] = useState(TABS[0].id);
  const isEmptyData = isEmpty(data);
  const isChartTabActive = activeTabId === 'chart';
  const isLogsTabActive = activeTabId === 'logs';

  const handleTabClick = useCallback((tab) => setActiveTabId(tab?.id), []);

  return (
    <Wrapper>
      {isEmptyData && (
        <EmptyStateBox
          dataTestId="printer-recording-data__empty-state"
          iconName="query_stats_0"
          title={intl.formatMessage({
            id: 'printermonitor.recording_logs.empty_state.title',
            defaultMessage: 'Wait time',
          })}
          description={intl.formatMessage({
            id: 'printermonitor.recording_logs.empty_state.description',
            defaultMessage: 'Displays the inter-layer wait times of the print',
          })}
        />
      )}

      {!isEmptyData && (
        <>
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'printermonitor.recording_logs.title',
              defaultMessage: 'Wait time',
            })}
          />

          <TabsContainer>
            <Tabs
              dataTestId="printer-recording-data__tabs"
              variant={TABS_VARIANT_TEXT}
              tabs={TABS.map((tab) => ({
                ...tab,
                active: tab.id === activeTabId,
              }))}
              onTabClick={handleTabClick}
            />

            <TabsContent>
              {isChartTabActive && <PrinterRecordingChart data={data} />}

              {isLogsTabActive && <PrinterRecordingLogs data={data} />}
            </TabsContent>
          </TabsContainer>
        </>
      )}
    </Wrapper>
  );
};

PrinterRecordingData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      printerId: PropTypes.string,
      cameraId: PropTypes.string,
      waitTime: PropTypes.number,
      layer: PropTypes.number,
    }),
  ),
};

export default PrinterRecordingData;
