import { useCallback, useEffect } from 'react';
import useLicenseMutations from '@app/hooks/license/useLicenseMutations';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSnackbar from '@app/hooks/useSnackbar';
import { ROUTES } from '@app/constants/router';

const ApproveDesktopLicense = () => {
  const intl = useIntl();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const queryParams = Object.fromEntries(
    new URLSearchParams(history.location.search),
  );
  const token = queryParams?.token;
  const { approveIssuanceMutation } = useLicenseMutations();
  const isApproved = approveIssuanceMutation.isSuccess;
  const isError = approveIssuanceMutation.isError;
  const error = approveIssuanceMutation.error;

  const handleErrorResponse = useCallback(
    (responseStatus) => {
      const isWindowExpired = responseStatus === 400;
      const isAlreadyIssued = responseStatus === 409;
      const isNotAuthorized = responseStatus === 403;

      let navigationPageState = {};

      if (isWindowExpired) {
        navigationPageState = {
          icon: 'person_0',
          title: intl.formatMessage({
            id: 'approvedesktoplicense.expired.title',
            defaultMessage: 'Request Expired',
          }),
          description: intl.formatMessage({
            id: 'approvedesktoplicense.expired.description',
            defaultMessage:
              'This request has expired and cannot be approved. It must be re-initiated by the requestor.',
          }),
        };
      }

      if (isAlreadyIssued) {
        navigationPageState = {
          icon: 'person_0',
          title: intl.formatMessage({
            id: 'approvedesktoplicense.alreadyissued.title',
            defaultMessage: 'License Already Issued',
          }),
          description: intl.formatMessage({
            id: 'approvedesktoplicense.alreadyissued.description',
            defaultMessage:
              'This license request has already been approved and issued.',
          }),
        };
      }

      if (isNotAuthorized) {
        navigationPageState = {
          icon: 'person_0',
          title: intl.formatMessage({
            id: 'sharedpage.notauthorized.title',
            defaultMessage: 'Access Restricted',
          }),
          description: intl.formatMessage({
            id: 'sharedpage.notauthorized.description',
            defaultMessage:
              "You don't have the necessary permissions to access this page or resource. If you believe you should have access, please contact our support team for further assistance.",
          }),
        };
      }

      if (!isWindowExpired && !isAlreadyIssued && !isNotAuthorized) {
        navigationPageState = {
          icon: 'help_0',
          title: intl.formatMessage({
            id: 'sharedpage.error.title',
            defaultMessage: 'Something went wrong',
          }),
          description: intl.formatMessage({
            id: 'sharedpage.error.description',
            defaultMessage:
              "We're sorry, but it looks like something didn't go as planned. You can: refresh the page, return to the home page, or contact our support team if the issue persists.",
          }),
        };
      }

      history.push(ROUTES.NOT_FOUND, navigationPageState);
    },
    [intl, history],
  );
  useEffect(() => {
    if (!isError) return;

    handleErrorResponse(error?.response?.status);
  }, [isError, error?.response?.status, handleErrorResponse]);

  useEffect(() => {
    if (!isApproved) return;

    showSnackbar({
      text: intl.formatMessage({
        id: 'approvedesktoplicense.issued.snackbar',
        defaultMessage:
          'License was successfully issued and sent to the recipient.',
      }),
    });
    history.push(ROUTES.HOME);
  }, [isApproved, showSnackbar, history, intl]);

  useEffect(() => {
    if (approveIssuanceMutation.isIdle && !approveIssuanceMutation.isError) {
      approveIssuanceMutation.mutate(token);
    }
  }, [approveIssuanceMutation, token]);
  return null;
};

export default ApproveDesktopLicense;
