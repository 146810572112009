import React, { useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import RecordingsPageContent from '@components/RecordingsPageContent';
import usePrinterList from '@hooks/printers/usePrinterList';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import { Wrapper } from './Recordings.styled';

const REAL_TIME_TAB = 'Real-time';
const RECORDINGS_TAB = 'Recordings';

const Recordings = () => {
  const intl = useIntl();
  const history = useHistory();
  const { getPrintersRecordingList } = usePrinterList();
  const { printersRecordingQuery } = usePrinterQueries();

  const refetchPrintersRecordingQueryQuery = printersRecordingQuery.refetch;
  const recordings = printersRecordingQuery.data;

  const recordingsList = getPrintersRecordingList(recordings);

  const handleTabClick = useCallback(
    (tab) => {
      const isRecordingsTab = tab?.id === RECORDINGS_TAB;

      if (isRecordingsTab) return;

      history.push(ROUTES.MONITOR);
    },
    [history],
  );

  useEffect(() => {
    refetchPrintersRecordingQueryQuery();
  }, [refetchPrintersRecordingQueryQuery]);

  return (
    <Wrapper data-testid="monitor-page">
      <RecordingsPageContent
        handleTabClick={handleTabClick}
        headerTitle={intl.formatMessage({
          id: 'monitorpage.header_title',
          defaultMessage: 'Monitor',
        })}
        loading={!printersRecordingQuery?.isFetched}
        listItems={recordingsList}
        tabs={[
          {
            id: REAL_TIME_TAB,
            title: intl.formatMessage({
              id: 'monitorpage.monitor_list.tab_title.real_time',
              defaultMessage: 'Real-time',
            }),
          },
          {
            id: RECORDINGS_TAB,
            title: intl.formatMessage({
              id: 'monitorpage.monitor_list.tab_title.recordings',
              defaultMessage: 'Recordings',
            }),
            active: true,
          },
        ]}
      />
    </Wrapper>
  );
};

Recordings.propTypes = {};

export default Recordings;
