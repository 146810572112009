import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Card from '@components/2-molecules/Card';

const PrinterRecordingVideoPlayer = ({
  autoPlay = false,
  cameraName = '',
  cameraNotEnabled,
  cameraStatus,
  enableCamera,
  isVideoTouched,
  isVideoLoading,
  videoRef,
}) => {
  const intl = useIntl();

  const optionalProps = useMemo(() => {
    let props = {};

    if (cameraNotEnabled && enableCamera) {
      props = {
        ...props,
        primaryButtonLabel: intl.formatMessage({
          id: 'printer_recording.enable_camera_button.title',
          defaultMessage: 'Enable camera',
        }),
        onPrimaryButtonClick: enableCamera,
      };
    }

    return props;
  }, [intl, cameraNotEnabled, enableCamera]);

  return (
    <Card
      {...optionalProps}
      mediaPlaceholderIconName={
        !autoPlay && !isVideoTouched ? 'videocam_0' : undefined
      }
      mediaIsLoading={isVideoLoading}
      video={{
        ref: videoRef,
        muted: true,
        autoPlay,
      }}
      infoRow={{
        label: cameraName,
        description: cameraStatus,
      }}
    />
  );
};

PrinterRecordingVideoPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  cameraName: PropTypes.string,
  cameraNotEnabled: PropTypes.bool,
  cameraStatus: PropTypes.string.isRequired,
  enableCamera: PropTypes.func,
  isVideoTouched: PropTypes.bool,
  isVideoLoading: PropTypes.bool,
  videoRef: PropTypes.object.isRequired,
};

export default PrinterRecordingVideoPlayer;
