import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import DropDownField, {
  DROP_DOWN_FIELD_SIZE_COMPACT,
} from '@components/1-atoms/DropDownField';
import { Wrapper, LeftColumn, RightColumn } from './SettingDropDown.styled';
import withTooltip from '@hoc/withTooltip';

const TextWithTooltip = withTooltip(Text);

const SettingDropDown = ({
  className,
  dataTestId = 'setting-drop-down',
  dropDownField = {},
  label,
  labelTooltip = {},
  withIdentation,
  form, // formik property
  field, // formik property
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <TextWithTooltip
          {...labelTooltip}
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </TextWithTooltip>
      </LeftColumn>

      <RightColumn>
        <DropDownField
          dataTestId={`${dataTestId}__drop-down-field`}
          {...dropDownField}
          field={field}
          form={form}
          size={DROP_DOWN_FIELD_SIZE_COMPACT}
        />
      </RightColumn>
    </Wrapper>
  );
};

SettingDropDown.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dropDownField: PropTypes.shape(DropDownField.propTypes),
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.object,
  withIdentation: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.object,
};

export default SettingDropDown;
