import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import {
  Wrapper,
  Dot,
  Description,
  RecordingDescription,
} from './PrinterPrintingStatus.styled';

export const MONITOR_LIST_ITEM_IDLE_STATUS_VARIANT = 'IDLE';
export const MONITOR_LIST_ITEM_PRINTING_STATUS_VARIANT = 'PRINTING';
export const MONITOR_LIST_ITEM_OFFLINE_STATUS_VARIANT = 'OFFLINE';

export const MONITOR_LIST_ITEM_STATUS_SUCCESS_COLOR_VARIANT = 'success';
export const MONITOR_LIST_ITEM_STATUS_ERROR_COLOR_VARIANT = 'error';
export const MONITOR_LIST_ITEM_STATUS_CAUTION_COLOR_VARIANT = 'outline';

const PrinterPrintingStatus = ({
  printerStatus,
  recordingStartedAt,
  ticking,
}) => {
  const intl = useIntl();

  const isRecording = !!recordingStartedAt;
  const variant = printerStatus;

  const getPrintingDuration = useCallback(() => {
    if (!recordingStartedAt) return null;

    const printStartedDate = moment(
      recordingStartedAt || new Date().toISOString(),
    );
    const printCurrentDate = moment(new Date().toISOString());
    const duration = moment.duration(printCurrentDate.diff(printStartedDate));
    const printingDuration = moment
      .utc(duration.asMilliseconds())
      .format('HH:mm:ss');

    return printingDuration;
  }, [recordingStartedAt]);

  const [printingDuration, setPrintingDuration] = useState(
    getPrintingDuration(),
  );

  const statusColorName = useMemo(() => {
    switch (variant) {
      case MONITOR_LIST_ITEM_IDLE_STATUS_VARIANT:
        return MONITOR_LIST_ITEM_STATUS_SUCCESS_COLOR_VARIANT;
      case MONITOR_LIST_ITEM_PRINTING_STATUS_VARIANT:
        return MONITOR_LIST_ITEM_STATUS_SUCCESS_COLOR_VARIANT;
      case MONITOR_LIST_ITEM_OFFLINE_STATUS_VARIANT:
        return MONITOR_LIST_ITEM_STATUS_CAUTION_COLOR_VARIANT;
      default:
        return MONITOR_LIST_ITEM_STATUS_CAUTION_COLOR_VARIANT;
    }
  }, [variant]);

  const description = useMemo(() => {
    switch (variant) {
      case MONITOR_LIST_ITEM_IDLE_STATUS_VARIANT:
        return intl.formatMessage({
          id: 'monitor_list_item_description.idle',
          defaultMessage: 'Idle',
        });
      case MONITOR_LIST_ITEM_PRINTING_STATUS_VARIANT:
        return printingDuration
          ? intl.formatMessage(
              {
                id: 'monitor_list_item_description.printing',
                defaultMessage: 'Printing {duration}',
              },
              {
                duration: `- ${printingDuration}`,
              },
            )
          : intl.formatMessage({
              id: 'general.printing',
              defaultMessage: 'Printing',
            });
      case MONITOR_LIST_ITEM_OFFLINE_STATUS_VARIANT:
        return intl.formatMessage({
          id: 'monitor_list_item_description.offline',
          defaultMessage: 'Offline',
        });
      default:
        return '';
    }
  }, [intl, variant, printingDuration]);

  useEffect(() => {
    if (ticking) {
      const interval = setInterval(() => {
        setPrintingDuration(getPrintingDuration());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [getPrintingDuration, setPrintingDuration, ticking]);

  return (
    <Wrapper>
      <Dot colorName={statusColorName} />
      {description && <Description>{description}</Description>}

      {isRecording && (
        <>
          <Dot colorName="error" />
          <RecordingDescription>
            {intl.formatMessage({
              id: 'global.recording',
              defaultMessage: 'REC',
            })}
          </RecordingDescription>
        </>
      )}
    </Wrapper>
  );
};

PrinterPrintingStatus.propTypes = {
  printerStatus: PropTypes.string,
  recordingStartedAt: PropTypes.string,
  ticking: PropTypes.bool,
};

export default PrinterPrintingStatus;
