export const PrinterStatuses = {
  PRINTING: 'PRINTING',
  IDLE: 'IDLE',
  OFFLINE: 'OFFLINE',
};

export const PrinterControlTypes = {
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  VALUE_AND_TARGET: 'VALUE_AND_TARGET',
};
