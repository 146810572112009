import { DISABLED_ORGS_FOR_TALK_TO_AI } from '@constants/featuresFlagsConstants';

export default {
  baseUrl: 'https://aisync-api-test.ai-build.com/',
  baseUrlws: 'wss://aisync-api-test.ai-build.com/',
  minimumBackwardsCompatibleVersion: '3.0.18',
  statsigClientKey: 'client-Jp4nkHcnPrIuRPLFTBbEUwCPltRhXCkQ1zMKNHCEJ1f',

  /* Feature Flags */
  features: {
    talkToAi: {
      enabled: false,
      users: '*',
      organizations: DISABLED_ORGS_FOR_TALK_TO_AI,
      roles: '*',
    },
    workflowAnnotations: {
      enabled: true,
      users: '*',
      organizations: ['AiBuild'],
      roles: '*',
    },
    knowledgeCentre: {
      enabled: true,
      users: '*',
      organizations: ['AiBuild'],
      roles: '*',
    },
    quickStart: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    analyticsDefectSensitivity: {
      enabled: true,
      users: '*',
      organizations: ['AiBuild'],
      roles: '*',
    },
    clippingTool: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    computationWaitingTime: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    fullScreenOperator: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    operatorCategories: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
  },
  /* End of Feature Flags */
};
