import styled from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';

export const Wrapper = styled.div`
  ${flexColumn}
  flex: 1;
  overflow: auto;
`;

export const Log = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurface',
})`
  width: 100%;
`;
