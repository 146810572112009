import React, { useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import MonitorPageContent from '@components/MonitorPageContent';
import usePrinterList from '@hooks/printers/usePrinterList';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import { Wrapper } from './Monitor.styled';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';

const REAL_TIME_TAB = 'Real-time';
const RECORDINGS_TAB = 'Recordings';

const Monitor = () => {
  const intl = useIntl();
  const history = useHistory();
  usePrinterSocket();
  const { getPrintersMonitorList } = usePrinterList();
  const { printersMonitorQuery } = usePrinterQueries();

  const refetchPrintersMonitorQuery = printersMonitorQuery.refetch;
  const printersWithCamera = printersMonitorQuery.data;

  const printersMonitorList = getPrintersMonitorList(printersWithCamera);

  const handleTabClick = useCallback(
    (tab) => {
      const isRealTimeTab = tab?.id === REAL_TIME_TAB;

      if (isRealTimeTab) return;

      history.push(ROUTES.MONITOR_RECORDINGS);
    },
    [history],
  );

  useEffect(() => {
    refetchPrintersMonitorQuery();
  }, [refetchPrintersMonitorQuery]);

  return (
    <Wrapper data-testid="monitor-page">
      <MonitorPageContent
        handleTabClick={handleTabClick}
        headerTitle={intl.formatMessage({
          id: 'monitorpage.header_title',
          defaultMessage: 'Monitor',
        })}
        loading={!printersMonitorQuery?.isFetched}
        listItems={printersMonitorList}
        tabs={[
          {
            id: REAL_TIME_TAB,
            title: intl.formatMessage({
              id: 'monitorpage.monitor_list.tab_title.real_time',
              defaultMessage: 'Real-time',
            }),
          },
          {
            id: RECORDINGS_TAB,
            title: intl.formatMessage({
              id: 'monitorpage.monitor_list.tab_title.recordings',
              defaultMessage: 'Recordings',
            }),
          },
        ]}
      />
    </Wrapper>
  );
};

Monitor.propTypes = {};

export default Monitor;
