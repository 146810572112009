import { useCallback, useEffect, useState } from 'react';
import useWSClient from '@hooks/websocket/useWSClient';

const WS_MESSAGE_TYPE = {
  WAITING_LAYER_TIME: 'WAITING_LAYER_TIME',
};

export default function usePrinterRecordingWaitingTimesSocket({
  printerId,
  cameraId,
}) {
  const ws = useWSClient();
  const [waitingLayerData, setWaitingLayerData] = useState({});

  const handleMessage = useCallback(
    (message) => {
      const event = JSON.parse(message.data);
      const data = event?.data;
      const isWaitingTimeEvent =
        event.type === WS_MESSAGE_TYPE.WAITING_LAYER_TIME;
      const isPrinterEvent = data.printerId === printerId;
      const isCameraEvent = data.cameraId === cameraId;
      const skip = !isWaitingTimeEvent || !isPrinterEvent || !isCameraEvent;

      if (skip) {
        return;
      }

      const messageLayer = data.layer;

      setWaitingLayerData((prevData) => ({
        ...prevData,
        [messageLayer]: data,
      }));
    },
    [printerId, cameraId],
  );

  useEffect(() => {
    ws.removeEventListener('message', handleMessage);
    ws.addEventListener('message', handleMessage);

    return () => {
      ws.removeEventListener('message', handleMessage);
    };
  }, [handleMessage, ws]);

  return {
    waitingLayerData: Object.values(waitingLayerData),
  };
}
