import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import useDialog from '@hooks/useDialog';
import usePrinter from '@hooks/printers/usePrinter';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import Dialog from '@components/Workspaces/Dialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Field from '@components/1-atoms/Field';

const MODAL_ID = ModalDataTypes.EDIT_PRINTER_MONITOR_RECORDING;

const EditRecordingDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { getPrintersRecording } = usePrinter();
  const { editRecordingMutation } = usePrinterMutations();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const { dataTestId = 'edit-recording-dialog', recording } = dialogData;

  const defaultValues = useMemo(
    () => ({
      name: recording?.name,
    }),
    [recording?.name],
  );

  const recordings = getPrintersRecording();
  const form = useForm({ defaultValues, mode: 'all' });
  const { isSubmitting } = form.formState;

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const handleSubmit = useCallback(
    async (values) => {
      await editRecordingMutation.mutateAsync({
        name: values.name,
        recordingId: recording?.id,
      });

      handleClose();
    },
    [editRecordingMutation, recording?.id, handleClose],
  );

  return (
    <Dialog
      dataTestId={dataTestId}
      dialogId={MODAL_ID}
      title={intl.formatMessage({
        id: 'editrecording.dialog.title',
        defaultMessage: 'Edit recording',
      })}
      secondaryButtonLabel={intl.formatMessage({
        id: 'general.cancel',
        defaultMessage: 'Cancel',
      })}
      confirmButtonText={intl.formatMessage({
        id: 'general.save',
        defaultMessage: 'Save',
      })}
      onSubmit={form.handleSubmit(handleSubmit)}
      onCancel={handleClose}
      loading={isSubmitting}
    >
      <Controller
        name="name"
        control={form.control}
        rules={{
          required: intl.formatMessage({
            id: 'editrecording.name.validation.required',
            defaultMessage: 'Name cannot be empty',
          }),
          minLength: {
            value: 5,
            message: intl.formatMessage({
              id: 'editrecording.name.validation.min',
              defaultMessage: 'Name must be at least 5 characters long',
            }),
          },
          maxLength: {
            value: 256,
            message: intl.formatMessage({
              id: 'editrecording.name.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          },
          validate: (value) => {
            const doesNameExist = recordings?.some(
              (recording) => recording.name === value,
            );

            if (doesNameExist) {
              return intl.formatMessage({
                id: 'editrecording.name.validation.unique',
                defaultMessage: 'Name must be unique',
              });
            }
          },
        }}
        render={({ field, fieldState }) => {
          const error = fieldState.isTouched && fieldState.error;

          return (
            <Field
              dataTestId={`${dataTestId}name`}
              disabled={isSubmitting}
              name="projectName"
              label={intl.formatMessage({
                id: 'editrecording.name.label',
                defaultMessage: 'Name',
              })}
              placeholder={intl.formatMessage({
                id: 'editrecording.name.placeholder',
                defaultMessage: 'Untitled recording',
              })}
              autoFocus
              error={!!error}
              supportingText={error?.message}
              {...field}
            />
          );
        }}
      />
    </Dialog>
  );
};

export default EditRecordingDialog;
