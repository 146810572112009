import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Wrapper } from './PrinterRecordingDetails.styled';

const PrinterRecordingDetails = ({ recording = {} }) => {
  const startedDate = moment(recording?.startedAt || new Date().toISOString());
  const endedDate = moment(recording?.endedAt || new Date().toISOString());
  const duration = moment.duration(endedDate.diff(startedDate));
  const recordingDuration = moment
    .utc(duration.asMilliseconds())
    .format('HH:mm:ss');

  return (
    <Wrapper>
      <FormattedMessage
        id="recordingspage.recording.description"
        defaultMessage="Date {date} - Duration {duration} - Printer {printerName}"
        values={{
          date: moment(recording?.startedAt).format('DD/MM/YYYY'),
          duration: recordingDuration,
          printerName: recording?.printerName,
        }}
      />
    </Wrapper>
  );
};

PrinterRecordingDetails.propTypes = {
  recording: PropTypes.object,
};

export default PrinterRecordingDetails;
