import { SELECTION_MODE_FACE } from '@app/constants/canvasSelection';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const canvasSelectionInitialState = {
  activeInput: null,
  targetOperator: null,
  selectionMode: SELECTION_MODE_FACE,
  keepCurrentSelections: false,
  confirm: false,
  selectionConfig: {},
};

const initialState = {
  hiddenInputNames: [],
  isSubmitted: false,
  isAwaitingComputation: false,
  canvasSelection: {
    ...canvasSelectionInitialState,
  },
  operators: {
    fullScreenOperatorId: null,
    showFullScreenOperator: false,
  },
  materialSettingsUpdated: false,
  safetyCheckAction: null,
};

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setHiddenInputNames(state, action) {
      state.hiddenInputNames = action.payload;
    },
    setIsSubmitted(state, action) {
      state.isSubmitted = action.payload;
    },
    setIsAwaitingComputation(state, action) {
      state.isAwaitingComputation = action.payload;
    },
    setActiveCanvasSelectionInput(state, action) {
      state.canvasSelection.activeInput = action.payload.activeInput;
      state.canvasSelection.targetOperator = action.payload.targetOperator;
      state.canvasSelection.selectionConfig = action.payload.selectionConfig;
      state.canvasSelection.selectionMode =
        action.payload.selectionConfig?.entityTypes?.[0] ?? SELECTION_MODE_FACE;
    },
    setActiveCanvasSelectionTargetOperator(state, action) {
      state.canvasSelection.targetOperator = action.payload;
    },
    confirmCanvasSelectionChanges(state) {
      state.canvasSelection.confirm = true;
    },
    setCanvasSelectionMode(state, action) {
      state.canvasSelection.selectionMode = action.payload.selectionMode;
      state.canvasSelection.keepCurrentSelections =
        action.payload.keepCurrentSelections ?? false;
    },
    setFullScreenOperatorId(state, action) {
      state.operators.fullScreenOperatorId = action.payload;
    },
    setFullScreenOperatorVisibility(state, action) {
      state.operators.showFullScreenOperator = action.payload;
    },
    setMaterialSettingsUpdated(state) {
      state.materialSettingsUpdated = true;
    },
    setSafetyCheckAction(state, action) {
      state.safetyCheckAction = action.payload;
    },
    resetHiddenInputNames(state) {
      state.hiddenInputNames = [];
    },
    resetIsSubmitted(state) {
      state.isSubmitted = false;
    },
    resetIsAwaitingComputation(state) {
      state.isAwaitingComputation = false;
    },
    resetActiveCanvasSelectionInput(state) {
      state.canvasSelection = { ...canvasSelectionInitialState };
    },
    resetFullScreenOperatorId(state) {
      state.operators.fullScreenOperatorId =
        initialState.operators.fullScreenOperatorId;
    },
    resetFullScreenOperatorVisibility(state) {
      state.operators.showFullScreenOperator =
        initialState.operators.showFullScreenOperator;
    },
    resetMaterialSettingsUpdated(state) {
      state.materialSettingsUpdated = false;
    },
    resetSafetyCheckAction(state) {
      state.safetyCheckAction = null;
    },
  },
});

export const {
  setHiddenInputNames,
  resetHiddenInputNames,
  resetIsSubmitted,
  setIsSubmitted,
  setIsAwaitingComputation,
  setFullScreenOperatorVisibility,
  setFullScreenOperatorId,
  confirmCanvasSelectionChanges,
  resetIsAwaitingComputation,
  setActiveCanvasSelectionInput,
  setActiveCanvasSelectionTargetOperator,
  setCanvasSelectionMode,
  setSafetyCheckAction,
  resetActiveCanvasSelectionInput,
  resetFullScreenOperatorId,
  resetFullScreenOperatorVisibility,
  setMaterialSettingsUpdated,
  resetMaterialSettingsUpdated,
  resetSafetyCheckAction,
} = workflowSlice.actions;

export const selectHiddenInputNames = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.hiddenInputNames,
);

export const selectIsSubmitted = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.isSubmitted,
);

export const selectIsAwaitingComputation = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.isAwaitingComputation,
);

export const selectActiveCanvasSelectionInput = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.canvasSelection.activeInput,
);

export const selectActiveCanvasSelectionOperator = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.canvasSelection.targetOperator,
);

export const selectFullScreenOperatorId = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.operators.fullScreenOperatorId,
);

export const selectFullScreenOperatorVisibility = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.operators.showFullScreenOperator,
);

export const selectCanvasSelectionMode = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.canvasSelection.selectionMode,
);

export const selectKeepCurrentSelections = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.canvasSelection.keepCurrentSelections,
);

export const selectCanvasSelectionConfig = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.canvasSelection.selectionConfig,
);

export const selectConfirmCanvasSelection = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.canvasSelection.confirm,
);

export const selectMaterialSettingsUpdated = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.materialSettingsUpdated,
);

export const selectSafetyCheckAction = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.safetyCheckAction,
);

export default workflowSlice.reducer;
