import { useIntl } from 'react-intl';
import { Field as FormikField, useFormikContext } from 'formik';
import React from 'react';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { PostProcessorSettingsName } from '@components/Printers/PostProcessorConfig/PostProcessorConstants';

const PostProcessorFanuc = () => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();
  return (
    <FormikField
      component={SettingCheckbox}
      dataTestId={`printer-custom-post-processor__setting-fanucUseKLFileGeneration`}
      checkbox={{
        checked: Boolean(
          values[PostProcessorSettingsName.fanucUseKLFileGeneration],
        ),
        onChange: () => {
          setFieldValue(
            PostProcessorSettingsName.fanucUseKLFileGeneration,
            !values[PostProcessorSettingsName.fanucUseKLFileGeneration],
          );
        },
        dirty: checkIfFieldIsDirty(
          values,
          initialValues,
          PostProcessorSettingsName.fanucUseKLFileGeneration,
        ),
      }}
      name={PostProcessorSettingsName.fanucUseKLFileGeneration}
      label={intl.formatMessage({
        id: 'printers.postprocessor.abb.useKLFileGeneration.label',
        defaultMessage: 'Use KL File Gen',
      })}
    />
  );
};

export default PostProcessorFanuc;
