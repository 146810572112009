import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing.level6};
  `}
`;

export const Controls = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level6};
  `}
`;
