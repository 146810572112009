import styled, { css } from 'styled-components';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => `
    gap: ${spacing.level2};
  `}
`;

export const Description = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurfaceVariant',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.76;
`;

export const Dot = styled.span`
  width: 11px;
  height: 11px;
  border-radius: 50%;

  ${({ colorName, theme: { colors } }) => css`
    background-color: ${colors?.[colorName] || colors.onSurfaceVariant};
  `}
`;

export const RecordingDescription = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'error',
})``;
