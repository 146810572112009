import React from 'react';
import PropTypes from 'prop-types';
import {
  ABB,
  CEAD_PROGRAMS,
  FANUC_PROGRAMS,
  GCODE_PROGRAMS,
  KUKA,
} from '../../../constants/printers/postProcessorSettingsDefinitions';
import PostProcessorGCode from './PostProcessorGCode/PostProcessorGCode';
import PostProcessorKUKA from './PostProcessorKUKA/PostProcessorKUKA';
import SettingsCategory from '../../2-molecules/SettingsCategory/SettingsCategory';
import { useIntl } from 'react-intl';
import PostProcessorABB from './PostProcessorABB/PostProcessorABB';
import PostProcessorFanuc from './PostProcessorFanuc/PostProcessorFanuc';
import PostProcessorCEAD from './PostProcessorCEAD/PostProcessorCEAD';

/**
 * Wrapper containing a list of customisation settings specific to that program type.
 * @param {*} param0
 * @returns
 */
const PostProcessorProgramSpecificSettings = ({ printer, isSubmitting }) => {
  const intl = useIntl();

  const children = [
    printer?.programType === KUKA && (
      <PostProcessorKUKA isSubmitting={isSubmitting} />
    ),
    GCODE_PROGRAMS.includes(printer?.programType) && (
      <PostProcessorGCode isSubmitting={isSubmitting} />
    ),
    printer?.programType === ABB && (
      <PostProcessorABB isSubmitting={isSubmitting} />
    ),
    CEAD_PROGRAMS.includes(printer?.programType) && (
      <PostProcessorCEAD isSubmitting={isSubmitting} />
    ),
    FANUC_PROGRAMS.includes(printer?.programType) && <PostProcessorFanuc />,
  ].filter(Boolean);

  // If the array is empty, return nothing:
  if (!children.length) {
    return null;
  }

  // Otherwise, render the SettingsCategory with children:
  return (
    <SettingsCategory
      withDividerBottom
      title={intl.formatMessage({
        id: 'printers.postprocessor.category.program.specific.title',
      })}
    >
      {children}
    </SettingsCategory>
  );
};

PostProcessorProgramSpecificSettings.propTypes = {
  printer: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default PostProcessorProgramSpecificSettings;
