import { useMutation } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const licenseMutationKeys = {
  approveIssuance: ['approveIssuance'],
  requestLicense: ['requestLicense'],
};

export default function useLicenseMutations() {
  const requestLicenseMutation = useMutation({
    mutationKey: licenseMutationKeys.requestLicense,
    mutationFn: ({ targetEmailAddress, expiryDate, customProgramTypes }) =>
      client
        .post(endpoints.requestDesktopLicense, {
          targetUser: targetEmailAddress,
          expiryDate,
          customProgramTypes,
        })
        .then((res) => res.data),
  });
  const approveIssuanceMutation = useMutation({
    mutationKey: licenseMutationKeys.approveIssuance,
    mutationFn: (token) =>
      client
        .post(
          endpoints.approveDesktopLicense,
          { token },
          {
            ignoreGlobalErrorHandler: true,
          },
        )
        .then((res) => res.data),
  });

  return {
    approveIssuanceMutation,
    requestLicenseMutation,
  };
}
