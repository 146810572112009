import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useSocketMessage from '@hooks/websocket/useSocketMessage';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import useDialog from '@hooks/useDialog';
import Card from '@components/2-molecules/Card';
import { PrinterControlTypes } from '@constants/printerStatuses';
import { ModalDataTypes } from '@constants/modalDataTypes';

const PRINTER_CONTROL_DATA = 'PRINTER_CONTROL_DATA';

const PrinterRecordingControl = ({ control, editable }) => {
  const { showDialog } = useDialog();
  const { editPrinterControlMutation } = usePrinterMutations();
  const { messageData } = useSocketMessage({
    messageType: editable ? PRINTER_CONTROL_DATA : undefined,
    messageCondition: (data) => data?.id === control?.id,
  });

  const isValueAndTargetType =
    control?.type === PrinterControlTypes.VALUE_AND_TARGET;
  const controlName = control?.name;
  const controlValue = editable ? messageData?.value : control?.value;
  const formattedControlValue = useMemo(() => {
    const noValue = editable ? !messageData : !controlValue;

    if (noValue) {
      return '--';
    }

    if (isValueAndTargetType) {
      const [currentValue, targetValue] = controlValue || [];
      return `${currentValue}/${targetValue || '--'}`;
    }

    return `${controlValue}`;
  }, [editable, isValueAndTargetType, messageData, controlValue]);

  const editControl = useCallback(() => {
    let defaultValue = controlValue;

    if (isValueAndTargetType) {
      defaultValue = controlValue?.[1];
    }

    showDialog(ModalDataTypes.EDIT_SINGLE_TEXT_FILED, {
      title: controlName,
      fieldName: controlName,
      defaultValue,
      onSubmit: async (value) =>
        await editPrinterControlMutation.mutateAsync({
          printerId: control?.printerId,
          controlId: control?.id,
          value,
        }),
    });
  }, [
    isValueAndTargetType,
    controlName,
    controlValue,
    control?.id,
    control?.printerId,
    showDialog,
    editPrinterControlMutation,
  ]);

  return (
    <Card
      headerLeadingIconButton={{
        iconName: control?.iconKey,
        onClick: editable ? editControl : undefined,
      }}
      infoRow={{
        label: formattedControlValue,
        description: controlName,
      }}
      onClick={editable ? editControl : undefined}
    />
  );
};

PrinterRecordingControl.propTypes = {
  editable: PropTypes.bool,
  control: PropTypes.shape({
    id: PropTypes.string,
    printerId: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.values(PrinterControlTypes)),
    iconKey: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ]),
    timestamp: PropTypes.number,
  }).isRequired,
};

export default PrinterRecordingControl;
